<template>
    <sidebar @close="$emit('close')" ref="sidebar">
        <template v-slot:title v-if="employee">
            {{ employee.full_name }}
        </template>

        <template v-slot:subtitle v-if="employee">
            <span class="counter counter-warnings" v-if="warningCount">
                <i class="counter-icon fas fa-fw fa-exclamation-triangle" aria-label="Warnings"></i>
                <span class="counter-label">{{ warningCount }}</span>
            </span>
        </template>

        <template v-slot:header v-if="employee">
            <div class="row mb-1">
                <div class="col">
                    <span class="text-muted">{{ employee.company.name }}</span>
                </div>
            </div>

            <div class="row mb-1">
                <div class="col">
                    <!-- Type of object -->
                    <span class="text-muted" v-if="employeeId">
                        Employee&nbsp;&middot;
                    </span>
                    <span class="text-muted" v-if="onboardingApplicationId">
                        Onboarding&nbsp;&middot;
                    </span>
                    <!-- Status -->
                    <span class="text-muted" v-if="employeeId && employee.employment_status == 'Active'">
                        {{ employee.employment_status }}
                    </span>
                    <span class="text-danger-dark text-bold text-italic" v-if="employeeId && ['Inactive', 'Terminated'].includes(employee.employment_status)">
                        {{ employee.employment_status }}
                    </span>
                    <span class="text-muted" v-if="onboardingApplicationId && employee.status != 'canceled'">
                        {{ employee.status_pretty }}
                    </span>
                    <span class="text-danger-dark text-bold text-italic" v-if="onboardingApplicationId && employee.status == 'canceled'">
                        {{ employee.status_pretty }}
                    </span>
                    <!-- Additional info if employeeId -->
                    <span class="text-muted" v-if="employeeId">
                        &nbsp;&middot; Employee Number {{ employee.employee_number }}
                    </span>
                </div>
            </div>

            <unlock-widget v-if="employee.user && employee.user.id && employee.is_locked" :client="client" :user="employee.user" :is-locked="employee.is_locked" @unlocked="getData"/>

            <!-- Tabs -->
            <div class="nav-tabs-container">
                <div class="nav-tabs-scroll-helper">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'main'}" href="#tabContentMain" id="tabLinkMain" data-toggle="tab" role="tab" aria-controls="tabContentMain" :aria-selected="activeTab == 'main'">Main</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'misc'}" href="#tabContentMiscHr" id="tabLinkMiscHr" data-toggle="tab" role="tab" aria-controls="tabContentMiscHr" :aria-selected="activeTab == 'misc'">Misc HR</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'hr-docs'}" href="#tabContentHrDocs" id="tabLinkHrDocs" data-toggle="tab" role="tab" aria-controls="tabContentHrDocs" :aria-selected="activeTab == 'hr-docs'">HR Docs</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'warnings'}" href="#tabContentWarnings" id="tabLinkWarnings" data-toggle="tab" role="tab" aria-controls="tabContentWarnings" :aria-selected="activeTab == 'warnings'">Warnings</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'access'}" href="#tabUserAccess" id="tabLinkUserAccess" data-toggle="tab" role="tab" aria-controls="tabUserAccess" :aria-selected="activeTab == 'access'">User Access</a>
                        </li>

                        <li class="nav-item" v-if="employee && employeeId && client.has_pos_integration_altametrics && client.pos_sidebar_work_locations.includes(employee.work_location)">
                            <a class="nav-link" href="#tabContentPOS" id="tabLinkPOS" data-toggle="tab" role="tab" aria-controls="tabContentPOS" aria-selected="false">POS</a>
                        </li>

                        <li class="nav-item" v-if="$permissions.hasRolesInCompany(client.id, employee.company_id, $store.state.security_roles.CHANGE_HISTORY_ROLES)">
                            <a class="nav-link" :class="{active: activeTab == 'changelogs'}" href="#tabContentHistory" id="tabLinkHistory" data-toggle="tab" role="tab" aria-controls="tabContentHistory" :aria-selected="activeTab == 'changelogs'">History</a>
                        </li>

                        <li class="nav-item" v-if="isAdmin">
                            <a class="nav-link" href="#tabContentAdmin" id="tabLinkAdmin" data-toggle="tab" role="tab" aria-controls="tabContentAdmin" aria-selected="false">Admin</a>
                        </li>
                    </ul>
                </div>
            </div>
        </template>

        <slot v-if="employee">
            <div class="tab-content flex-body flex-container">

                <div class="tab-pane flex-body" :class="{'show active': activeTab == 'main'}" id="tabContentMain" role="tabpanel" aria-labelledby="tabLinkMain">

                    <!-- "Scarlet Letter" indicator -->
                    <alert-box type="lockedWarning" v-if="i9CompletedLateWarnings.length">
                        <p class="mb-0">
                            <strong>I-9 Completed Late</strong>
                        </p>
                        <p class="mb-0 mt-1" v-for="w in i9CompletedLateWarnings" :key=w.id>
                            This employee's Form I-9 was completed late for their start date of {{ $filters.date(w.date) }}.
                        </p>
                    </alert-box>

                    <employee-details
                        :client="client"
                        :employee="employee"
                        :can-edit="onboardingApplicationId && canEditHRInfo && !employee.hr_info_approved_on"
                        @edit="openEditModal"
                        @edit-email="openEditEmailModal"
                    />

                    <p class="color-red" v-if="activeOnboardingCount">{{ activeOnboardingCount }} onboarding applications in progress. <router-link :to="{name: 'onboarding', params: {hashedClientId: $hasher.encode(client.id)}}">Go to Onboarding</router-link></p>
                </div>

                <div class="tab-pane flex-body" :class="{'show active': activeTab == 'hr-docs'}" id="tabContentHrDocs" role="tabpanel" aria-labelledby="tabLinkHrDocs">
                    <!-- <p class="text-right text-small">
                        <a href @click.prevent="expandAll()">Expand all</a>
                        <span class="text-muted px-1">|</span>
                        <a href @click.prevent="collapseAll()">Collapse all</a>
                    </p> -->

                    <doc-task-approval
                        v-if="docTaskApprovalMode"
                        :doctasks="this.docTasksPendingApproval"
                        :employee="employee"
                        :client="client"
                        @close="docTaskApprovalMode = false"
                        @updated="onUpdate"
                    />

                    <button-row v-if="!docTaskApprovalMode && employeeId && client.has_company_docs_module && ($permissions.hasRolesInCompany(client.id, employee.company_id, $store.state.security_roles.HR_DOCUMENTS_ROLES) || $store.state.activeTour == 'hr-suite')" stacked class="pt-1 mb-2 pr-4 pl-4">
                        <button class="btn btn-outline-primary" type="button" @click.prevent.stop="openAddDocumentModal" id="addDocButton">
                            <i class="far fa-plus" aria-hidden="true" title="Add Document to Employee"></i>
                            Add Document to Employee
                        </button>
                    </button-row>

                    <!-- Completed HR Documents Widget -->
                    <info-widget v-if="!docTaskApprovalMode" :collapsible="true" :is-expanded="completedDocsWidgetExpanded" @toggle_expand="completedDocsWidgetExpanded = !completedDocsWidgetExpanded" id="completedDocuments">
                        <template v-slot:title>Completed Documents</template>

                        <template v-slot:headerbutton v-if="employeeId && !client.has_company_docs_module && $permissions.hasRolesInCompany(client.id, employee.company_id, $store.state.security_roles.HR_DOCUMENTS_ROLES)">
                            <button class="btn btn-outline-primary" type="button" @click.prevent.stop="openDocumentUploadModal">
                                <i class="far fa-plus" aria-hidden="true" title="Upload"></i>
                                Upload
                            </button>
                        </template>

                        <slot v-if="!$permissions.hasRolesInCompany(client.id, employee.company_id, $store.state.security_roles.HR_DOCUMENTS_ROLES) && !($store.state.activeTour == 'hr-suite')">
                            <div class="info-widget-empty p-2">
                                This information is not available to your security role.
                            </div>
                        </slot>

                        <slot v-if="$permissions.hasRolesInCompany(client.id, employee.company_id, $store.state.security_roles.HR_DOCUMENTS_ROLES) || $store.state.activeTour == 'hr-suite'">
                            <div v-if="docsTableData.length" class="p-0">
                                <section class="table-section" v-for="docSection in completedDocSections" :key="docSection.slug">
                                    <a href class="table-section-titlebar collapse-trigger" role="button" :class="{'is-expanded': expandedCompletedDocSections[docSection.slug]}" @click.prevent="expandedCompletedDocSections[docSection.slug] = !expandedCompletedDocSections[docSection.slug]">

                                        <span class="widget-toggle-wrapper">
                                            <collapse-toggle-button />
                                        </span>
                                        <h5 class="table-section-title">{{ docSection.title }} <span class="text-regular">({{ docSection.count }})</span></h5>
                                    </a>
                                    <table class="table css-table widget-table table-fixed" v-if="docSection.rows.length" v-show="expandedCompletedDocSections[docSection.slug]">
                                        <thead>
                                            <tr>
                                                <th class="cw-toggle"></th>
                                                <th>Document</th>
                                                <th class="cw-date text-right">Completed</th>
                                                <!-- <th class="cw-6 text-right">Expires</th> -->
                                            </tr>
                                        </thead>
                                        <tbody v-for="doc in docSection.rows" :key="doc.id" :class="{'active-item': doc.id == `employee-doc-${activeCompletedDocId}`}" ref="docs">
                                            <tr>
                                                <td class="cw-toggle position-relative">
                                                    <a href class="widget-toggle-wrapper collapse-trigger" role="button" :class="{'is-expanded': expandedCompletedDocs[doc.id]}" @click.prevent="expandedCompletedDocs[doc.id] = !expandedCompletedDocs[doc.id]">
                                                        <collapse-toggle-button />
                                                    </a>
                                                </td>
                                                <td class="d-flex">
                                                    <span class="truncate-wrapper">
                                                        <!-- Restrict PDF view if this is a PDFForm (i.e. tax form) on an employee (as opposed to an onboarding app) -->
                                                        <a v-if="!onboardingApplicationId && (doc.onboarding_application_pdf_form || doc.employee_update_pdf_form)" href @click.prevent="setCurrentRestrictedDoc(doc)" class="truncate-content">
                                                            {{ doc.name }}
                                                        </a>
                                                        <!-- Otherwise don't restrict PDF view -->
                                                        <a v-else href @click.prevent="setCurrentPDF(doc)" class="truncate-content">
                                                            {{ doc.name }}
                                                        </a>
                                                    </span>
                                                    <i v-if="employeeId && !doc.visible_to_employee" class="fas pl-hf pt-qt fa-eye-slash text-muted"></i>
                                                </td>
                                                <td class="cw-date text-right">
                                                    <span>
                                                        {{ $filters.dateshort(doc.completed_on || doc.date_added || doc.manager_date_signed) }}
                                                    </span>
                                                </td>
                                                <!-- <td class="cw-6 text-right">
                                                    <span v-if="doc.expires_on">
                                                        {{ $filters.fromNow(doc.expires_on) }}
                                                    </span>
                                                    <span v-else>--</span>
                                                </td> -->
                                            </tr>
                                            <tr class="child no-transition" v-show="expandedCompletedDocs[doc.id]">
                                                <td colspan="3">
                                                    <div class="card card-body item-details" v-if="doc.source_pretty == 'Import'">
                                                        <div>
                                                            Imported document
                                                        </div>
                                                    </div>
                                                    <div class="card card-body item-details" v-else>
                                                        <!-- "Banner" subcomponent, not currently used
                                                        <div class="item-details-banner item-details-banner--danger" v-if="!doc.most_recent">
                                                            <span class="text-danger-dark text-bold text-italic">
                                                                Inactive
                                                            </span>
                                                        </div>
                                                        -->
                                                        <div>
                                                            <section class="item-details-section">
                                                                <div class="float-right">
                                                                    <span class="text-danger-dark text-bold text-italic" v-if="!doc.most_recent">
                                                                        Inactive
                                                                    </span>
                                                                    <span class="text-muted text-semibold" v-else>
                                                                        Active
                                                                    </span>
                                                                </div>
                                                                <p class="mb-hf">
                                                                    Version: {{ doc.version }}
                                                                </p>
                                                                <p v-if="!doc.onboarding_application_uniform && !doc.employee_uniform">
                                                                    File:
                                                                    <!-- Restrict PDF view if this is a PDFForm (i.e. tax form) on an employee (as opposed to an onboarding app) -->
                                                                    <a v-if="!onboardingApplicationId && (doc.onboarding_application_pdf_form || doc.employee_update_pdf_form)" href @click.prevent="setCurrentRestrictedDoc(doc)">{{ doc.filename }}</a>
                                                                    <!-- Otherwise don't restrict PDF view -->
                                                                    <a v-else href @click.prevent="setCurrentPDF(doc)">{{ doc.filename }}</a>
                                                                </p>
                                                                <div v-if="doc.onboarding_application_uniform || doc.employee_uniform">
                                                                    <p v-for="item in doc.response_data">
                                                                        <strong>{{ item.name }}</strong> <br/>
                                                                        <span>Size: {{ item.category }} {{ item.size }}</span> <br/>
                                                                        <span v-if="item.color">Color: {{ item.color }}</span>
                                                                    </p>
                                                                </div>
                                                                <div v-if="doc.onboarding_application_hr_custom_form || doc.employee_hr_custom_form">
                                                                    <p v-for="r in doc.response_data">
                                                                        <strong>{{ r.prompt }}</strong> <br/>
                                                                        <span v-if="r.question_type == 'address'">
                                                                            {{ r.response.address_1 }} {{ r.response.address_2 }}<br/>
                                                                            {{ r.response.city }}, {{ r.response.state }} {{ r.response.postal_code }}
                                                                        </span>
                                                                        <span v-else-if="r.question_type == 'select-multiple'">
                                                                            {{ r.response.join(', ') }}
                                                                        </span>
                                                                        <span v-else-if="r.question_type == 'date'">
                                                                            {{ $filters.date(r.response) }}
                                                                        </span>
                                                                        <span v-else-if="r.question_type == 'upload'">
                                                                            <button
                                                                                class="btn btn-reset btn-link d-flex justify-content-center align-items-center"
                                                                                @click.prevent="imageModalUrl = makeHRCustomFormPhotoUrl((doc.employee_hr_custom_form || doc.onboarding_application_hr_custom_form), r.response.photo_id); imageModalTitle = r.response.filename; imageModalOpen = true;"
                                                                            >
                                                                                <div class="flex-grow-0 docphoto-thumb mr-1" :style="{ backgroundImage: `url(${makeHRCustomFormPhotoUrl((doc.employee_hr_custom_form || doc.onboarding_application_hr_custom_form), r.response.photo_id)})` }"></div>
                                                                                <div class="flex-grow-1">{{ r.response.filename }}</div>
                                                                            </button>
                                                                        </span>
                                                                        <span v-else>{{ r.response }}</span>
                                                                    </p>
                                                                </div>
                                                            </section>
                                                            <section class="item-details-section" v-if="doc.source_pretty == 'Onboarding' || doc.source_pretty == 'ESS'">
                                                                <p>
                                                                    <span v-if="doc.manager_date_signed">
                                                                        Submitted by Employee On: {{ $filters.date(doc.date_signed || doc.date_submitted) }}<br/>
                                                                        Approved by Manager On: {{ $filters.date(doc.manager_date_signed) }}<br/>
                                                                        <span v-if="doc.manager_name">Approved/Signed By: {{ doc.manager_name }}</span>
                                                                    </span>
                                                                    <span v-else>
                                                                        Completed by Employee On: {{ $filters.date(doc.date_signed || doc.date_submitted) }}
                                                                    </span>
                                                                </p>
                                                            </section>
                                                            <section class="item-details-section" v-else-if="doc.document_type == 'employee-document-covid-policy'">
                                                                <p>
                                                                    Completed by Employee On: {{ $filters.date(doc.date_signed) }} <br/>
                                                                </p>
                                                            </section>
                                                            <section class="item-details-section" v-else-if="doc.source_pretty == 'Upload'">
                                                                <p v-if="doc.completed_on">
                                                                    Completed On: {{ $filters.date(doc.completed_on) }}
                                                                </p>
                                                                <p>
                                                                    Uploaded On: {{ $filters.date(doc.date_added) }} <br/>
                                                                    <span v-if="doc.manager_name">Uploaded By: {{ doc.manager_name }}</span>
                                                                </p>
                                                                <p v-if="!doc.visible_to_employee">
                                                                    Visible to Employee: No
                                                                </p>
                                                                <p v-if="$permissions.hasRolesInCompany(client.id, employee.company_id, $store.state.security_roles.HR_DOCUMENTS_ROLES)">
                                                                    <a href @click.prevent="docToRemove = doc">Remove Document</a>
                                                                </p>
                                                            </section>
                                                            <section class="item-details-section" v-else-if="doc.source_pretty == 'Import'">
                                                                <p>
                                                                    Uploaded On: {{ $filters.date(doc.date_added) }} <br/>
                                                                    <span v-if="doc.manager_name">Uploaded By: {{ doc.manager_name }}</span>
                                                                </p>
                                                                <p v-if="$permissions.hasRolesInCompany(client.id, employee.company_id, $store.state.security_roles.HR_DOCUMENTS_ROLES)">
                                                                    <a href @click.prevent="docToRemove = doc">Remove Document</a>
                                                                </p>
                                                            </section>
                                                            <section class="item-details-section" v-if="employee.company.feature_i9_document_photos && doc.onboarding_application_pdf_form && doc.onboarding_application_pdf_form.form_designation == 'i9'">
                                                                <document-photos-list
                                                                    :client="client"
                                                                    :company="employee.company"
                                                                    :onboarding-application-id="doc.onboarding_application_pdf_form.onboarding_application_id"
                                                                    :form="doc.onboarding_application_pdf_form"
                                                                    :most-recent-i9="doc.most_recent"
                                                                    @updated="onUpdate"
                                                                />
                                                            </section>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="row-empty" v-if="!docSection.rows.length" v-show="expandedCompletedDocSections[docSection.slug]">
                                        This section is empty.
                                    </div>
                                </section>
                            </div>

                            <div v-else>
                                <div class="info-widget-empty p-2">
                                    This employee has no completed HR documents.
                                </div>
                            </div>
                        </slot>
                    </info-widget>

                    <!-- Incomplete HR Documents Widget -->
                    <info-widget v-if="employeeId && !docTaskApprovalMode && hrDocsSuiteEnabled" :collapsible="true" :is-expanded="incompleteDocsWidgetExpanded" @toggle_expand="incompleteDocsWidgetExpanded = !incompleteDocsWidgetExpanded" id="incompleteDocuments">
                        <template v-slot:title>Incomplete Documents</template>

                        <slot v-if="!$permissions.hasRolesInCompany(client.id, employee.company_id, $store.state.security_roles.HR_DOCUMENTS_ROLES) && !($store.state.activeTour == 'hr-suite')">
                            <div class="info-widget-empty p-2">
                                This information is not available to your security role.
                            </div>
                        </slot>

                        <slot v-if="$permissions.hasRolesInCompany(client.id, employee.company_id, $store.state.security_roles.HR_DOCUMENTS_ROLES) || $store.state.activeTour == 'hr-suite'">
                            <div v-if="incompleteDocTasksTableData.length" class="p-0">
                                <section class="table-section" v-for="docSection in incompleteDocSections" :key="docSection.slug">
                                    <a href class="table-section-titlebar collapse-trigger" role="button" :class="{'is-expanded': expandedIncompleteDocSections[docSection.slug]}" @click.prevent="expandedIncompleteDocSections[docSection.slug] = !expandedIncompleteDocSections[docSection.slug]">

                                        <span class="widget-toggle-wrapper">
                                            <collapse-toggle-button />
                                        </span>
                                        <h5 class="table-section-title">{{ docSection.title }} <span class="text-regular">({{ docSection.count }})</span></h5>
                                    </a>
                                    <table class="table css-table widget-table table-fixed" v-if="docSection.rows.length" v-show="expandedIncompleteDocSections[docSection.slug]">
                                        <thead>
                                            <tr>
                                                <th class="cw-toggle"></th>
                                                <th>Document</th>
                                                <th class="cw-date text-right">{{ docSection.dateFieldTitle }}</th>
                                            </tr>
                                        </thead>
                                        <tbody v-for="doc in docSection.rows" :key="doc.id" :class="{'active-item': doc.id == activeIncompleteDocTaskId}" ref="incompleteDocs">
                                            <tr>
                                                <td class="cw-toggle position-relative">
                                                    <a href class="widget-toggle-wrapper collapse-trigger" role="button" :class="{'is-expanded': expandedIncompleteDocTasks[doc.id]}" @click.prevent="expandedIncompleteDocTasks[doc.id] = !expandedIncompleteDocTasks[doc.id]">
                                                        <collapse-toggle-button />
                                                    </a>
                                                </td>
                                                <td class="truncate-wrapper">
                                                    <a href v-if="doc.url" @click.prevent="setCurrentPDF(doc)" class="paycheck truncate-content">{{ doc.name }}</a>
                                                    <span v-else class="paycheck truncate-content">{{ doc.name }}</span>
                                                </td>
                                                <td class="cw-date text-right">
                                                    <span v-if="docSection.slug == 'pending-employee-completion'">
                                                        {{ $filters.dateshort(doc.last_reminded_on) }}
                                                    </span>
                                                    <span v-if="docSection.slug == 'pending-manager-completion'">
                                                        - <!-- TODO KB change to required/assigned on? -->
                                                    </span>
                                                    <span v-if="docSection.slug == 'needs-manager-approval'">
                                                        {{ $filters.dateshort(doc.responded_on) }}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr class="child no-transition" v-show="expandedIncompleteDocTasks[doc.id]">
                                                <td colspan="3">
                                                    <div class="card card-body item-details">
                                                        <div>
                                                            <section class="item-details-section">
                                                                <template v-if="doc.status == 'needs-manager-approval'">
                                                                    <p class="mb-hf" v-if="doc.submitted_version_number">
                                                                        Version: {{ doc.submitted_version_number }}
                                                                    </p>
                                                                    <p v-if="doc.url">
                                                                        File: <a href @click.prevent="setCurrentPDF(doc)">{{ doc.filename }}</a>
                                                                    </p>
                                                                    <div v-if="doc.employee_hr_custom_form">
                                                                        <p v-for="r in doc.employee_hr_custom_form.response_data">
                                                                            <strong>{{ r.prompt }}</strong> <br/>
                                                                            <span v-if="r.question_type == 'address'">
                                                                                {{ r.response.address_1 }} {{ r.response.address_2 }}<br/>
                                                                                {{ r.response.city }}, {{ r.response.state }} {{ r.response.postal_code }}
                                                                            </span>
                                                                            <span v-else-if="r.question_type == 'select-multiple'">
                                                                                {{ r.response.join(', ') }}
                                                                            </span>
                                                                            <span v-else-if="r.question_type == 'date'">
                                                                                {{ $filters.date(r.response) }}
                                                                            </span>
                                                                            <span v-else-if="r.question_type == 'upload'">
                                                                                <button
                                                                                    class="btn btn-reset btn-link d-flex justify-content-center align-items-center"
                                                                                    @click.prevent="imageModalUrl = makeHRCustomFormPhotoUrl(doc.employee_hr_custom_form, r.response.photo_id); imageModalTitle = r.response.filename; imageModalOpen = true;"
                                                                                >
                                                                                <div class="flex-grow-0 docphoto-thumb mr-1" :style="{ backgroundImage: `url(${makeHRCustomFormPhotoUrl(doc.employee_hr_custom_form, r.response.photo_id)})` }"></div>
                                                                                    <div class="flex-grow-1">{{ r.response.filename }}</div>
                                                                                </button>
                                                                            </span>
                                                                            <span v-else>{{ r.response }}</span>
                                                                        </p>
                                                                    </div>
                                                                </template>
                                                                <p class="mb-hf">Required On: {{ $filters.date(doc.assigned_on) }}</p>
                                                                <p v-if="doc.assigned_by">Required By: {{ doc.assigned_by }}</p>
                                                                <p v-if="doc.due_on">Due On: {{ doc.due_on }}</p>
                                                                <p v-if="doc.responded_on">Submitted by Employee On: {{ $filters.date(doc.responded_on) }}</p>
                                                                <template v-else>
                                                                    <p v-if="doc.collection_method == 'employee' && doc.last_reminded_on != doc.assigned_on">Last Reminded On: {{ $filters.date(doc.last_reminded_on) }}</p>
                                                                    <p>
                                                                        Collection Settings:
                                                                        <span v-if="doc.status == 'needs-employee-response'">Employee will complete, </span>
                                                                        <span v-if="doc.status == 'needs-manager-response'">Manager will complete, </span>
                                                                        <span v-if="doc.block_ee_until_completion">block access</span>
                                                                        <span v-else>allow access</span>
                                                                    </p>
                                                                </template>
                                                                <div v-if="!doc.responded_on && $permissions.hasRolesInCompany(client.id, employee.company_id, $store.state.security_roles.HR_DOCUMENTS_ROLES)">
                                                                    <a href @click.prevent="openCancelDocTaskModal(doc)">Cancel Document</a>
                                                                    <button-row  v-if="canEditCollectionSettings" stacked class="m-0">
                                                                        <button class="btn btn-outline-primary m-0" @click.prevent="openCollectionSettingsModal(doc)">
                                                                            <i class="far fa-cog" aria-hidden="true"></i> Edit Collection Settings
                                                                        </button>
                                                                    </button-row>
                                                                    <button-row stacked class="m-0">
                                                                        <button class="btn btn-primary m-0" @click.prevent="openCompleteDocTaskModal(doc)">
                                                                            <i class="far fa-check" aria-hidden="true"></i> Complete Document
                                                                        </button>
                                                                    </button-row>
                                                                </div>
                                                            </section>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div v-if="docSection.rows.length">
                                        <button-row v-if="expandedIncompleteDocSections[docSection.slug] && docSection.slug == 'needs-manager-approval'" stacked class="mt-1 mb-0">
                                            <button class="btn btn-primary" @click.prevent="startDocTaskApproval()">
                                                Start Approval
                                            </button>
                                        </button-row>
                                        <button-row v-if="expandedIncompleteDocSections[docSection.slug] && docSection.slug == 'pending-employee-completion'" stacked class="mt-1 mb-0">
                                            <button class="btn btn-primary" @click.prevent="sendReminder">
                                                Send Reminder
                                            </button>
                                        </button-row>
                                    </div>
                                    <div class="row-empty" v-if="!docSection.rows.length" v-show="expandedIncompleteDocSections[docSection.slug]">
                                        This section is empty.
                                    </div>
                                </section>
                            </div>

                            <div v-else>
                                <div class="info-widget-empty p-2">
                                    This employee has no incomplete HR documents.
                                </div>
                            </div>
                        </slot>
                    </info-widget>

                    <!-- Canceled HR Documents Widget -->
                    <info-widget v-if="employeeId && !docTaskApprovalMode && hrDocsSuiteEnabled" :collapsible="true" :is-expanded="canceledDocsWidgetExpanded" @toggle_expand="canceledDocsWidgetExpanded = !canceledDocsWidgetExpanded">
                        <template v-slot:title>Canceled Documents</template>

                        <slot v-if="!$permissions.hasRolesInCompany(client.id, employee.company_id, $store.state.security_roles.HR_DOCUMENTS_ROLES)">
                            <div class="info-widget-empty p-2">
                                This information is not available to your security role.
                            </div>
                        </slot>

                        <slot v-if="$permissions.hasRolesInCompany(client.id, employee.company_id, $store.state.security_roles.HR_DOCUMENTS_ROLES)">
                            <div v-if="canceledDocTasksTableData.length" class="p-0">
                                <table class="table css-table widget-table table-fixed">
                                    <thead>
                                        <tr>
                                            <th class="cw-toggle"></th>
                                            <th>Document</th>
                                            <th class="cw-date text-right">Canceled On</th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="doc in canceledDocTasksTableData" :key="doc.id" :class="{'active-item': doc.id == activeCanceledDocTaskId}" ref="docs">
                                        <tr>
                                            <td class="cw-toggle position-relative">
                                                <a href class="widget-toggle-wrapper collapse-trigger" role="button" :class="{'is-expanded': expandedCanceledDocTasks[doc.id]}" @click.prevent="expandedCanceledDocTasks[doc.id] = !expandedCanceledDocTasks[doc.id]">
                                                    <collapse-toggle-button />
                                                </a>
                                            </td>
                                            <td class="truncate-wrapper">
                                                <a href v-if="doc.url" @click.prevent="setCurrentPDF(doc)" class="paycheck truncate-content">{{ doc.name }}</a>
                                                <span v-else class="paycheck truncate-content">{{ doc.name }}</span>
                                            </td>
                                            <td class="cw-date text-right">
                                                <span>
                                                    {{ $filters.dateshort(doc.canceled_on) }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr class="child no-transition" v-show="expandedCanceledDocTasks[doc.id]">
                                            <td colspan="3">
                                                <div class="card card-body item-details">
                                                    <div>
                                                        <section class="item-details-section">
                                                            <!-- ID: {{ doc.id }} -->
                                                            <template v-if="doc.submitted_version_number">
                                                                <p class="mb-hf">
                                                                    Version: {{ doc.submitted_version_number }}
                                                                </p>
                                                                <p v-if="doc.url">
                                                                    File: <a href @click.prevent="setCurrentPDF(doc)">{{ doc.filename }}</a>
                                                                </p>
                                                            </template>
                                                        </section>
                                                        <section class="item-details-section">
                                                            <p class="mb-hf">Required On: {{ $filters.datetime(doc.assigned_on) }}</p>
                                                            <p v-if="doc.assigned_by">Required By: {{ doc.assigned_by }}</p>
                                                            <p class="mb-hf">Canceled On: {{ $filters.datetime(doc.canceled_on) }}</p>
                                                            <p v-if="doc.canceled_by">Canceled By: {{ doc.canceled_by }}</p>
                                                        </section>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div v-else>
                                <div class="info-widget-empty p-2">
                                    This employee has no canceled HR documents.
                                </div>
                            </div>
                        </slot>
                    </info-widget>
                </div>

                <div class="tab-pane flex-body" :class="{'show active': activeTab == 'misc'}" id="tabContentMiscHr" role="tabpanel" aria-labelledby="tabLinkMiscHr">

                    <approval
                        v-if="approvalMode"
                        :onboarding-application="employee"
                        :client="client"
                        @close="approvalMode = false"
                        @updated="onUpdate"
                        @edit="openEditModal"
                        @edit-email="openEditEmailModal"
                    />

                    <div v-show="!approvalMode">
                        <p class="text-right text-small">
                            <a href @click.prevent="expandAll()">Expand all</a>
                            <span class="text-muted px-1">|</span>
                            <a href @click.prevent="collapseAll()">Collapse all</a>
                        </p>

                        <!-- Onboarding Widget -->
                        <info-widget :collapsible="true" :is-expanded="onboardingAppsExpanded" @toggle_expand="onboardingAppsExpanded = !onboardingAppsExpanded" v-if="employee">
                            <template v-slot:title>Onboarding</template>

                            <slot>
                                <!-- Current Onboarding Application(s) display -->
                                <div class="p-2" v-if="onboardingApplicationId && employee">
                                    <p v-if="hasBouncedEmail">
                                        <alert-box type="caution">
                                            <strong>Bounced Email:</strong> Onboarding cannot proceed because email cannot be delivered.
                                            <a v-if="canEditEmail" href @click.prevent="openEditEmailModal">Update this user’s email address to re-try.</a>
                                        </alert-box>
                                    </p>

                                    <p v-if="!employee.approved_on && employee.onboarding_error_message">
                                        <alert-box type="error">
                                            There was a problem with onboarding this employee: {{ employee.onboarding_error_message }}
                                        </alert-box>
                                    </p>

                                    <p v-if="employee.approved_on && !employee.onboarded_on">
                                        <alert-box type="info">
                                            Sending data to payroll system. This may take a few minutes.
                                        </alert-box>
                                    </p>

                                    <alert-box type="info" class="mb-4" v-if="!userHasAccess">
                                        This employee's onboarding is being managed by another user. Contact your Payroll Administrator for more information.
                                    </alert-box>

                                    <div class="m-0">
                                        <div class="text-semibold" v-for="job in employee.jobs" :key="job.id">{{ job.position.terminal_value }}</div>
                                        <div>
                                            Expected Start Date: {{ $filters.date(employee.date_hired) }}
                                        </div>
                                        <div class="pt-1" v-if="employee.status == 'canceled'">
                                            <span class="text-danger-dark text-bold">Canceled:</span>
                                            {{ $filters.date(employee.canceled_on) }} by {{ employee.canceled_by.email }}
                                        </div>
                                    </div>

                                    <button-row stacked class="mt-1 mb-0" v-if="userHasAccess && employee.status != 'canceled'">
                                        <button class="btn btn-outline-primary" v-if="employee.status == 'started'" @click.prevent="resendActivationEmail()">
                                            Re-Send Invitation
                                        </button>
                                        <button class="btn btn-primary" @click.prevent="startApproval()" v-if="((employee.status == 'needs-manager-approval' && !employee.approved_on) || (employee.status == 'hired' && !employee.approved_on)) && !approvalStarted">
                                            Start Approval
                                        </button>
                                        <button class="btn btn-primary" @click.prevent="approvalMode = true" v-if="((employee.status == 'needs-manager-approval' && !employee.approved_on) || (employee.status == 'hired' && !employee.approved_on)) && approvalStarted && !employee.onboarding_error_message">
                                            Continue Approval
                                        </button>
                                        <button class="btn btn-primary" @click.prevent="$refs.approvalModal.open()" v-if="((employee.status == 'needs-manager-approval' && !employee.approved_on) || (employee.status == 'hired' && !employee.approved_on)) && approvalStarted && employee.onboarding_error_message">
                                            Retry Approval
                                        </button>
                                        <button class="btn btn-outline-primary btn-block mb-1" @click.prevent="cancelOnboarding()" v-if="((employee && ['canceled', 'hired'].indexOf(employee.status) < 0) && !employee.approved_on) || (employee.status == 'hired' && !employee.approved_on)">
                                            Cancel Onboarding
                                        </button>
                                    </button-row>
                                </div>

                                <!-- Completed Onboarding Applications display -->
                                <div class="p-0" v-if="employeeId">

                                    <div v-if="onboardingApplications.length">
                                        <section class="table-section">
                                            <div class="table-section-titlebar">
                                                <h5 class="table-section-title">Completed Onboarding Applications <span class="text-regular">({{ onboardingApplications.length }})</span></h5>
                                            </div>
                                            <table class="table css-table widget-table table-fixed">
                                                <thead>
                                                    <tr>
                                                        <th class="cw-toggle"></th>
                                                        <th>{{ client.position_label_pretty }}</th>
                                                        <th class="cw-date text-right">Approved</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-for="app in onboardingApplications" :key="app.id">
                                                    <tr>
                                                        <td class="cw-toggle position-relative">
                                                            <a href class="widget-toggle-wrapper collapse-trigger" role="button" :class="{'is-expanded': expandedOnboardingApplications[app.id]}" @click.prevent="expandedOnboardingApplications[app.id] = !expandedOnboardingApplications[app.id]">
                                                                <collapse-toggle-button />
                                                            </a>
                                                        </td>
                                                        <td class="truncate-wrapper">
                                                            <div class="truncate-content" v-for="job in app.jobs" :key="job.id">{{ job.position.terminal_value }}</div>
                                                        </td>
                                                        <td class="text-right">
                                                            <span>{{ $filters.dateshort(app.onboarded_on) }}</span>
                                                        </td>
                                                    </tr>

                                                    <tr class="child no-transition" v-show="expandedOnboardingApplications[app.id]">
                                                        <td colspan="3">
                                                            <div class="card card-body item-details">
                                                                <section class="item-details-section">
                                                                    <p>
                                                                        <span class="d-block">
                                                                            Approval Date: {{ $filters.date(app.onboarded_on) }}
                                                                        </span>
                                                                        <span class="d-block">
                                                                            Approver: {{ app.approved_by ? app.approved_by.email : 'N/A' }}
                                                                        </span>
                                                                    </p>
                                                                </section>
                                                                <section class="item-details-section">
                                                                    <h5 class="theading">
                                                                        Employee Information
                                                                    </h5>
                                                                    <p>
                                                                        <span class="d-block text-semibold">
                                                                            Employee Name:
                                                                        </span>
                                                                        <span class="d-block">
                                                                            {{ app.full_name }}
                                                                        </span>
                                                                    </p>
                                                                    <p>
                                                                        <span class="d-block text-semibold">
                                                                            Email Address:
                                                                        </span>
                                                                        <span class="d-block">
                                                                            {{ app.email }}
                                                                        </span>
                                                                    </p>
                                                                    <p>
                                                                        <span class="d-block text-semibold">
                                                                            Expected Start Date:
                                                                        </span>
                                                                        <span class="d-block">
                                                                            {{ $filters.date(app.date_hired) }}
                                                                        </span>
                                                                    </p>
                                                                    <p>
                                                                        <span class="d-block text-semibold">
                                                                            ACA Employment Category:
                                                                        </span>
                                                                        <span class="d-block">
                                                                            {{ app.aca_category }}
                                                                        </span>
                                                                    </p>
                                                                    <p>
                                                                        <span class="d-block text-semibold">
                                                                            Resident Address:
                                                                        </span>
                                                                        <span class="d-block">
                                                                            {{ app.address_1 }}
                                                                        </span>
                                                                        <span class="d-block" v-if="app.address_2">
                                                                            {{ app.address_2 }}
                                                                        </span>
                                                                        <span class="d-block">
                                                                            {{ app.city }}, {{ app.state }} {{ app.postal_code }}
                                                                        </span>
                                                                    </p>
                                                                    <p>
                                                                        <span class="d-block text-semibold">
                                                                            Mailing Address:
                                                                        </span>
                                                                        <template v-if="app.use_mailing_address">
                                                                            <span class="d-block">
                                                                                {{ app.mailing_address_1 }}
                                                                            </span>
                                                                            <span class="d-block" v-if="app.mailing_address_2">
                                                                                {{ app.mailing_address_2 }}
                                                                            </span>
                                                                            <span class="d-block">
                                                                                {{ app.mailing_city }}, {{ app.mailing_state }} {{ app.mailing_postal_code }}
                                                                            </span>
                                                                        </template>
                                                                        <template v-else>
                                                                            Same as above
                                                                        </template>
                                                                    </p>
                                                                    <p>
                                                                        <span class="d-block text-semibold">
                                                                            Phone:
                                                                        </span>
                                                                        <span class="d-block">
                                                                            {{ app.phone_formatted }}
                                                                        </span>
                                                                    </p>
                                                                    <p>
                                                                        <span class="d-block text-semibold">
                                                                            Social Security Number:
                                                                        </span>
                                                                        <span class="d-block">
                                                                            XXX-XX-{{ app.ssn_last_4 }}
                                                                        </span>
                                                                    </p>
                                                                </section>
                                                                <section class="item-details-section">
                                                                    <h5 class="theading">
                                                                        {{ client.position_label_short }} Information
                                                                    </h5>
                                                                    <p v-for="job in app.jobs" :key="job.id">
                                                                        <span class="d-block text-semibold">
                                                                            {{ job.position.terminal_value }}
                                                                        </span>
                                                                        <span class="d-block">
                                                                            Pay Type: {{ job.pay_type_pretty }}
                                                                        </span>
                                                                        <span class="d-block">
                                                                            {{ job.pay_type == 'H' ? 'Per Hour' : 'Per Pay Period' }}:
                                                                            ${{ job.pay_type == 'H' ? job.hourly_rate : job.salary_rate }}
                                                                        </span>
                                                                    </p>
                                                                </section>
                                                                <section class="item-details-section">
                                                                    <h5 class="theading">
                                                                        Emergency Contacts
                                                                    </h5>
                                                                    <p v-for="contact in app.emergency_contacts" :key="contact.id">
                                                                        <span class="d-block text-semibold">
                                                                            {{ contact.full_name }}
                                                                        </span>
                                                                        <span class="d-block">
                                                                            {{ contact.relationship }}
                                                                        </span>
                                                                        <span class="d-block" v-if="contact.phone">
                                                                            {{ contact.phone_formatted }}
                                                                        </span>
                                                                        <span class="d-block" v-if="contact.email">
                                                                            {{ contact.email }}
                                                                        </span>
                                                                    </p>
                                                                </section>
                                                                <section class="item-details-section">
                                                                    <h5 class="theading">
                                                                        Equal Employment Opportunity Self-Identification
                                                                    </h5>
                                                                    <p>
                                                                        <span class="d-block text-semibold">
                                                                            Legal Gender:
                                                                        </span>
                                                                        <span class="d-block">
                                                                            {{ app.gender == 'custom' ? 'Prefer not to say' : app.gender }}
                                                                        </span>
                                                                    </p>
                                                                    <p>
                                                                        <span class="d-block text-semibold">
                                                                            Racial/Ethnic Identification:
                                                                        </span>
                                                                        <span class="d-block">
                                                                            {{ app.ethnic_origin ? app.ethnic_origin : 'Prefer not to say' }}
                                                                        </span>
                                                                    </p>
                                                                </section>
                                                                <section class="item-details-section" v-if="!app.has_wotc_step">
                                                                    <h5 class="theading">
                                                                        WOTC
                                                                    </h5>
                                                                    <p>
                                                                        <span class="d-block" v-if="app.wotc_pre_qualified">
                                                                            This employee <strong>may qualify</strong> for the Work Opportunity Tax Credit. You can check the application progress in HKP.
                                                                        </span>
                                                                        <span class="d-block" v-if="!app.wotc_pre_qualified">
                                                                            This employee <strong>does not qualify</strong> for the Work Opportunity Tax Credit, based on their responses to the pre-screening questionnaire.
                                                                        </span>

                                                                        <a v-if="app.wotc_pdf_file" @click.prevent="showWOTCPDF(app)" :href="`${$api.API_BASE}/clients/${client.id}/onboarding-applications/${app.id}/wotc-pdf`" class="btn btn-outline-primary mt-1">View WOTC Responses</a>
                                                                    </p>
                                                                </section>
                                                                <section class="item-details-section">
                                                                    <h5 class="theading">
                                                                        Required Documents
                                                                    </h5>
                                                                    <p v-for="form in app.forms" :key="form.id">
                                                                        <span class="d-block text-semibold">
                                                                            {{ form.form_name }}
                                                                        </span>
                                                                        <span class="d-block">
                                                                            Completed on {{ $filters.date(form.filled_on) }}
                                                                        </span>
                                                                        <span class="d-block" v-if="form.signature_data.manager_signature">
                                                                            Signed by {{ form.signature_data.manager_signature.full_name }}
                                                                        </span>
                                                                    </p>
                                                                    <p v-for="formDoc in app.hr_form_documents" :key="formDoc.id">
                                                                        <span class="d-block text-semibold">
                                                                            {{ formDoc.hr_form_document.name }}
                                                                        </span>
                                                                        <span class="d-block">
                                                                            Completed on {{ $filters.date(formDoc.filled_on) }}
                                                                        </span>
                                                                        <span class="d-block" v-if="formDoc.signature_data.manager_signature">
                                                                            Signed by {{ formDoc.signature_data.manager_signature.full_name }}
                                                                        </span>
                                                                    </p>
                                                                    <p v-for="doc in app.hr_documents" :key="doc.id">
                                                                        <span class="d-block text-semibold">
                                                                            {{ doc.hr_document.name }}
                                                                        </span>
                                                                        <span class="d-block">
                                                                            Completed on {{ $filters.date(doc.signed_on) }}
                                                                        </span>
                                                                        <span class="d-block" v-if="doc.signature_data.manager_signature">
                                                                            Signed by {{ doc.signature_data.manager_signature.full_name }}
                                                                        </span>
                                                                    </p>
                                                                </section>
                                                                <section class="item-details-section">
                                                                    <h5 class="theading">
                                                                        Direct Deposit
                                                                    </h5>
                                                                    <p v-for="acct in app.direct_deposit_accounts" :key="acct.id">
                                                                        <span class="d-block text-semibold">
                                                                            {{ acct.bank_name }}
                                                                        </span>
                                                                        <span class="d-block">
                                                                            Routing Number: {{ acct.routing_number }}
                                                                        </span>
                                                                        <span class="d-block">
                                                                            {{ acct.account_type.toUpperCase() }}
                                                                        </span>
                                                                        <span class="d-block" v-if="acct.split_type == 'percentage' && acct.percentage !== null">
                                                                            {{ acct.percentage }}%
                                                                        </span>
                                                                        <span class="d-block" v-if="acct.split_type == 'remainder'">
                                                                            Remainder
                                                                        </span>
                                                                        <span class="d-block" v-if="acct.split_type == 'fixed'">
                                                                            ${{ acct.amount }}
                                                                        </span>
                                                                    </p>
                                                                </section>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </section>
                                    </div>

                                    <div>
                                        <div class="info-widget-empty p-2" v-if="!onboardingApplications.length">
                                            This employee has no completed onboarding applications.
                                        </div>
                                    </div>

                                </div>
                            </slot>
                        </info-widget>

                        <!-- Managers Widget -->
                        <info-widget :collapsible="true" :is-expanded="managersExpanded" v-if="managers" @toggle_expand="managersExpanded = !managersExpanded">
                            <template v-slot:title>Managers</template>

                            <slot>

                                <div class="p-2">
                                    <ul class="list-unstyled m-0">
                                        <li v-if="employee && (payrollAdmins.length)">
                                            <h4 class="theading mb-2">Organization</h4>

                                            <ul class="list-unstyled m-0">
                                                <li v-for="manager in payrollAdmins" :key="manager.id">
                                                    <div class="pb-2">
                                                        <h6 class="text-semibold mb-qt">
                                                            {{ manager.full_name }}
                                                        </h6>
                                                        <div class="text-muted">
                                                            Payroll Administrator
                                                        </div>
                                                        <div v-show="manager.phone">
                                                            <i class="fas fa-fw fa-phone text-muted mr-3q"></i>
                                                            <a :href="'tel:' + manager.phone">{{ manager.phone_formatted }}</a>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-fw fa-envelope text-muted mr-3q"></i>
                                                            <a :href="'mailto:' + manager.email">{{ manager.email }}</a>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>

                                        <template v-for="location in workLocations" :key="location.id">
                                        <li class="border-top pt-2" v-if="managersByLocation[location.id][$store.state.security_roles.ROLE_LOCATION_MANAGER].length + managersByLocation[location.id][$store.state.security_roles.ROLE_LOCATION_MANAGER_APPROVER].length + managersByLocation[location.id][$store.state.security_roles.ROLE_STORE_MANAGER].length + managersByLocation[location.id][$store.state.security_roles.ROLE_EMPLOYEE_MANAGER].length">
                                            <h4 class="theading mb-2">{{ location.name }}</h4>
                                            <ul class="list-unstyled m-0">
                                                <li v-for="manager in managersByLocation[location.id][$store.state.security_roles.ROLE_LOCATION_MANAGER].concat(managersByLocation[location.id][$store.state.security_roles.ROLE_LOCATION_MANAGER_APPROVER])" :key="manager.id">
                                                    <div class="pb-2">
                                                        <h6 class="text-semibold mb-qt">
                                                            {{ manager.full_name }}
                                                        </h6>
                                                        <div class="text-muted">
                                                            General Manager
                                                        </div>
                                                        <div v-show="manager.phone">
                                                            <i class="fas fa-fw fa-phone text-muted mr-3q"></i>
                                                            <a :href="'tel:' + manager.phone">{{ manager.phone_formatted }}</a>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-fw fa-envelope text-muted mr-3q"></i>
                                                            <a :href="'mailto:' + manager.email">{{ manager.email }}</a>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li v-for="manager in managersByLocation[location.id][$store.state.security_roles.ROLE_STORE_MANAGER]" :key="manager.id">
                                                    <div class="pb-2">
                                                        <h6 class="text-semibold mb-qt">
                                                            {{ manager.full_name }}
                                                        </h6>
                                                        <div class="text-muted">
                                                            Department Manager
                                                        </div>
                                                        <div v-show="manager.phone">
                                                            <i class="fas fa-fw fa-phone text-muted mr-3q"></i>
                                                            <a :href="'tel:' + manager.phone">{{ manager.phone_formatted }}</a>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-fw fa-envelope text-muted mr-3q"></i>
                                                            <a :href="'mailto:' + manager.email">{{ manager.email }}</a>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li v-for="manager in managersByLocation[location.id][$store.state.security_roles.ROLE_EMPLOYEE_MANAGER]" :key="manager.id">
                                                    <div class="pb-2">
                                                        <h6 class="text-semibold mb-qt">
                                                            {{ manager.full_name }}
                                                        </h6>
                                                        <div class="text-muted">
                                                            DEPARTMENT ASSISTANT
                                                        </div>
                                                        <div v-show="manager.phone">
                                                            <i class="fas fa-fw fa-phone text-muted mr-3q"></i>
                                                            <a :href="'tel:' + manager.phone">{{ manager.phone_formatted }}</a>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-fw fa-envelope text-muted mr-3q"></i>
                                                            <a :href="'mailto:' + manager.email">{{ manager.email }}</a>
                                                        </div>
                                                    </div>
                                                </li>

                                            </ul>
                                        </li>
                                        </template>
                                    </ul>
                                </div>
                            </slot>
                        </info-widget>

                        <!-- Emergency Contacts Widget -->
                        <info-widget :collapsible="true" :is-expanded="contactsExpanded" v-if="managers" @toggle_expand="contactsExpanded = !contactsExpanded">
                            <template v-slot:title>Emergency Contacts</template>
                            <template v-slot:headerbutton v-if="employeeId && $permissions.hasRolesInCompany(client.id, employee.company_id, $store.state.security_roles.ONBOARDING_ROLES)">
                                <button class="btn btn-outline-primary" type="button" @click.stop.prevent="openEmergencyContactModal">
                                    <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
                                    Edit
                                </button>
                            </template>

                            <slot v-if="!$permissions.hasRolesInCompany(client.id, employee.company_id, $store.state.security_roles.ONBOARDING_ROLES)">
                                <div class="info-widget-empty p-2">
                                    This information is not available to your security role.
                                </div>
                            </slot>

                            <slot v-if="$permissions.hasRolesInCompany(client.id, employee.company_id, $store.state.security_roles.ONBOARDING_ROLES)">
                                <div v-if="emergencyContacts.length">
                                    <section class="table-section">
                                        <table class="table css-table widget-table table-fixed">
                                            <thead>
                                                <tr>
                                                    <th class="cw-toggle"></th>
                                                    <th>Name</th>
                                                    <th>Relationship</th>
                                                </tr>
                                            </thead>
                                            <tbody v-for="contact in emergencyContacts">
                                            <!-- Start of an individual contact -->
                                                <tr class="mb-2">
                                                    <td class="cw-toggle position-relative">
                                                        <a href class="widget-toggle-wrapper collapse-trigger" role="button" :class="{'is-expanded': expandedEmergencyContacts[contact.id]}" @click.prevent="expandedEmergencyContacts[contact.id] = !expandedEmergencyContacts[contact.id]">
                                                            <collapse-toggle-button />
                                                        </a>
                                                    </td>
                                                    <td class="truncate-wrapper">
                                                        <span class="truncate-content"> <!-- Unsure if truncate-content is truly required here, but I think so -->
                                                            {{ contact.full_name }}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span>
                                                            {{ contact.relationship }}
                                                        </span>
                                                    </td>
                                                </tr>

                                                <tr class="child no-transition" v-show="expandedEmergencyContacts[contact.id]">
                                                    <td colspan="3" class="normal-size">
                                                        <ul class="list-unstyled m-0 ml-3" style="padding-left: 4px">
                                                            <li v-if="contact.phone">
                                                                <i class="fas fa-phone text-muted mr-1"></i>
                                                                <a :href="'tel:' + contact.phone">{{ contact.phone_formatted }}</a>
                                                            </li>
                                                            <li v-if="contact.email">
                                                                <i class="fas fa-envelope text-muted mr-1"></i>
                                                                <a :href="'mailto:' + contact.email">{{ contact.email }}</a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            <!-- End of an individual contact -->
                                            </tbody>
                                        </table>
                                    </section>
                                </div>
                                <div v-if="!emergencyContacts.length">
                                    <div class="info-widget-empty p-2">
                                        This employee has no emergency contacts.
                                    </div>
                                </div>

                            </slot>

                        </info-widget>

                        <!-- Check History Widget -->
                        <info-widget :collapsible="true" :is-expanded="checksExpanded" @toggle_expand="checksExpanded = !checksExpanded" v-if="employee">
                            <template v-slot:title>Check History</template>

                            <slot v-if="!$permissions.hasRolesInCompany(client.id, employee.company_id, $store.state.security_roles.PAYCHECKS_ROLES)">
                                <div class="info-widget-empty p-2">
                                    This information is not available to your security role.
                                </div>
                            </slot>

                            <slot v-if="$permissions.hasRolesInCompany(client.id, employee.company_id, $store.state.security_roles.PAYCHECKS_ROLES)">
                                <div v-if="employee && checks.length">
                                    <section class="table-section">
                                        <div class="table-wrapper table-responsive">
                                            <table class="table css-table widget-table table-fixed">
                                                <thead>
                                                    <tr>
                                                        <th class="cw-checknum">Check Num</th>
                                                        <th class="cw-7 cell-right">Check Date</th>
                                                        <th class="cw-pay cell-right">Net Pay</th>
                                                        <th class="cw-7">Check Type</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-for="(check, i) in visibleChecks" :key="check.id" :class="{'active-item': check.id == activeCheckId}">
                                                    <tr>
                                                        <td class="cw-checknum">
                                                            <a href @click.prevent="onCheckClicked(check)">
                                                                <!-- Blank if no check number -->
                                                                {{ check.check_number || '' }}
                                                            </a>
                                                        </td>
                                                        <td class="cw-7 cell-right">
                                                            <a href @click.prevent="onCheckClicked(check)">
                                                                {{ $filters.dateshort(check.check_date) }}
                                                            </a>
                                                        </td>
                                                        <td class="cw-pay cell-right">
                                                            <span>
                                                                ${{ check.net_pay }}
                                                            </span>
                                                        </td>
                                                        <td class="cw-7">
                                                            <span>
                                                                {{ check.check_type_display }}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                    <div v-if="!showAllChecks && employee && checks.length > 5" class="pb-1">
                                        <button type="button" class="btn btn-link btn-block" @click.prevent="showAllChecks = true">
                                            See all
                                        </button>
                                    </div>
                                    <div v-if="showAllChecks && employee && checks.length > 5" class="pb-1">
                                        <button type="button" class="btn btn-link btn-block" @click.prevent="showAllChecks = false">
                                            See fewer
                                        </button>
                                    </div>
                                </div>
                                <div v-if="!checks.length">
                                    <div class="info-widget-empty p-2">
                                        This employee has no check history.
                                    </div>
                                </div>
                            </slot>
                        </info-widget>

                        <!-- Tax Documents Widget -->
                        <info-widget :collapsible="true" :is-expanded="taxDocsExpanded" @toggle_expand="taxDocsExpanded = !taxDocsExpanded" v-if="employee">
                            <template v-slot:title>Tax Documents</template>

                            <slot v-if="!$permissions.hasRolesInCompany(client.id, employee.company_id, $store.state.security_roles.EMPLOYEE_TAX_DOCUMENTS_ROLES)">
                                <div class="info-widget-empty p-2">
                                    This information is not available to your security role.
                                </div>
                            </slot>

                            <slot v-if="$permissions.hasRolesInCompany(client.id, employee.company_id, $store.state.security_roles.EMPLOYEE_TAX_DOCUMENTS_ROLES)">
                                <div v-if="employee && taxForms.length">
                                    <section class="table-section" v-if="employee && taxForms.length">
                                        <div class="table-wrapper table-responsive">
                                            <table class="table css-table widget-table table-fixed">
                                                <thead>
                                                    <tr>
                                                        <th class="">Document</th>
                                                        <th class="cw-6 cell-right">Year</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-for="doc in visibleTaxForms" :key="doc.id" :class="{'active-item': doc.id == activeTaxDocumentId}">
                                                    <tr>
                                                        <td>
                                                            <a href @click.prevent="setCurrentRestrictedDoc(doc)">
                                                                {{ doc.document_title }}
                                                            </a>
                                                        </td>
                                                        <td class="cw-6 cell-right">
                                                            <span>
                                                                {{ doc.tax_year }}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                    <div v-if="!showAllTaxForms && employee && taxForms.length > 5" class="pb-1">
                                        <button type="button" class="btn btn-link btn-block" @click.prevent="showAllTaxForms = true">
                                            See all
                                        </button>
                                    </div>
                                    <div v-if="showAllTaxForms && employee && taxForms.length > 5" class="pb-1">
                                        <button type="button" class="btn btn-link btn-block" @click.prevent="showAllTaxForms = false">
                                            See fewer
                                        </button>
                                    </div>
                                </div>

                                <div v-if="!taxForms.length">
                                    <div class="info-widget-empty p-2">
                                        This employee has no tax documents.
                                    </div>
                                </div>
                            </slot>
                        </info-widget>

                        <!-- COVID vaccination status Widget -->
                        <info-widget :collapsible="true" :is-expanded="covidVaccinationExpanded" @toggle_expand="covidVaccinationExpanded = !covidVaccinationExpanded" v-if="showCOVIDWidget && employee">
                            <template v-slot:title>COVID</template>

                            <slot v-if="!$permissions.hasRolesInCompany(client.id, employee.company_id, $store.state.security_roles.COVID_ROLES)">
                                <div class="info-widget-empty p-2">
                                    This information is not available to your security role.
                                </div>
                            </slot>

                            <slot v-if="$permissions.hasRolesInCompany(client.id, employee.company_id, $store.state.security_roles.COVID_ROLES)">
                                <div v-if="employee && hasVisibleCovidVaccinationStatuses">

                                    <section class="table-section" v-if="covidVaccinationStatusPending">
                                        <a href class="table-section-titlebar collapse-trigger" role="button" :class="{'is-expanded': expandedCovidVaccinationSections['section-pending']}" @click.prevent="expandedCovidVaccinationSections['section-pending'] = !expandedCovidVaccinationSections['section-pending']">

                                            <span class="widget-toggle-wrapper">
                                                <collapse-toggle-button />
                                            </span>
                                            <h5 class="table-section-title">Pending Vaccination Status</h5>
                                        </a>

                                        <div class="p-2">
                                            <h3>{{ covidVaccinationStatusPending.vaccination_status_pretty }}</h3>

                                            Status Submitted: {{ $filters.date(covidVaccinationStatusPending.reported_on) }}

                                            <div class="info-widget-empty pt-2 text-center">
                                                <button class="btn btn-primary" type="button" @click.prevent="currentCovidVaccinationStatus = covidVaccinationStatusPending">Start Approval</button>
                                            </div>
                                        </div>
                                    </section>

                                    <section class="table-section" v-if="covidVaccinationStatusCompleted">
                                        <a href class="table-section-titlebar collapse-trigger" role="button" :class="{'is-expanded': expandedCovidVaccinationSections['section-completed']}" @click.prevent="expandedCovidVaccinationSections['section-completed'] = !expandedCovidVaccinationSections['section-completed']">

                                            <span class="widget-toggle-wrapper">
                                                <collapse-toggle-button />
                                            </span>
                                            <h5 class="table-section-title">Completed Vaccination Status</h5>
                                        </a>
                                        <table class="table css-table widget-table table-fixed" v-show="expandedCovidVaccinationSections['section-completed']">
                                            <thead>
                                                <tr>
                                                    <th class="cw-toggle"></th>
                                                    <th>Vaccination Status</th>
                                                    <th class="cw-date text-right">Reviewed On</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="cw-toggle position-relative">
                                                        <a href class="widget-toggle-wrapper collapse-trigger" role="button" :class="{'is-expanded': expandedCovidVaccinationSections['detail-completed']}" @click.prevent="expandedCovidVaccinationSections['detail-completed'] = !expandedCovidVaccinationSections['detail-completed']">
                                                            <collapse-toggle-button />
                                                        </a>
                                                    </td>
                                                    <td class="truncate-wrapper">
                                                        {{ covidVaccinationStatusCompleted.vaccination_status_pretty }}
                                                    </td>
                                                    <td class="cw-date text-right">
                                                        <span>
                                                            {{ $filters.date(covidVaccinationStatusCompleted.accepted_on) }}
                                                        </span>
                                                    </td>
                                                </tr>

                                                <tr class="child no-transition" v-show="expandedCovidVaccinationSections['detail-completed']">
                                                    <td colspan="3">
                                                        <div class="card card-body item-details">
                                                            <div>
                                                                <section class="item-details-section">
                                                                    <p>
                                                                        Completed by {{ covidVaccinationStatusCompleted.reported_by_label }}: {{ $filters.date(covidVaccinationStatusCompleted.reported_on) }} <br/>
                                                                        <div v-if="covidVaccinationStatusCompleted.accepted_on">
                                                                            Approval Date: {{ $filters.date(covidVaccinationStatusCompleted.accepted_on) }} <br/>
                                                                        </div>
                                                                        <div v-if="covidVaccinationStatusCompleted.accepted_by_id">
                                                                            Approver: {{ covidVaccinationStatusCompleted.accepted_by.email }}
                                                                        </div>
                                                                    </p>
                                                                </section>

                                                                <div v-if="covidVaccinationStatusCompleted.vaccination_status == 'fully-vaccinated' || covidVaccinationStatusCompleted.vaccination_status == 'partially-vaccinated'" class="mb-2">

                                                                    <hr>
                                                                    <h5 class="theading">Shot Information</h5>
                                                                    <table class="table widget-table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Date</th>
                                                                                <th>Location</th>
                                                                                <th>Type</th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tr v-for="shot in covidVaccinationStatusCompleted.shots">
                                                                            <td>{{ $filters.date(shot.administered_on) }}</td>
                                                                            <td>{{ shot.location }}</td>
                                                                            <td>{{ shot.vaccine_name }}</td>
                                                                        </tr>
                                                                    </table>

                                                                    <div v-if="covidVaccinationStatusCompleted.is_attestation">
                                                                        <p>This employee's vaccination card was lost or stolen. They attest that they are {{ covidVaccinationStatusCompleted.vaccination_status_pretty }}.</p>
                                                                    </div>
                                                                </div>

                                                                <section class="item-details-section" v-if="covidVaccinationStatusCompleted.vaccination_card">
                                                                    <hr>
                                                                    <ul class="list-unstyled mb-1 ml-1">
                                                                        <li class="py-hf">
                                                                            <button class="btn btn-reset btn-link d-flex justify-content-center align-items-center" @click.prevent="currentCovidVaccinationStatus = covidVaccinationStatusCompleted">
                                                                                <div class="flex-grow-0 docphoto-thumb docphoto-thumb-button mr-1">
                                                                                    <i class="far fa-file-pdf" aria-hidden="true"></i>
                                                                                    <span class="sr-only">Add</span>
                                                                                </div>
                                                                                <div class="flex-grow-1">
                                                                                    {{ covidVaccinationStatusCompleted.vaccination_card_document_label }}
                                                                                </div>
                                                                            </button>
                                                                        </li>
                                                                    </ul>
                                                                </section>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>

                                        <div class="info-widget-empty p-2 text-center">
                                            <button class="btn btn-outline-primary" type="button" @click.prevent.stop="openCovidVaccinationStatusReportingModal">Update Employee Status</button>
                                        </div>
                                    </section>
                                </div>

                                <div v-if="!hasVisibleCovidVaccinationStatuses">
                                    <div class="info-widget-empty p-2">
                                        This employee has not reported a vaccination status.
                                    </div>

                                    <div class="info-widget-empty p-2 text-center">
                                        <button class="btn btn-outline-primary" type="button" @click.prevent.stop="openCovidVaccinationStatusReportingModal">Update Employee Status</button>
                                    </div>
                                </div>
                            </slot>
                        </info-widget>

                        <!-- E-Verify Widget -->
                        <info-widget :collapsible="true" :is-expanded="eVerifyExpanded" @toggle_expand="eVerifyExpanded = !eVerifyExpanded" v-if="showEVerifyWidget && employee">
                            <template v-slot:title>
                                E-Verify
                            </template>

                            <template v-slot:title-detail>
                                <span v-if="$permissions.hasRolesInCompany(client.id, employee.company_id, $store.state.security_roles.EVERIFY_ROLES)">
                                    <h4 class="text-dark mb-0">
                                        <i>{{ latestEVerifyCaseStatusDisplay }}</i>
                                    </h4>
                                </span>
                            </template>

                            <slot v-if="!$permissions.hasRolesInCompany(client.id, employee.company_id, $store.state.security_roles.EVERIFY_ROLES)">
                                <div class="info-widget-empty p-2">
                                    This information is not available to your security role.
                                </div>
                            </slot>

                            <slot v-if="$permissions.hasRolesInCompany(client.id, employee.company_id, $store.state.security_roles.EVERIFY_ROLES)">
                                <div v-if="latestEVerifyCase">
                                    <div class="info-widget p-2" v-if="latestEVerifyCase.case_creation_errors.errors">
                                        <div class="item-details-banner--danger p-2">
                                            <span class="text-danger-dark text-bold">Errors creating case:</span>

                                            <div v-for="err in latestEVerifyCase.case_creation_errors.errors" class="my-2">
                                                <span class="text-danger-dark text-bold">
                                                    <span v-if="err.attribute_name">{{ err.attribute_name }}: </span>
                                                    {{ err.message }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="p-2 text-center" v-if="latestEVerifyCase.case_status == 'PRE_DRAFT'">
                                        <a href class="btn btn-outline-primary" @click.prevent="openEVerifyCaseModal" v-if="otherEVerifyCases.length == 0">
                                            <i class="far fa-fw fa-pencil mr-1"></i> Create E-Verify Case
                                        </a>

                                        <a href class="btn btn-outline-primary mx-2" @click.prevent="openEVerifyCaseModal('OPEN_NEW_CASE')" v-if="otherEVerifyCases.length > 0">
                                            <i class="far fa-fw fa-pencil mr-1"></i> Open New Case
                                        </a>

                                    </div>

                                    <div class="p-2 text-center" v-if="latestEVerifyCase.case_status == 'DRAFT'">
                                        <div class="info-widget-empty pb-2 text-left">
                                            This case is in DRAFT status and has not been submitted. Continue below to submit the case.
                                        </div>

                                        <a href class="btn btn-outline-primary" @click.prevent="openEVerifyCaseModal">
                                            <i class="far fa-fw fa-pencil mr-1"></i> Submit E-Verify Case
                                        </a>
                                    </div>

                                    <div class="p-2 text-center" v-if="latestEVerifyCase.case_status == 'UNCONFIRMED_DATA'">
                                        <div class="info-widget-empty pb-2 text-left">
                                            This case has unconfirmed data that requires review before submission.
                                        </div>

                                        <a href class="btn btn-outline-primary" @click.prevent="openEVerifyCaseModal">
                                            <i class="far fa-fw fa-pencil mr-1"></i> Review Case
                                        </a>
                                    </div>

                                    <div class="p-2 text-center" v-if="latestEVerifyCase.case_status == 'MANUAL_REVIEW'">
                                        <div class="info-widget-empty pb-2 text-left">
                                            <b>{{ latestEVerifyCase.everify_case_status_display }}</b>
                                            <br><br>

                                            E-Verify needs more time to confirm that this employee is authorized to work in the United States. You should not take any action against the employee while E-Verify is reviewing this case.
                                        </div>

                                        <a href class="btn btn-outline-primary" @click.prevent="openEVerifyCaseModal">
                                            <i class="far fa-fw fa-pencil mr-1"></i> View Case
                                        </a>
                                    </div>

                                    <div class="p-2 text-center" v-if="latestEVerifyCase.case_status == 'PHOTO_MATCH'">
                                        <div class="info-widget-empty pb-2 text-left">
                                            <b>{{ latestEVerifyCase.everify_case_status_display }}</b>
                                            <br><br>

                                            <b>Action required:</b> Match photo against employee's photo document
                                        </div>

                                        <a href class="btn btn-primary" @click.prevent="openEVerifyCaseModal('PHOTO_MATCH')">
                                            <i class="far fa-fw fa-portrait mr-1"></i> Match Photo
                                        </a>
                                    </div>

                                    <div class="p-2 text-center" v-if="latestEVerifyCase.case_status == 'SCAN_AND_UPLOAD'">
                                        <div class="info-widget-empty pb-2 text-left">
                                            <b>{{ latestEVerifyCase.everify_case_status_display }}</b>
                                            <br><br>

                                            <b>Action required:</b> Scan and upload employee's photo document
                                        </div>

                                        <a href class="btn btn-primary" @click.prevent="openEVerifyCaseModal('SCAN_AND_UPLOAD')">
                                            <i class="far fa-fw fa-portrait mr-1"></i> Scan and Upload
                                        </a>
                                    </div>

                                    <div class="p-2 text-center" v-if="latestEVerifyCase.case_status == 'PENDING_REFERRAL'">
                                        <div class="info-widget-empty pb-2 text-left">
                                            <b>{{ latestEVerifyCase.everify_case_status_display }}</b>
                                            <br><br>

                                            It's okay! E-Verify just needs some more information from you and the employee before confirming employment authorization. Here are the next steps you both will need to take.
                                        </div>

                                        <a href class="btn btn-outline-primary" @click.prevent="openEVerifyCaseModal('TNC')">
                                            <i class="far fa-fw fa-pencil mr-1"></i> View Next Steps
                                        </a>
                                    </div>

                                    <div class="p-2 text-center" v-if="latestEVerifyCase.case_status == 'REFERRED'">
                                        <div class="info-widget-empty pb-2 text-left">
                                            <b>{{ latestEVerifyCase.everify_case_status_display }}</b>
                                            <br><br>

                                            This employee has been referred to DHS/SSA to resolve a mismatch.
                                        </div>

                                        <a href class="btn btn-outline-primary" @click.prevent="openEVerifyCaseModal('REFERRED')">
                                            <i class="far fa-fw fa-pencil mr-1"></i> View Case
                                        </a>
                                    </div>

                                    <div class="p-2 text-center" v-if="latestEVerifyCase.case_status == 'FINAL_NONCONFIRMATION'">
                                        <div class="info-widget-empty pb-2 text-left">
                                            <b>{{ latestEVerifyCase.everify_case_status_display }}</b>
                                            <br><br>

                                            E-Verify was not able to verify employment eligibility.
                                        </div>

                                        <a href class="btn btn-outline-primary" @click.prevent="openEVerifyCaseModal('FNC')">
                                            <i class="far fa-fw fa-pencil mr-1"></i> Close Case
                                        </a>
                                    </div>

                                    <div class="p-2 text-center" v-if="latestEVerifyCase.case_status == 'QUEUED'">
                                        <div class="info-widget-empty pb-2 text-left">
                                            <b>{{ latestEVerifyCase.everify_case_status_display }}: {{ latestEVerifyCase.get_case_eligibility_statement_display }}</b>
                                            <br><br>

                                            E-Verify needs more time to confirm that this employee is authorized to work in the United States. You should not take any action against the employee while E-Verify is reviewing this case.
                                        </div>

                                        <a href class="btn btn-outline-primary" @click.prevent="openEVerifyCaseModal">
                                            <i class="far fa-fw fa-pencil mr-1"></i> View Case
                                        </a>
                                    </div>

                                    <div class="p-2 text-center" v-if="latestEVerifyCase.case_status == 'CLOSE_CASE_AND_RESUBMIT'">
                                        <div class="info-widget-empty pb-2 text-left">
                                            <b>{{ latestEVerifyCase.everify_case_status_display }}: {{ latestEVerifyCase.get_case_eligibility_statement_display }}</b>
                                            <br><br>

                                            Something is incorrect in the data submitted. E-Verify needs you to close this case and re-enter the employee's information in a new case.
                                        </div>

                                        <a href class="btn btn-outline-primary" @click.prevent="openEVerifyCaseModal('CLOSE_CASE')">
                                            <i class="far fa-fw fa-pencil mr-1"></i> Close Case
                                        </a>
                                    </div>

                                    <div class="p-2 pb-3 text-center" v-if="latestEVerifyCase.case_status == 'CLOSED'">
                                        <div class="info-widget-empty pb-2 text-left" v-if="latestEVerifyCase.onboarding_canceled_on">
                                            Onboarding canceled, no E-Verify case created
                                        </div>
                                        <div class="info-widget-empty pb-2 text-left" v-else>
                                            <div v-if="latestEVerifyCase.get_case_eligibility_statement_display">
                                                <b>{{ latestEVerifyCase.everify_case_status_display }}: {{ latestEVerifyCase.get_case_eligibility_statement_display }}</b>
                                                <br><br>
                                            </div>

                                            This case has been closed.
                                        </div>

                                        <a href class="btn btn-outline-primary mx-2" @click.prevent="openEVerifyCaseModal">
                                            <i class="far fa-fw fa-pencil mr-1"></i> View Case
                                        </a>

                                        <a href class="btn btn-outline-primary mx-2" @click.prevent="openEVerifyCaseModal('OPEN_NEW_CASE')">
                                            <i class="far fa-fw fa-pencil mr-1"></i> Open New Case
                                        </a>
                                    </div>

                                    <div class="px-2 pb-2" v-if="otherEVerifyCases.length > 0">
                                        <hr class="mt-0 mb-3">
                                        <h4 class="theading px-2 mb-2"><b>PREVIOUS CASES</b></h4>

                                        <table class="table css-table widget-table table-fixed" v-if="otherEVerifyCases.length">
                                            <thead>
                                                <tr>
                                                    <th class="text-start">Case #</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody v-for="other_case in otherEVerifyCases">
                                                <tr>
                                                    <td class="text-start text-nowrap">
                                                        <a href class="" role="button" @click.prevent="openOtherEVerifyCaseModal(other_case)">
                                                            {{ other_case.case_number }}
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <span class="truncate-wrapper">
                                                            {{ other_case.case_status_pretty }}
                                                        </span>
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>

                                <div v-if="!latestEVerifyCase">
                                    <div class="info-widget-empty p-2">
                                        No case created yet
                                    </div>

                                    <div class="p-2 text-center">
                                        <a href class="btn btn-outline-primary" @click.prevent="openEVerifyCaseModal">
                                            <i class="far fa-fw fa-pencil mr-1"></i> Create E-Verify Case
                                        </a>
                                    </div>

                                </div>
                            </slot>
                        </info-widget>

                    </div>

                </div>

                <!-- WARNINGS Tab Pane -->
                <div class="tab-pane flex-body" :class="{'show active': activeTab == 'warnings'}" id="tabContentWarnings" role="tabpanel" aria-labelledby="tabLinkWarnings">
                    <section class="table-section">
                        <warnings-header category="Dismissed" :expanded="dismissedWarningsExpanded" :warning-count="dismissedWarnings.length" @click="dismissedWarningsExpanded = !dismissedWarningsExpanded" />
                        <warnings :warnings="dismissedWarnings" :active-warning-id="activeWarningId" v-if="employee" v-show="dismissedWarningsExpanded" @updated="onWarningsUpdate" />
                    </section>
                    <section class="table-section">
                        <warnings-header category="Unresolved" :expanded="unresolvedWarningsExpanded" :warning-count="unresolvedWarnings.length" @click="unresolvedWarningsExpanded = !unresolvedWarningsExpanded" />
                        <warnings :warnings="unresolvedWarnings" :active-warning-id="activeWarningId" v-if="employee" v-show="unresolvedWarningsExpanded" @updated="onWarningsUpdate" />
                    </section>
                    <section class="table-section">
                        <warnings-header category="Resolved" :expanded="resolvedWarningsExpanded" :warning-count="resolvedWarnings.length" @click="resolvedWarningsExpanded = !resolvedWarningsExpanded" />
                        <warnings :warnings="resolvedWarnings" :active-warning-id="activeWarningId" v-if="employee" v-show="resolvedWarningsExpanded" @updated="onWarningsUpdate" />
                    </section>
                </div>

                <!-- USER ACCESS Tab Pane -->
                <div class="tab-pane flex-body" :class="{'show active': activeTab == 'access'}" id="tabUserAccess" role="tabpanel" aria-labelledby="tabLinkUserAccess">
                    <user-access :client="client" :user="employee.user" :email="employee.email" :login-sessions="loginSessions" :is-locked="employee.is_locked" :allow-security-profile-pin-reset="false" @pin_reset="resetPIN()" @send_invite_email="resendActivationEmail(user.email)" v-if="employee">
                        <template v-slot:pin-reset-disabled-message>
                            <p class="mb-0 pt-1">
                                This user account has Security User access. To reset their PIN, go to <strong>Settings &gt; Security Users</strong>.
                            </p>
                        </template>
                    </user-access>

                    <pin-reset-modal :user="employee.user" :client="client" v-if="employee.user && employee.user.id" ref="pinResetModal" />
                </div>

                <!-- POS ACTIONS Tab Pane -->
                <div class="tab-pane flex-body" id="tabContentPOS" role="tabpanel" aria-labelledby="tabLinkPOS" v-if="employee && employeeId && client.has_pos_integration_altametrics && client.pos_sidebar_work_locations.includes(employee.work_location)">
                    <div class="tab-pane pb-2" role="tabpanel">

                        <div class="card mb-2">
                            <div class="card-header pr-1 d-flex align-items-center">
                                <span class="btnlike mr-auto">
                                    <h4 class="mb-0">Altametrics</h4>
                                </span>
                            </div>

                            <div class="card-body">
                                <div v-if="employeeId" class="mb-2">
                                    <div class="flex-grow-0 flex-shrink-0 mr-1">
                                        <p>
                                            <span class="d-block text-semibold">
                                                GEID
                                            </span>
                                            <span class="d-block">
                                                {{ employee.external_pos_id }}
                                            </span>
                                        </p>
                                        <br>

                                        <button class="btn btn-outline-primary btn-block mb-3" @click.prevent="POSActionHire()" v-if="employee.employment_status == 'Active' || $permissions.hasRolesInCompany(client.id, employee.company_id, $store.state.security_roles.POS_SIDEBAR_ROLES)">POS Rehire</button>

                                        <button class="btn btn-outline-primary btn-block mb-3 disabled" @click.prevent="POSActionHireNotAllowed()" v-if="! (employee.employment_status == 'Active' || $permissions.hasRolesInCompany(client.id, employee.company_id, $store.state.security_roles.POS_SIDEBAR_ROLES))">POS Rehire</button>

                                        <button class="btn btn-outline-primary btn-block mb-3" @click.prevent="POSActionUpdateGeneral()">POS Update General Info</button>

                                        <button class="btn btn-outline-primary btn-block mb-3" @click.prevent="POSActionUpdateJobPay()">POS Update Job/Rate</button>

                                        <button class="btn btn-outline-primary btn-block mb-3" @click.prevent="POSActionTerminate()">POS Terminate</button>

                                        <hr>

                                        <div v-if="!posDataUpdates.length > 0">
                                            <p class="py-1 m-0 text-muted">
                                                <em>No POS history recorded.</em>
                                            </p>
                                        </div>
                                        <div v-if="posDataUpdates.length > 0">
                                            <ul class="list-unstyled m-0 text-small">
                                                <li class="d-none d-sm-block">
                                                    <div class="form-row align-items-baseline py-hf">
                                                        <div class="col-5">
                                                            <h5 class="theading m-0">Date & Time</h5>
                                                        </div>
                                                        <div class="col-4">
                                                            <h5 class="theading m-0">Action</h5>
                                                        </div>
                                                        <div class="col-3">
                                                            <h5 class="theading m-0">Status</h5>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li class="boxed-li-bottom" v-for="updateObj in posDataUpdates" :key="updateObj.id">
                                                    <div class="form-row align-items-baseline py-hf">
                                                        <div class="col-12 col-sm-5">
                                                            <span>
                                                                {{ $filters.datetimeshort(updateObj.created_on) }}
                                                            </span>
                                                        </div>
                                                        <div class="col-12 col-sm-4">
                                                            <span>{{ updateObj.action_pretty }} </span>
                                                        </div>
                                                        <div class="col-12 col-sm-3">
                                                            <a href @click.prevent="posDataUpdateObj = updateObj; POSDataStatusUpdateModalOpen = true;">{{ updateObj.status_pretty }} </a>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <!-- CHANGE HISTORY Tab Pane -->
                <div class="tab-pane flex-body body-scroll-x" :class="{'show active': activeTab == 'changelogs'}" id="tabContentHistory" role="tabpanel" aria-labelledby="tabLinkHistory" v-if="$permissions.hasRolesInCompany(client.id, employee.company_id, $store.state.security_roles.CHANGE_HISTORY_ROLES)">
                    <change-history :client="client" :changelogs="changelogs" :active-changelog-id="activeChangelogId" />
                </div>

                <!-- ADMIN Tab Pane -->
                <div class="tab-pane flex-body" id="tabContentAdmin" role="tabpanel" aria-labelledby="tabLinkAdmin" v-if="employee">
                    <div class="tab-pane pb-2" role="tabpanel">

                        <!-- <tax-statuses-widget :employee="employee" :tax-statuses="taxStatuses" @updated="getData" /> -->

                        <div v-if="onboardingApplicationId">
                            <div class="form-row mb-3">
                                <div class="col-6">
                                    <button class="btn btn-outline-primary btn-block mb-1" @click.prevent="adminOnboarding()">Onboard as Employee</button>

                                    <!--
                                    <button class="btn btn-outline-primary btn-block mb-1" @click.prevent="adminRepeatOnboardingStep('wotc')">WOTC</button>
                                    <button class="btn btn-outline-primary btn-block mb-1" @click.prevent="adminRepeatOnboardingStep('hr-info')">HR Info</button>
                                    <button class="btn btn-outline-primary btn-block mb-1" @click.prevent="adminRepeatOnboardingStep('address')">Address</button>
                                    <button class="btn btn-outline-primary btn-block mb-1" @click.prevent="adminRepeatOnboardingStep('eeo-info')">EEO Information</button>
                                    <button class="btn btn-outline-primary btn-block mb-1" @click.prevent="adminRepeatOnboardingStep('forms')">Forms</button>
                                    <button class="btn btn-outline-primary btn-block mb-1" @click.prevent="adminRepeatOnboardingStep('direct-deposit')">Direct Deposit</button>
                                    -->
                                </div>

                                <div class="col-6">
                                    <button class="btn btn-outline-primary btn-block mb-1" @click.prevent="getAOSData()">View Payroll Data</button>
                                </div>
                            </div>

                            <div class="form-row mb-3">
                                <div class="col-6">
                                    <button class="btn btn-outline-primary btn-block mb-1" @click.prevent="adminClearSchoolDistrict()">Clear School District</button>
                                </div>
                            </div>

                            <div class="form-row mb-3">
                                <div class="col-6">
                                    <button class="btn btn-outline-primary btn-block mb-1" @click.prevent="adminClearI9()">Clear I-9</button>
                                </div>
                                <div class="col-6">
                                    <button class="btn btn-outline-primary btn-block mb-1" @click.prevent="adminClearEVerify()">Clear E-Verify</button>
                                </div>
                            </div>

                            <div class="my-2" v-if="aosData">
                                <pre><code>{{ aosData }}</code></pre>
                            </div>

                            <admin-onboarding-modal
                                ref="adminOnboardingModal"
                                :employee="employee"
                                :client="client"
                                :repeat-step="adminOnboardingRepeatStep"
                                @updated="$emit('updated', $event)"
                                @close="adminOnboardingRepeatStep = null"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <paycheck-modal :client="client" :paycheck="currentCheck" v-if="currentCheck" ref="paycheckModal" @close="currentCheck = null" />
            <w2-modal :client="client" :w2detail="currentW2" v-if="currentW2" ref="W2Modal" @close="currentW2 = null" />
            <pdf-modal :url="currentPDF.url" :title="currentPDF.title" v-if="currentPDF" @close="currentPDF = null" ref="pdfModal" />
            <document-upload-modal :client="client" :employee="employee" @updated="onDocumentAdded" v-if="documentUploadModalOpen" @close="documentUploadModalOpen = false" />
            <add-employee-document-modal :client="client" :employee="employee" @updated="onEmployeeDocumentAdded" v-if="addDocumentModalOpen" @close="addDocumentModalOpen = false" />
            <complete-document-task-modal :client="client" :employee="employee" :doc-task="docTaskToComplete" @updated="onDocTaskCompleted" v-if="docTaskToComplete" @close="docTaskToComplete = null" />
            <approval-modal :client="client" :employee="employee" @updated="onUpdate" ref="approvalModal" />
            <edit-modal ref="editModal" :employee="employee" :client="client" @updated="onUpdate" v-if="canEditHRInfo && !employee.hr_info_approved_on && editModalOpened" @close="editModalOpened = false" />
            <emergency-contact-modal :client="client" :employee="employee" :contacts="emergencyContacts" @updated="onEmergencyContactsUpdate" ref="emergencyContactModal" v-if="emergencyContactModalOpen" @close="emergencyContactModalOpen = false" />
            <view-document-modal :client-id="client.id" :doc="currentRestrictedDoc" v-if="currentRestrictedDoc" @submit="openRestrictedDoc(currentRestrictedDoc)" @close="currentRestrictedDoc = false"/>
            <edit-email-modal
                :email="employee.email"
                :full-name="employee.full_name"
                :client="client"
                :employee-id="employeeId"
                :onboarding-application-id="onboardingApplicationId"
                @updated="onUpdate"
                v-if="editEmailModalOpened"
                @close="editEmailModalOpened = false"
            />

            <confirm-modal v-if="docToRemove" title="Confirm: Remove Document" confirm-button-text="Remove" @submit.prevent="removeDocument()" @close="docToRemove = null">
                <template v-slot:subheading>
                    <div class="p-1 unpad-x bg-150 rounded">
                        <div><span class="text-muted">Employee:</span> {{ employee.full_name }}</div>
                        <div><span class="text-muted">Document:</span> {{ docToRemove.name }}</div>
                    </div>
                </template>
            </confirm-modal>

            <confirm-modal v-if="docTaskToCancel" title="Confirm: Cancel Document" confirm-button-text="Cancel Document" @submit.prevent="cancelDocTask()" @close="docTaskToCancel = null">
                <template v-slot:subheading>
                    <div class="p-1 unpad-x bg-150 rounded">
                        <div><span class="text-muted">Employee:</span> {{ employee.full_name }}</div>
                        <div><span class="text-muted">Document:</span> {{ docTaskToCancel.name }}</div>
                    </div>
                    <p class="mt-2 mb-0">
                        Are you sure you want to cancel this document? This document will no longer be required for this employee. A warning will be generated.
                    </p>
                </template>
            </confirm-modal>

            <doc-task-collection-settings-modal v-if="collectionSettingsDocTask" @close="collectionSettingsDocTask = null" @updated="onUpdate" :docTask="collectionSettingsDocTask" :client="client" :employee="employee"/>

            <e-verify-modal :client="client" :company="employee.company" :employee="employee" :e-verify-case="latestEVerifyCase" @updated="onEVerifyCaseUpdate($event)" v-if="EVerifyModalOpen" @close="EVerifyModalOpen = false" />

            <e-verify-modal :client="client" :company="employee.company" :employee="employee" @updated="onEVerifyCaseUpdate($event)" v-if="EVerifyNewCaseModalOpen" @close="EVerifyNewCaseModalOpen = false" :show-new-case-warning="true" />

            <e-verify-modal :client="client" :company="employee.company" :employee="employee" :e-verify-case="currentEVerifyCase" @updated="onEVerifyCaseUpdate($event)" v-if="currentEVerifyCase && EVerifyCurrentCaseModalOpen" @close="EVerifyCurrentCaseModalOpen = false; currentEVerifyCase = null;" />

            <e-verify-tnc-modal :client="client" :company="employee.company" :employee="employee" :e-verify-case="latestEVerifyCase" @updated="onEVerifyCaseUpdate($event)" v-if="EVerifyTNCModalOpen" @close="EVerifyTNCModalOpen = false" />

            <e-verify-fnc-modal :client="client" :company="employee.company" :employee="employee" :e-verify-case="latestEVerifyCase" @updated="onEVerifyCaseUpdate($event)" v-if="EVerifyFNCModalOpen" @close="EVerifyFNCModalOpen = false" />

            <e-verify-photo-match-modal :client="client" :company="employee.company" :employee="employee" :e-verify-case="latestEVerifyCase" :latest-i9-form="latestI9Form" @updated="onEVerifyCaseUpdate($event)" v-if="EVerifyPhotoMatchModalOpen" @close="EVerifyPhotoMatchModalOpen = false" />

            <e-verify-scan-and-upload-modal :client="client" :company="employee.company" :employee="employee" :e-verify-case="latestEVerifyCase" :latest-i9-form="latestI9Form" @updated="onEVerifyCaseUpdate($event)" v-if="EVerifyScanAndUploadModalOpen" @close="EVerifyScanAndUploadModalOpen = false" />

            <e-verify-close-case-modal :client="client" :company="employee.company" :employee="employee" :e-verify-case="latestEVerifyCase" @updated="onEVerifyCaseUpdate($event)" v-if="EVerifyCloseCaseModalOpen" @close="EVerifyCloseCaseModalOpen = false" />

            <covid-vaccination-status-modal
                :status="currentCovidVaccinationStatus"
                v-if="employee && currentCovidVaccinationStatus"
                @updated="onCovidVaccinationStatusUpdated"
                @close="currentCovidVaccinationStatus = null"
            />

            <covid-vaccination-status-reporting-modal
                :client="client"
                :company="employee.company"
                :employee="employee"
                v-if="employee && covidVaccinationStatusReportingModalOpen"
                @updated="onCovidVaccinationStatusUpdated"
                @close="covidVaccinationStatusReportingModalOpen = false"
            />

            <image-modal
                v-if="imageModalOpen"
                :imageUrl="imageModalUrl"
                :imageTitle="imageModalTitle"
                @close="imageModalOpen = false; imageModalUrl = ''; imageModalTitle = ''"
            />

            <pos-hire-modal
                :client="client"
                :employee="employee"
                v-if="employee && POSHireModalOpen"
                @close="POSHireModalOpen = false"
            />

            <pos-job-pay-modal
                :client="client"
                :employee="employee"
                v-if="employee && POSJobPayModalOpen"
                @close="POSJobPayModalOpen = false"
            />

            <pos-terminate-modal
                :client="client"
                :employee="employee"
                v-if="employee && POSTerminateModalOpen"
                @close="POSTerminateModalOpen = false"
            />

            <pos-data-update-status-modal
                :client="client"
                :employee="employee"
                :updateObj="posDataUpdateObj"
                v-if="posDataUpdateObj && POSDataStatusUpdateModalOpen"
                @close="POSDataStatusUpdateModalOpen = false"
            />

        </slot>

        <slot v-if="!employee || loading">
            <div class="loader loader-sidebar">
                <div class="spinner">
                <div class="lds-grid">
                    <div></div><div></div><div></div>
                    <div></div><div></div><div></div>
                    <div></div><div></div><div></div>
                </div>
                </div>
            </div>
        </slot>

    </sidebar>
</template>

<script>
import Sidebar from '@/components/Sidebar'
import ConfirmModal from '@/components/ConfirmModal'
import InfoWidget from '@/components/InfoWidget'
import ButtonRow from '@/components/ButtonRow'
import EmployeeDetails from './EmployeeDetails'
import ChangeHistory from '@/components/ChangeHistory'
import Warnings from '@/components/WarningsWidget'
import WarningsHeader from '@/components/WarningsHeaderWidget'
import UserAccess from '@/components/UserAccess'
import PINResetModal from '@/components/PINResetModal'
import UnlockWidget from '@/components/UnlockWidget'
import DocumentPhotosList from './DocumentPhotosList'
import PaycheckModal from '@/components/PaycheckModal'
import W2Modal from '@/components/W2Modal'
import PdfModal from '@/components/PdfModal'
import DocumentUploadModal from './DocumentUploadModal'
import AddEmployeeDocumentModal from './AddEmployeeDocumentModal'
import CompleteDocumentTaskModal from './CompleteDocumentTaskModal'
import ViewDocumentModal from './ViewDocumentModal'
import Approval from './Approval'
import DocTaskApproval from './DocTaskApproval'
import DocTaskCollectionSettingsModal from './DocTaskCollectionSettingsModal'
import ApprovalModal from './ApprovalModal'
import TaxStatusesWidget from './TaxStatusesWidget'
import EVerifyModal from './EVerifyModal'
import EVerifyTNCModal from './EVerifyTNCModal'
import EVerifyFNCModal from './EVerifyFNCModal'
import EVerifyPhotoMatchModal from './EVerifyPhotoMatchModal'
import EVerifyScanAndUploadModal from './EVerifyScanAndUploadModal'
import EVerifyCloseCaseModal from './EVerifyCloseCaseModal'
import POSHireModal from './pos/POSHireModal'
import POSJobPayModal from './pos/POSJobPayModal'
import POSTerminateModal from './pos/POSTerminateModal'
import POSDataUpdateStatusModal from './pos/POSDataUpdateStatusModal'
import EditModal from './EditEmployeeModal'
import AdminOnboardingModal from './AdminOnboardingModal'
import EmergencyContactModal from './EmergencyContactEditModal'
import YAML from 'js-yaml'
import EditEmailModal from '@/components/EditEmployeeEmailModal'
import CovidVaccinationStatusModal from './CovidVaccinationStatusModal'
import CovidVaccinationStatusReportingModal from './covid/CovidVaccinationStatusReportingModal'
import ImageModal from '@/components/ImageModal'
import exampleSidebarData from '@/components/Tours/example-employee-sidebar.json'

const sortManagers = (managers) => {
    managers.sort((a, b) => {
        if (a.full_name == b.full_name) {
            return a.id > b.id ? 1 : -1
        }
        return a.full_name > b.full_name ? 1 : -1
    })
}

const sortByNameAndId = (objects, nameField) => {
    objects.sort((a, b) => {
        if (a[nameField] == b[nameField]) {
            return a.id > b.id ? 1 : -1
        }
        return a[nameField] > b[nameField] ? 1 : -1
    })
}

export default {
    components: {Sidebar, InfoWidget, ButtonRow, ChangeHistory, UserAccess, PinResetModal: PINResetModal, UnlockWidget, EmployeeDetails, PaycheckModal, DocumentPhotosList, W2Modal, PdfModal, DocumentUploadModal, Warnings, WarningsHeader, Approval, DocTaskApproval, DocTaskCollectionSettingsModal, ApprovalModal, EVerifyModal, EVerifyTncModal: EVerifyTNCModal, EVerifyFncModal: EVerifyFNCModal, EVerifyPhotoMatchModal, EVerifyScanAndUploadModal, EVerifyCloseCaseModal, PosTerminateModal: POSTerminateModal, PosHireModal: POSHireModal, PosJobPayModal: POSJobPayModal, PosDataUpdateStatusModal: POSDataUpdateStatusModal, EditModal, AdminOnboardingModal, ViewDocumentModal, EmergencyContactModal, EditEmailModal, ConfirmModal, CovidVaccinationStatusModal, CovidVaccinationStatusReportingModal, TaxStatusesWidget, AddEmployeeDocumentModal, CompleteDocumentTaskModal, ImageModal},
    props: ['employeeId', 'onboardingApplicationId', 'client', 'tab', 'section', 'itemId', 'expandedItemsSubsection' ],
    computed: {
        isAdmin() {
            return this.$store.state.user.is_superuser
        },
        warningCount() {
            if (!this.employee) {return 0}
            return this.employee.warnings.filter((w) => {return !w.resolved_on && w.is_resolvable}).length
        },
        hasBouncedEmail() {
            if (!this.employee) {return false}
            return this.employee.warnings.filter(w => !w.resolved_on && w.warning_type == 'bounced-email').length > 0
        },
        canEditEmail() {
            if (this.employee.is_employee && !this.$permissions.hasRolesInCompany(this.client.id, this.employee.company_id, this.$store.state.security_roles.EMPLOYEE_ROLES)) {
                return false  // Onboarding-only managers can't edit existing employees' email addresses
            }
            return this.employee.is_employee || (this.employee.is_onboarding_application && this.employee.status != 'canceled')
        },
        visibleChecks() {
            if (!this.showAllChecks) {
                return this.checks.slice(0, 5)
            }

            return this.checks
        },
        visibleTaxForms() {
            if (!this.showAllTaxForms) {
                return this.taxForms.slice(0, 5)
            }

            return this.taxForms
        },
        hasVisibleCovidVaccinationStatuses() {
            // if it's just rejected, ignore those
            const statuses = this.covidVaccinationStatuses.filter((s) => {return s.status != 'rejected'})
            if (! statuses) {
                return false
            }

            if (! statuses.length) {
                return false
            }

            return true
        },
        covidVaccinationStatusCompleted() {
            const statuses = this.covidVaccinationStatuses.filter((s) => {return s.status == 'accepted'})
            if (! statuses) {
                return {}
            }

            statuses.sort((a, b) => {
                return (a.accepted_on > b.accepted_on) ? 1 : -1
            })
            return statuses[statuses.length - 1]
        },
        covidVaccinationStatusPending() {
            const statuses = this.covidVaccinationStatuses.filter((s) => {return s.status == 'pending'})
            if (! statuses) {
                return {}
            }

            statuses.sort((a, b) => {
                return (a.reported_on > b.reported_on) ? 1 : -1
            })
            return statuses[statuses.length - 1]
        },

        showEVerifyWidget() {
            return this.employee.company.everify_enabled && this.employee.everify_required
        },

        latestEVerifyCase() {
            const cases = this.eVerifyCases.slice()
            if (! cases) {
                return {}
            }

            return cases.toSorted((a, b) => {
                return (a.created_on > b.created_on) ? 1 : -1
            })[cases.length - 1]
        },
        otherEVerifyCases() {
            const cases = this.eVerifyCases.slice()
            if (! cases) {
                return []
            }

            // array without the latest (don't use pop bc it mutates); note order of sort
            return cases.toSorted((a, b) => {
                return (a.created_on < b.created_on) ? 1 : -1
            }).slice(1)
        },
        latestEVerifyCaseStatusDisplay() {
            // not *quite* the latest case, since the latest case may be in draft
            // but we have a previously Closed case, so don't want to show "No Case" in that case
            const cases = this.eVerifyCases.slice()

            if (cases.length == 0) {
                return "No Case"
            }

            cases.sort((a, b) => {
                return (a.created_on < b.created_on) ? 1 : -1
            })

            if (cases.length == 1) {
                if (cases[0].case_status == 'PRE_DRAFT') {
                    return "No Case"
                } else {
                    return cases[0].case_status_pretty
                }
            }

            return cases[1].case_status_pretty
        },
        latestI9Form() {
            if (!this.employee) {
                return null
            }

            // OA
            if (this.employee.is_onboarding_application) {
                return this.employee.forms.find(f => f.form_designation == 'i9')
            }

            // EE - find OA (if there is one)
            if (this.onboardingApplications.length > 0) {
                return this.onboardingApplications[0].forms.find(f => f.form_designation == 'i9')
            }

            return null;
        },
        showEVerifyCreateCase() {
            const latestCase = this.latestEVerifyCase

            if (!latestCase) {
                return true
            }

            return latestCase.case_status == 'PRE_DRAFT'
        },
        completedDocSections() {
            let activeDocs = this.docsTableData.filter((d) => {return d.most_recent})
            let inactiveDocs = this.docsTableData.filter((d) => {return !d.most_recent})
            let sections = [
                {
                    'slug': 'active',
                    'title': 'Active Documents',
                    'count': activeDocs.length,
                    'rows': activeDocs,
                },
            ]
            // only show inactive section for employees, not onboarding apps
            if (this.employeeId) {
                sections.push({
                    'slug': 'inactive',
                    'title': 'Inactive Documents',
                    'count': inactiveDocs.length,
                    'rows': inactiveDocs,
                })
            }
            return sections
        },
        incompleteDocSections() {
            let docTasksPendingEmployeeCompletion = this.incompleteDocTasksTableData.filter((d) => {return d.status == 'needs-employee-response'})
            let docTasksPendingManagerCompletion = this.incompleteDocTasksTableData.filter((d) => {return d.status == 'needs-manager-response'})
            let docTasksPendingApproval = this.incompleteDocTasksTableData.filter((d) => {return d.status == 'needs-manager-approval'})
            return [
                {
                    'slug': 'needs-manager-approval',
                    'title': 'Ready for Approval',
                    'dateFieldTitle': 'Submitted On',
                    'count': docTasksPendingApproval.length,
                    'rows': docTasksPendingApproval,
                },
                {
                    'slug': 'pending-employee-completion',
                    'title': 'Pending Employee Completion',
                    'dateFieldTitle': 'Reminded On',
                    'count': docTasksPendingEmployeeCompletion.length,
                    'rows': docTasksPendingEmployeeCompletion,
                },
                {
                    'slug': 'pending-manager-completion',
                    'title': 'Pending Manager Completion',
                    'dateFieldTitle': 'Due On',
                    'count': docTasksPendingManagerCompletion.length,
                    'rows': docTasksPendingManagerCompletion,
                },
            ]
        },
        payrollAdmins() {
            if (!this.employee) {return []}

            const pas = (this.managers[this.$store.state.security_roles.ROLE_PAYROLL_ADMIN] || [])
            const opas = (this.managers[this.$store.state.security_roles.ROLE_OFFICER_PA] || [])
            const managers = (pas.concat(opas)).map(csp => csp.security_profile)
            sortManagers(managers)
            return managers
        },
        workLocations() {
            if (!this.employee) {return []}
            if (!this.employee.jobs) {return []}

            const locations = {}
            this.employee.jobs.forEach(j => {
                locations[j.position.work_location.id] = j.position.work_location
            })
            const result = []
            Object.keys(locations).forEach(id => {
                result.push(locations[id])
            })
            sortByNameAndId(result, 'name')
            return result
        },
        managersByLocation() {
            const result = {}
            const roles = [
                this.$store.state.security_roles.ROLE_LOCATION_MANAGER,
                this.$store.state.security_roles.ROLE_LOCATION_MANAGER_APPROVER,
                this.$store.state.security_roles.ROLE_STORE_MANAGER,
                this.$store.state.security_roles.ROLE_EMPLOYEE_MANAGER,
            ]

            this.workLocations.forEach(w => {
                result[w.id] = {}
                roles.forEach(roleId => {
                    result[w.id][roleId] = []
                })
            })

            Object.keys(this.managers).forEach(role => {
                if (!roles.includes(role)) {return}

                this.managers[role].forEach(csp => {
                    let workLocationId;
                    if (this.$store.state.security_roles.LOCATION_LEVEL_ROLES.includes(csp.role)) {
                        csp.work_location_security_profiles.forEach(wlsp => {
                            workLocationId = wlsp.work_location.id
                            if (!result[workLocationId]) {return}

                            if (!result[workLocationId][csp.role].find(x => x.id == csp.security_profile.id)) {
                                result[workLocationId][csp.role].push(csp.security_profile)
                            }
                        })
                    }
                    else {
                        csp.security_profile_positions.forEach(spp => {
                            workLocationId = spp.position.work_location.id
                            if (!result[workLocationId]) {return}

                            if (!result[workLocationId][csp.role].find(x => x.id == csp.security_profile.id)) {
                                result[workLocationId][csp.role].push(csp.security_profile)
                            }
                        })

                    }

                })
            })

            return result
        },

        docsTableData() {
            const result = []

            // If this is an Employee show all the EmployeeDocuments
            if (this.employeeId && this.employee) {
                this.employeeDocuments.forEach(d => {
                    let dateSigned = ''
                    if (d.signature_data) {
                        if (d.signature_data.signature) {
                            dateSigned = d.signature_data.signature.date ? d.signature_data.signature.date : ''
                        } else if (d.signature_data.date) {
                            dateSigned = d.signature_data.date
                        }
                    }
                    let managerDateSigned = ''
                    if (d.signature_data.manager_signature) {
                        managerDateSigned = d.signature_data.manager_signature.date
                    }

                    result.push({
                        id: `employee-doc-${d.id}`,
                        url: `${this.$api.API_BASE}/clients/${this.client.id}/employees/${this.employee.id}/documents/${d.id}/pdf`,
                        name: d.name,
                        filename: d.name.toLowerCase().endsWith('.pdf') ? d.name : d.name + '.pdf',
                        version: d.version,
                        date_signed: dateSigned,
                        manager_date_signed: managerDateSigned,
                        manager_name: d.signature_data.manager_signature ? d.signature_data.manager_signature.full_name : '',
                        date_submitted: d.date_submitted,
                        date_added: d.date_added,
                        completed_on: d.completed_on,
                        visible_to_employee: d.visible_to_employee,
                        source_pretty: d.source_pretty,
                        document_type: d.document_type,
                        employee_document: d,
                        onboarding_application_pdf_form: d.onboarding_application_pdf_form,
                        onboarding_application_hr_document: d.onboarding_application_hr_document,
                        onboarding_application_hr_form_document: d.onboarding_application_hr_form_document,
                        onboarding_application_uniform: d.onboarding_application_uniform,
                        onboarding_application_hr_custom_form: d.onboarding_application_hr_custom_form,
                        employee_update_pdf_form: d.employee_update_pdf_form,
                        employee_hr_document: d.employee_hr_document,
                        employee_uniform: d.employee_uniform,
                        employee_hr_custom_form: d.employee_hr_custom_form,
                        response_data: d.response_data,
                        most_recent: d.most_recent,
                    })
                })

                return result
            }
            // If this is an OnboardingApplication, show all accepted documents
            else if (this.onboardingApplicationId && this.employee) {
                this.employee.hr_documents.forEach(d => {
                    // only include docs that have a PDF
                    if (d.pdf_file) {
                        result.push({
                            id: `hr-doc-${d.id}`,
                            url: `${this.$api.API_BASE}/clients/${this.client.id}/onboarding-applications/${this.employee.id}/hr-documents/${d.id}/pdf`,
                            name: d.hr_document.name,
                            filename: d.hr_document.name.toLowerCase().endsWith('.pdf') ? d.hr_document.name : d.hr_document.name + '.pdf',
                            version: d.hr_document.version,
                            date_signed: d.signature_data.signature ? d.signature_data.signature.date : '',
                            manager_date_signed: d.signature_data.manager_signature ? d.signature_data.manager_signature.date : '',
                            manager_name: d.signature_data.manager_signature ? d.signature_data.manager_signature.full_name : '',
                            manager_signature_required: d.manager_signature_required,
                            source_pretty: 'Onboarding',
                            document_type: d.document_type,
                            employee_document: null,
                            onboarding_application_pdf_form: null,
                            onboarding_application_hr_document: d,
                            onboarding_application_hr_form_document: null,
                            onboarding_application_uniform: null,
                            onboarding_application_hr_custom_form: null,
                            most_recent: true,
                        })
                    }
                })

                this.employee.hr_form_documents.forEach(d => {
                    result.push({
                        id: `hr-form-doc-${d.id}`,
                        url: `${this.$api.API_BASE}/clients/${this.client.id}/onboarding-applications/${this.employee.id}/hr-form-documents/${d.id}/pdf`,
                        name: d.hr_form_document.name,
                        filename: d.hr_form_document.name.toLowerCase().endsWith('.pdf') ? d.hr_form_document.name : d.hr_form_document.name + '.pdf',
                        version: d.version,
                        date_signed: (d.signature_data && d.signature_data.signature) ? d.signature_data.signature.date : '',
                        manager_date_signed: d.signature_data.manager_signature ? d.signature_data.manager_signature.date : '',
                        manager_name: d.signature_data.manager_signature ? d.signature_data.manager_signature.full_name : '',
                        manager_signature_required: true,
                        source_pretty: 'Onboarding',
                        document_type: d.document_type,
                        employee_document: null,
                        onboarding_application_pdf_form: null,
                        onboarding_application_hr_document: null,
                        onboarding_application_hr_form_document: d,
                        onboarding_application_uniform: null,
                        onboarding_application_hr_custom_form: null,
                        most_recent: true,
                    })
                })

                this.employee.forms.forEach(d => {
                    const name = d.form_name
                    result.push({
                        id: `form-${d.id}`,
                        url: `${this.$api.API_BASE}/clients/${this.client.id}/onboarding-applications/${this.employee.id}/forms/${d.id}/pdf`,
                        name: name,
                        filename: name.toLowerCase().endsWith('.pdf') ? name : name + '.pdf',
                        version: 1,
                        date_signed: (d.signature_data && d.signature_data.signature) ? d.signature_data.signature.date : '',
                        manager_date_signed: d.signature_data.manager_signature ? d.signature_data.manager_signature.date : '',
                        manager_name: d.signature_data.manager_signature ? d.signature_data.manager_signature.full_name : '',
                        manager_signature_required: true,
                        source_pretty: 'Onboarding',
                        document_type: d.document_type,
                        employee_document: null,
                        onboarding_application_pdf_form: d,
                        onboarding_application_hr_document: null,
                        onboarding_application_hr_form_document: null,
                        onboarding_application_uniform: null,
                        onboarding_application_hr_custom_form: null,
                        most_recent: true,
                    })
                })

                // filter out documents that require manager signature and have not yet been signed by manager
                return result.filter(d => (['Import', 'Upload'].includes(d.source_pretty) || d.manager_date_signed || !d.manager_signature_required))
            }
        },
        docTasksTableData() {
            let result = []

            this.employeeDocumentTasks.forEach(t => {
                let url = ''
                if (t.employee_hr_document_id) {
                    url = `${this.$api.API_BASE}/clients/${this.client.id}/employees/${this.employee.id}/hr-documents/${t.employee_hr_document_id}/signed`
                } else if (t.hr_document) {
                    url = `${this.$api.API_BASE}/clients/${this.client.id}/hr-documents/${t.hr_document_id}/file/pdf`
                } else if (t.employee_hr_custom_form) {
                    url = `${this.$api.API_BASE}/clients/${this.client.id}/employees/${this.employee.id}/hr-custom-forms/${t.employee_hr_custom_form_id}/pdf`
                }
                result.push({
                    id: t.id,
                    url: url,
                    name: t.document_name,
                    task_type: t.task_type,
                    filename: t.document_name.toLowerCase().endsWith('.pdf') ? t.document_name : t.document_name + '.pdf',
                    hr_document: t.hr_document,
                    hr_custom_form: t.hr_custom_form,
                    employee_hr_custom_form: t.employee_hr_custom_form,
                    uniform: t.uniform,
                    submitted_version_number: t.submitted_version_number,
                    assigned_on: t.assigned_on,
                    assigned_by: t.assigned_by_pretty,
                    due_on: t.due_on,
                    last_reminded_on: t.last_reminded_on || t.assigned_on,
                    canceled_on: t.canceled_on,
                    canceled_by: t.canceled_by_pretty,
                    responded_on: t.responded_on,
                    block_ee_until_completion: t.block_ee_until_completion,
                    status: t.status,
                })
            })

            return result
        },
        incompleteDocTasksTableData() {
            return this.docTasksTableData.filter(t => ['needs-employee-response', 'needs-manager-response', 'needs-manager-approval'].includes(t.status))
        },
        canceledDocTasksTableData() {
            return this.docTasksTableData.filter(t => ['exempted', 'canceled'].includes(t.status))
        },
        docTasksPendingApproval() {
            return this.employeeDocumentTasks.filter(t => t.status == 'needs-manager-approval')
        },
        unresolvedWarnings() {
            if (!this.employee) {return []}
            return this.employee.warnings.filter(w => {
                const isDismissed = (this.$store.state.user.acknowledged_warning_ids.indexOf(w.id) >= 0)
                return (!isDismissed && !w.is_resolved)
            })
        },
        resolvedWarnings() {
            if (!this.employee) {return []}
            return this.employee.warnings.filter(w => {
                return w.is_resolved
            })
        },
        dismissedWarnings() {
            if (!this.employee) {return []}
            return this.employee.warnings.filter(w => {
                const isDismissed = (this.$store.state.user.acknowledged_warning_ids.indexOf(w.id) >= 0)
                return isDismissed && !w.is_resolved
            })
        },

        /* These have to do with dealing with onboarding */
        approvalStarted() {
            if (!this.onboardingApplicationId) {return false}

            if (this.employee.hr_info_approved_on) {return true}
            if (this.employee.i9_approval_completed) {return true}
            if (this.employee.direct_deposit_approved_on) {return true}
            const formsApproved = this.employee.forms.filter((f) => {
                return f.accepted_on
            }).length

            if (formsApproved > 0) {return true}
            return false
        },
        canEditHRInfo() {
            if (!this.onboardingApplicationId) {return false}

            return !(this.employee.status == 'hired' || this.employee.status == 'canceled')
        },
        workLocationIds() {
            if (!this.onboardingApplicationId) {return []}

            return Array.from(new Set(this.employee.jobs.map(j => j.position.work_location.id)))
        },
        positionIds() {
            if (!this.onboardingApplicationId) {return []}

            return Array.from(new Set(this.employee.jobs.map(j => j.position.id)))
        },
        userHasAccess() {
            if (!this.onboardingApplicationId) {return false}

            return this.$permissions.hasAccess(
                this.employee.company.client,
                this.employee.company.id,
                this.workLocationIds,
                this.positionIds,
            )
        },
        i9CompletedLateWarnings() {
            const result = []
            const warnings = this.employee.warnings.filter(w => w.warning_type == 'i9-completed-late')
            warnings.forEach(w => {
                const o = this.onboardingApplications.find(o => o.id == w.onboarding_application_id)
                if (o) {
                    const i9 = o.forms.find(f => f.form_designation == 'i9')
                    result.push({
                        warning: w,
                        onboardingApplication: o,
                        i9: i9,
                        date: i9.employer_form_data.date_hired
                    })
                }
            })
            return result
        },
        hrDocsSuiteReleased() {
            return this.$store.state.system_flags?.hr_docs_suite_released || this.client.preview_hr_docs_suite
        },
        hrDocsSuiteEnabled() {
            if (!this.employee) { return false }
            return (this.client.has_company_docs_module && this.employee.company.has_company_docs_module) ||
                (this.client.has_uniform_module && this.employee.company.has_uniform_module) ||
                (this.client.has_custom_forms_module && this.employee.company.has_custom_forms_module) ||
                (this.client.has_ad_hoc_docs_module && this.employee.company.has_ad_hoc_docs_module)
        },
        canEditCollectionSettings() {
            return this.$permissions.hasRolesInCompany(this.client.id, this.employee.company_id, this.$store.state.security_roles.HR_DOCUMENTS_ROLES)
        }
    },
    mounted() {
        this.getData()
    },
    data() {
        const activeCompletedDocId = (this.section == 'completed-docs') ? this.itemId : null
        const activeIncompleteDocTaskId = (this.section == 'incomplete-docs' ? this.itemId : null)
        const activeCanceledDocTaskId = (this.section == 'canceled-docs' ? this.itemId : null)
        const expandedCompletedDocs = {}
        const expandedIncompleteDocTasks = {}
        const expandedCanceledDocTasks = {}
        let expandedCompletedDocSections = {
            'active': true,
            'inactive': false,
        }
        let expandedIncompleteDocSections = {
            'pending-employee-completion': true,
            'pending-manager-completion': true,
            'ready-for-approval': true,
        }
        let expandedCanceledDocSections = {
            'rejected': true,
            'requirement-removed': true,
        }
        if (activeCompletedDocId) {
            expandedCompletedDocs[`employee-doc-${activeCompletedDocId}`] = true
            if (this.expandedItemsSubsection == 'inactive') {
                expandedCompletedDocSections = {
                    'active': false,
                    'inactive': true,
                }
            }
        } else if (activeIncompleteDocTaskId) {
            expandedIncompleteDocTasks[activeIncompleteDocTaskId] = true
        } else if (activeCanceledDocTaskId) {
            expandedCanceledDocTasks[activeCanceledDocTaskId] = true
        }

        return {
            loading: false,
            employee: null,
            approvalMode: false,
            docTaskApprovalMode: false,
            changelogs: [],
            editModalOpened: false,
            editEmailModalOpened: false,
            currentEVerifyCase: null,
            EVerifyModalOpen: false,
            EVerifyNewCaseModalOpen: false,
            EVerifyCurrentCaseModalOpen: false,
            EVerifyTNCModalOpen: false,
            EVerifyFNCModalOpen: false,
            EVerifyPhotoMatchModalOpen: false,
            EVerifyScanAndUploadModalOpen: false,
            EVerifyCloseCaseModalOpen: false,
            POSHireModalOpen: false,
            POSJobPayModalOpen: false,
            POSTerminateModalOpen: false,
            POSDataStatusUpdateModalOpen: false,
            adminOnboardingRepeatStep: null,
            aosData: null,

            activeOnboardingCount: 0,
            onboardingApplications: [],
            managers: {},
            employeeContacts: [],
            employeeDocuments: [],
            employeeDocumentTasks: [],
            emergencyContacts: [],
            taxForms: [],
            covidVaccinationStatuses: [],
            eVerifyCases: [],
            loginSessions: [],
            checks: [],
            taxStatuses: [],
            posDataUpdates: [],

            currentCheck: null,
            currentW2: null,
            currentPDF: null,
            currentRestrictedDoc: null,
            currentCovidVaccinationStatus: null,
            docToRemove: null,
            docTaskToCancel: null,
            collectionSettingsDocTask: null,
            collection_method: '',
            block_ee_until_completion: null,
            showAllChecks: Boolean(this.activeCheckId),
            showAllTaxForms: Boolean(this.activeTaxDocumentId),
            showAllCovidVaccinationStatuses: Boolean(this.activeCovidVaccinationStatusId),
            expandedCompletedDocs: expandedCompletedDocs,
            expandedIncompleteDocTasks: expandedIncompleteDocTasks,
            expandedCanceledDocTasks: expandedCanceledDocTasks,
            expandedEmergencyContacts: {},
            expandedOnboardingApplications: {},
            expandedCompletedDocSections: expandedCompletedDocSections,
            expandedIncompleteDocSections: expandedIncompleteDocSections,
            expandedCanceledDocSections: expandedCanceledDocSections,
            expandedCovidVaccinationSections: {
                'section-completed': true,
                'section-pending': true,
                'detail-completed': false,
            },
            documentUploadModalOpen: false,
            addDocumentModalOpen: false,
            docTaskToComplete: null,
            posDataUpdateObj: null,
            // Below line added for testing
            emergencyContactModalOpen: false,
            covidVaccinationStatusReportingModalOpen: false,

            activeTab: this.tab,

            onboardingAppsExpanded: this.section == 'onboarding' || this.tab != 'misc',
            managersExpanded: this.section == 'managers' || this.tab != 'misc',
            contactsExpanded: this.section == 'contacts' || this.tab != 'misc',
            completedDocsWidgetExpanded: this.section == 'completed-docs' || this.tab != 'hr-docs',
            incompleteDocsWidgetExpanded: this.section == 'incomplete-docs' || this.tab != 'hr-docs',
            canceledDocsWidgetExpanded: this.section == 'canceled-docs' || this.tab != 'hr-docs',
            checksExpanded: this.section == 'checks' || this.tab != 'misc',
            taxDocsExpanded: this.section == 'tax-documents' || this.tab != 'misc',
            covidVaccinationExpanded: this.section == 'covid-vaccination-statuses' || this.tab != 'misc',
            eVerifyExpanded: this.section == 'e-verify' || this.tab != 'misc',

            activeCheckId: (this.section == 'checks') ? this.itemId : null,
            activeCompletedDocId: activeCompletedDocId,
            activeIncompleteDocTaskId: activeIncompleteDocTaskId,
            activeCanceledDocTaskId: activeCanceledDocTaskId,
            activeTaxDocumentId: (this.section == 'tax-documents') ? this.itemId : null,
            activeCovidVaccinationStatusId: (this.section == 'covid-vaccination-statuses') ? this.itemId : null,

            activeWarningId: (this.tab == 'warnings') ? this.itemId : null,
            activeChangelogId: (this.tab == 'changelogs') ? this.itemId : null,

            unresolvedWarningsExpanded: true,
            resolvedWarningsExpanded: true,
            dismissedWarningsExpanded: true,

            showCOVIDWidget: false,

            imageModalOpen: false,
            imageModalUrl: '',
            imageModalTitle: '',
        }
    },
    methods: {
        getData() {
            if (this.employeeId) {
                return this.getEmployee()
            }
            else if (this.onboardingApplicationId) {
                return this.getOnboardingApplication()
            }
        },
        getEmployee() {
            if (this.$store.state.activeTour == 'hr-suite') {
                this.employee = exampleSidebarData.employee
                this.employeeDocumentTasks = exampleSidebarData.doc_tasks
                this.employeeDocuments = exampleSidebarData.docs
                return
            }
            this.loading = true
            return this.$api.get(`/clients/${this.client.id}/employees/${this.employeeId}/misc-hr-info`).then(resp => {

                this.employee = resp.employee
                this.taxStatuses = resp.tax_statuses
                this.activeOnboardingCount = resp.active_onboarding_count
                this.managers = resp.managers
                this.employeeContacts = resp.managers
                this.employeeDocuments = resp.docs
                this.employeeDocumentTasks = resp.doc_tasks
                this.emergencyContacts = resp.emergency_contacts
                this.taxForms = resp.tax_forms
                this.covidVaccinationStatuses = resp.covid_vaccination_statuses
                this.eVerifyCases = resp.everify_cases
                this.loginSessions = resp.login_sessions
                this.checks = resp.checks
                this.changelogs = resp.changelogs
                this.onboardingApplications = resp.onboarding_applications
                this.posDataUpdates = resp.pos_data_updates
                this.loading = false

                /* XXX: need to figure out how to make this scroll correctly
                this.$nextTick(() => {
                    let offset = 0
                    if (this.activeCompletedDocId) {
                        const idx = this.docs.findIndex(d => `${d.id}` == `${this.activeCompletedDocId}`)
                        offset = this.$refs.docs[idx].getBoundingClientRect().top
                    }
                    this.$refs.sidebar.scrollTop(offset)
                })
                */
            }).catch(errors => {
                this.$bus.showError(errors.__all__)
                this.loading = false
            })
        },
        getOnboardingApplication() {
            this.loading = true
            return this.$api.get(`/clients/${this.client.id}/onboarding-applications/${this.onboardingApplicationId}/misc-hr-info`).then(resp => {
                this.employee = resp.onboarding_application
                this.activeOnboardingCount = 0  // XXX: is this correct?
                this.managers = resp.managers
                this.taxForms = []
                this.covidVaccinationStatuses = []
                this.eVerifyCases = resp.everify_cases
                this.loginSessions = resp.login_sessions
                this.emergencyContacts = resp.emergency_contacts
                this.posDataUpdates = resp.pos_data_updates
                this.checks = []
                this.changelogs = resp.changelogs
                this.loading = false
            }).catch(errors => {
                this.$bus.showError(errors.__all__)
                this.loading = false
            })
        },
        resetPIN() {
            this.$refs.pinResetModal.open()
        },
        resendActivationEmail() {
            if (!this.onboardingApplicationId) {return}

            this.$store.dispatch('START_LOADING')
            this.$api.post(`/clients/${this.client.id}/onboarding-applications/${this.employee.id}/invitation`, {}).then(() => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showInfo(`Email sent to ${this.employee.email}.`)
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        startApproval() {
            this.approvalMode = true
            this.$bus.trackEvent(
                "Onboarding App Start Approval",
                {'app_id': this.onboardingApplicationId, 'client_id': this.client.id, 'company_id': this.employee.company.id}
            )
        },
        startDocTaskApproval() {
            this.docTaskApprovalMode = true
        },
        sendReminder() {
            this.$store.dispatch('START_LOADING')
            this.$api.post(`/clients/${this.client.id}/employees/${this.employee.id}/document-tasks/send-reminder`, {}).then(() => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showInfo(`Email sent to ${this.employee.email}.`)
                this.onUpdate()
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        cancelOnboarding() {
            if (!this.onboardingApplicationId) {return}

            if (confirm('Are you sure?')) {
                this.$store.dispatch('START_LOADING')
                this.$api.patch(`/clients/${this.client.id}/onboarding-applications/${this.employee.id}`, {status: 'canceled'}).then((resp) => {
                    this.$store.dispatch('STOP_LOADING')
                    Object.assign(this.employee, resp)
                    this.$emit('updated', resp)
                }).catch((errors) => {
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.showError(errors.error)
                })
            }
        },
        onCheckClicked(check) {
            this.currentCheck = check
            this.$nextTick(() => {
                this.$refs.paycheckModal.open()
            })
        },
        onW2Clicked(w2_detail) {
            this.currentW2 = w2_detail
            this.$nextTick(() => {
                this.$refs.W2Modal.open()
            })
        },
        setCurrentRestrictedDoc(doc) {
            this.currentRestrictedDoc = doc
        },
        openRestrictedDoc(doc) {
            this.currentRestrictedDoc = null
            // if it's a W2, open the modal; otherwise, open the PDF
            if (doc.document_type == 'w2') {
                this.currentW2 = doc
                this.$nextTick(() => {
                    this.$refs.W2Modal.open()
                })
            } else {
                this.currentPDF = {
                    title: doc.name,
                    url: {
                        url: doc.url,
                        withCredentials: true,
                    },
                }
            }
        },
        setCurrentPDF(doc) {
            this.currentRestrictedDoc = null
            this.currentPDF = {
                title: doc.name,
                url: {
                    url: doc.url,
                    withCredentials: true,
                },
            }
        },
        openCancelDocTaskModal(docTask) {
            this.docTaskToCancel = docTask
        },
        openCollectionSettingsModal(docTask) {
            this.collectionSettingsDocTask = docTask
        },
        cancelDocTask() {
            if (!this.docTaskToCancel) {return}

            this.$store.dispatch('START_LOADING')
            this.$api.post(`/clients/${this.client.id}/employees/${this.employee.id}/document-tasks/${this.docTaskToCancel.id}`, {'action': 'cancel'}).then(() => {
                this.$store.dispatch('STOP_LOADING')
                // this.employeeDocTasks = this.employeeDocTasks.filter(t => t.id != this.docTaskToCancel.id)
                this.$bus.showInfo(`Document was successfully canceled.`)
                this.$emit('doc-task-canceled', this.docTaskToCancel.id)
                this.docTaskToCancel = null
                this.$nextTick(() => this.getData().then(() => {
                    this.$emit('updated', this.employee)
                }))
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        removeDocument() {
            if (!this.docToRemove) {return}
            if (!this.docToRemove.employee_document) {return}

            this.$store.dispatch('START_LOADING')
            this.$api.del(`/clients/${this.client.id}/employees/${this.employee.id}/documents/${this.docToRemove.employee_document.id}?version=${this.docToRemove.employee_document.version}`).then(() => {
                this.$store.dispatch('STOP_LOADING')
                if (this.docToRemove.employee_document) {
                    this.employeeDocuments = this.employeeDocuments.filter(d => d.id != this.docToRemove.employee_document.id)
                }
                else if (this.docToRemove.onboarding_application_pdf_form) { // TODO KB I don't think we'll reach this code - test this
                    this.employee.forms = this.employee.forms.filter(d => d.id != this.docToRemove.onboarding_application_pdf_form.id)
                }
                else if (this.docToRemove.onboarding_application_hr_document) {
                    this.employee.hr_documents = this.employee.hr_documents.filter(d => d.id != this.docToRemove.onboarding_application_hr_document.id)
                }
                else if (this.docToRemove.onboarding_application_hr_form_document) {
                    this.employee.hr_form_documents = this.employee.hr_form_documents.filter(d => d.id != this.docToRemove.onboarding_application_hr_form_document.id)
                }

                this.$bus.showInfo(`Document was successfully removed.`)
                this.$emit('employee-document-removed', this.docToRemove.employee_document.id)
                this.docToRemove = null
                this.$nextTick(() => this.getData().then(() => {
                    this.$emit('updated', this.employee)
                }))
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        expandAll() {
            this.onboardingAppsExpanded = true
            this.managersExpanded = true
            this.contactsExpanded = true
            this.checksExpanded = true
            this.taxDocsExpanded = true
        },
        collapseAll() {
            this.onboardingAppsExpanded = false
            this.managersExpanded = false
            this.contactsExpanded = false
            this.checksExpanded = false
            this.taxDocsExpanded = false
        },
        onDocumentAdded(doc) {
            this.employeeDocuments.push(doc)
            this.$nextTick(() => this.getData().then(() => {
                this.$emit('updated', this.employee)
            }))
        },
        onEmployeeDocumentAdded(doc) {
            this.$nextTick(() => this.getData().then(() => {
                this.$emit('updated', this.employee)
            }))
        },
        onDocTaskCompleted(docTask) {
            this.$nextTick(() => this.getData().then(() => {
                this.$emit('updated', this.employee)
            }))
        },
        onUpdate(emp) {
            if (this.approvalMode && (this.employee.status != emp.status) && (emp.status == 'hired')) {
                this.approvalMode = false
            }

            if (this.editEmailModalOpened) {
                this.editEmailModalOpened = false
            }

            if (emp) {
                Object.assign(this.employee, emp)
            }

            this.$nextTick(() => this.getData())
            this.$emit('updated', this.employee)
        },
        onCovidVaccinationStatusUpdated() {
            this.currentCovidVaccinationStatus = null
            this.getData().then(() => {
                this.$emit('updated', this.employee)
            })
        },
        onEmergencyContactsUpdate(resp) {
            this.emergencyContacts = resp.emergency_contacts
            this.$emit('updated', this.employee)
        },
        onEVerifyCaseUpdate(resp) {
            this.getData().then(() => {
                this.$emit('updated', this.employee)
            })
        },
        onWarningsUpdate() {
            this.getData().then(() => {
                this.$emit('updated', this.employee)
            })
        },
        updateEmployee(emp) {
            // Similar to onUpdate() but does not emit an event. This is used by the parent component to reload this.employee if an out of band update comes in

            if (this.approvalMode && (this.employee.status != emp.status) && (emp.status == 'hired')) {
                this.approvalMode = false
            }

            this.$nextTick(() => this.getData())
        },
        openDocumentUploadModal() {
            this.documentUploadModalOpen = true
        },
        openAddDocumentModal() {
            this.addDocumentModalOpen = true
        },
        openCompleteDocTaskModal(docTask) {
            this.docTaskToComplete = docTask
        },
        openCovidVaccinationStatusReportingModal() {
            this.covidVaccinationStatusReportingModalOpen = true
        },

        POSActionUpdateGeneral() {
            if (! window.confirm("This will update the employee's general information in Altametrics. Are you sure?")) {
                return;
            }

            const url = `/clients/${this.client.id}/employees/${this.employee.id}/pos-actions/update-general`;
            this.$store.dispatch('START_LOADING')
            this.$api.post(url, {}).then(() => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showInfo("Sent to POS", "Sent to Altametrics. This may take several minutes.")
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },

        POSActionHire() {
            this.POSHireModalOpen = true
        },

        POSActionHireNotAllowed() {
            window.alert("Employee is terminated.")
        },

        POSActionUpdateJobPay() {
            this.POSJobPayModalOpen = true
        },

        POSActionTerminate() {
            this.POSTerminateModalOpen = true
        },

        // Below function added for testing
        openEmergencyContactModal() {
            this.emergencyContactModalOpen = true
            this.$nextTick(() => {
                this.$refs.emergencyContactModal.open()
            })
        },
        openEditModal() {
            this.editModalOpened = true
        },
        openEditEmailModal() {
            this.editEmailModalOpened = true
        },
        getAOSData() {
            const url = `/admin/onboarding-applications/${this.employee.id}/aos-data`
            this.$store.dispatch('START_LOADING')
            this.$api.get(url).then(resp => {
                this.aosData = resp
                this.$store.dispatch('STOP_LOADING')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        adminClearSchoolDistrict() {
            if (! window.confirm("This will clear the school district in this onboarding. Are you sure?")) {
                return;
            }

            const url = `/admin/onboarding-applications/${this.employee.id}/clear-school-district`
            this.$store.dispatch('START_LOADING')
            this.$api.post(url, {}).then(() => {
                this.$bus.showInfo("Cleared school district from onboarding.")
                this.$store.dispatch('STOP_LOADING')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        adminClearI9() {
            if (! window.confirm("This will clear the employer side of the I-9 in this onboarding. Are you sure?")) {
                return;
            }

            const url = `/admin/onboarding-applications/${this.employee.id}/clear-i9`
            this.$store.dispatch('START_LOADING')
            this.$api.post(url, {}).then(() => {
                this.$bus.showInfo("Cleared employer I-9.")
                this.$store.dispatch('STOP_LOADING')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        adminClearEVerify() {
            if (! window.confirm("This will delete any E-Verify cases in GetMyPayStub. Are you sure?\n\nNote that you still need to close any open cases in E-Verify itself.")) {
                return;
            }

            const url = `/admin/onboarding-applications/${this.employee.id}/clear-everify`
            this.$store.dispatch('START_LOADING')
            this.$api.post(url, {}).then(() => {
                this.$bus.showInfo("Deleted E-Verify cases in GetMyPayStub - close any open cases in E-Verify.")
                this.$store.dispatch('STOP_LOADING')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        adminOnboarding() {
            if (! window.confirm("This will reset the employee's onboarding. Are you sure?")) {
                return;
            }

            const url = `/admin/onboarding-applications/${this.employee.id}/reset`
            this.$store.dispatch('START_LOADING')
            this.$api.post(url, {}).then(() => {
                return this.$router.push({name: 'admin-onboarding', params: {appId: this.employee.id}})
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        adminRepeatOnboardingStep(step) {
            this.adminOnboardingRepeatStep = step
            this.$refs.adminOnboardingModal.open()
        },
        showWOTCPDF(app) {
            const doc = {
                name: 'WOTC',
                url: `${this.$api.API_BASE}/clients/${this.client.id}/onboarding-applications/${app.id}/wotc-pdf`
            }

            this.setCurrentPDF(doc)
        },
        openEVerifyCaseModal(action) {
            switch(action) {
                case 'TNC':
                    this.EVerifyTNCModalOpen = true
                    break;
                case 'FNC':
                    this.EVerifyFNCModalOpen = true
                    break;
                case 'REFERRED':
                    // TNC modal
                    this.EVerifyTNCModalOpen = true
                    break;
                case 'PHOTO_MATCH':
                    this.EVerifyPhotoMatchModalOpen = true
                    break;
                case 'SCAN_AND_UPLOAD':
                    this.EVerifyScanAndUploadModalOpen = true
                    break;
                case 'CLOSE_CASE':
                    this.EVerifyCloseCaseModalOpen = true
                    break;
                // explicitly open a new case
                case 'OPEN_NEW_CASE':
                    this.EVerifyNewCaseModalOpen = true
                    break;
                default:
                    this.EVerifyModalOpen = true;
            }
        },
        openOtherEVerifyCaseModal(other_case) {
            this.currentEVerifyCase = other_case
            this.EVerifyCurrentCaseModalOpen = true
        },
        parseYAML(yaml) {
            return YAML.safeLoad(yaml)
        },
        makeHRCustomFormPhotoUrl(customForm, photoId) {
            if (customForm.employee_id) {
                return `${this.$api.API_BASE}/clients/${this.client.id}/employees/${customForm.employee_id}/hr-custom-forms/${customForm.id}/photos/${photoId}`
            } else if (customForm.onboarding_application_id) {
                return `${this.$api.API_BASE}/clients/${this.client.id}/onboarding-applications/${customForm.onboarding_application_id}/hr-custom-forms/${customForm.id}/photos/${photoId}`
            }
            return ''
        },
        scrollTop(offset) {
            this.$refs.sidebar.scrollTop(offset)
        }
    },
    filters: {
        yamlTitle(yaml) {
            return YAML.safeLoad(yaml).title
        },
    },
}
</script>

<style scoped lang="scss">
    @import "@/assets/scss/globals";
    .active-item {
        background-color: #DCEEF6;
    }
    // Tiny thumbnail image of document
    .docphoto-thumb {
        background-color: gray("150");
        // background-image set inline above
        background-position: center;
        background-size: cover;
        border: solid 1px $border-color;
        border-radius: 3px;
        height: 24px;
        width: 24px;
        line-height: 23px; // 24px results in slightly off-center text
        text-align: center;
    }
</style>
