<template>
    <page>
        <div class="nav-tabs-container pt-1" id="tabsPageNav">
            <div class="nav-tabs-scroll-helper">
                <ul class="nav nav-tabs">

                    <li class="nav-item">
                        <a class="nav-link" :class="{'active': mode == 'earnings'}" href id="tabLink1" role="tab" aria-controls="tabContent1" :aria-selected="mode == 'earnings'" @click.prevent="setMode('earnings')">
                            Earnings
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" :class="{'active': mode == 'memos'}" href id="tabLink2" role="tab" aria-controls="tabContent2" :aria-selected="mode == 'memos'" @click.prevent="setMode('memos')">
                            Memos
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" :class="{'active': mode == 'deductions'}" href id="tabLink3" role="tab" aria-controls="tabContent3" :aria-selected="mode == 'deductions'" @click.prevent="setMode('deductions')">
                            Deductions
                        </a>
                    </li>

                </ul>
            </div>
        </div>

        <div class="flex-body flex-container tab-content">
            <div class="flex-body flex-container tab-pane show active" v-if="mode == 'earnings'" id="tabContent1" role="tabpanel" aria-labelledby="tabLink1">
                <pay-item-type-table :client="client" :item-type-slug="'earnings'" :item-type-description="'Earnings'" />
            </div>

            <div class="flex-body flex-container tab-pane show active" v-if="mode == 'memos'" id="tabContent2" role="tabpanel" aria-labelledby="tabLink2">
                <pay-item-type-table :client="client" :item-type-slug="'memos'" :item-type-description="'Memos'" />
            </div>

            <div class="flex-body flex-container tab-pane show active" v-if="mode == 'deductions'" id="tabContent3" role="tabpanel" aria-labelledby="tabLink3">
                <pay-item-type-table :client="client" :item-type-slug="'deductions'" :item-type-description="'Deductions'" />
            </div>
        </div>

    </page>
</template>

<script>
import Page from '@/components/Page'
import PayItemTypeTable from './components/PayItemTypeTable'

export default {
    components: {Page, PayItemTypeTable, },
    props: {
        'client': {required: true},
    },
    data() {
        return {
            mode: 'earnings',
        }
    },
    methods: {
        setMode(mode) {
            this.mode = mode
            this.$bus.$emit('resize')
        },
    }
}
</script>
