<template>
    <div>
    <nav-secondary v-if="hrDocsSuiteReleased || hrDocsSuiteEnabled" :navOptions="navOptions" :useOptionTextAsTitle="true"></nav-secondary>
    <div class="flex-page-content flex-container view">

    <template v-if="hrSuiteUpgradeAvailable">
        <portal to="upgrade-link">
            <router-link target="_blank" :to="{name: 'client-features-hr-compliance', params: {client: this.client.id}}" @click="trackUpgradeClick">
                <i class="fas fa-fw fa-star mr-0"></i>
                Upgrade
            </router-link>
        </portal>
    </template>

    <table-page
        :client="client"
        :warnings="warnings"
        :changelogs="changelogs"
        @changelog_date_filter_change="onChangelogDateRangeChange"
        @warnings_date_filter_change="onWarningsDateRangeChange"
        @warnings_updated="getWarnings"
        @updated="refresh"
    >
        <fast-table
            :recordLabel="recordLabel"
            :sections="sections"
            :headers="headers"
            :data="tableData"
            :activeRowId="activeRowId"
            :managers="client.company_managers"
            @click="setCurrentDocument($event)"
            :use-date-filters="true"
            @date_filter_change="onDateFilter"
        />

        <employee-sidebar
            :client="client"
            :employee-id="currentDocument.employee.id"
            v-if="currentDocument"
            @close="currentDocument = null"
            tab="hr-docs" section="completed-docs" :item-id="currentDocument.id"
            :expanded-items-subsection="currentDocument.most_recent ? 'active' : 'inactive'"
            @updated="refresh"
            @employee-document-removed="onDocRemoved"
            :key="currentDocument.id"/>
    </table-page>
    </div>
    <HRSuiteTour ref="hrSuiteTour" :client="client"/>
    </div>
</template>

<script>
import FastTable from '@/components/FastTable'
import EmployeeSidebar from '@/components/EmployeeSidebar'
import TablePageMixin from '@/mixins/TablePageMixin'
import NavSecondary from '@/components/NavSecondary'
import HRSuiteTour from '@/components/Tours/HRSuiteTour.vue'

export default {
    props: ['client'],
    components: {FastTable, EmployeeSidebar, NavSecondary, HRSuiteTour},
    mixins: [TablePageMixin, ],
    computed: {
        active() {
            return this.documents.filter(d => d.employee.employment_status == 'Active')
        },
        inactive() {
            return this.documents.filter(d => d.employee.employment_status != 'Active')
        },
        activeRowId() {
            return this.currentDocument ? this.currentDocument.id : null
        },
        tableData() {
            const tableData = []
            const subsets = [
                this.active,
                this.inactive,
            ]

            subsets.forEach(docs => {
                const sectionData = []

                docs.forEach((d, idx) => {
                    const row = {
                        id: d.id,
                        object: d,
                        cells: [
                            d.employee.full_name_sortable,
                            d.employee.employee_number,
                            d.employee.warning_count,
                            d.employee.company.name,
                            d.name,
                            d.most_recent ? 'Active' : 'Inactive',
                            d.source_pretty,
                            d.completed_on || d.created_on,
                            // d.expires_on,
                            d.signature_data.manager_signature ? d.signature_data.manager_signature.full_name : 'N/A',
                            d.employee.email,
                            d.employee.ssn_last_4 ? `XXX-XX-${d.employee.ssn_last_4}` : '---',
                            d.employee.last_pay_date,
                        ],
                    }

                    sectionData.push(row)
                })

                tableData.push(sectionData)
            })

            return tableData
        },
        hrDocsSuiteReleased() {
            return this.$store.state.system_flags?.hr_docs_suite_released
        },
        hrDocsSuiteEnabled() {
            return this.client.has_company_docs_module || this.client.has_uniform_module || this.client.has_custom_forms_module || this.client.has_ad_hoc_docs_module
        },
        hrSuiteUpgradeAvailable() {
            // If client or at least one company does not have all the document modules, then an upgrade is available
            if (!this.hrDocsSuiteReleased) {
                return false
            }
            if (!this.client.has_company_docs_module || !this.client.has_uniform_module || !this.client.has_custom_forms_module) {
                return true
            }
            if (this.client.has_company_docs_module) {
                if (this.client.companies.some(c => c.has_company_docs_module == false)) {
                    return true
                }
            }
            if (this.client.has_uniform_module) {
                if (this.client.companies.some(c => c.has_uniform_module == false)) {
                    return true
                }
            }
            if (this.client.has_custom_forms_module) {
                if (this.client.companies.some(c => c.has_custom_forms_module == false)) {
                    return true
                }
            }
            return false
        },
    },
    data() {
        return {
            documents: [],
            dataLoaded: false,
            refreshing: false,
            currentDocument: null,
            dateRanges: {},

            recordLabel: "HR documents for employees",
            headers: [
                {label: 'Employee', classes: '', isSearchable: true, defaultSort: true},
                {label: 'Employee Num', classes: 'cw-7h cell-right', isSearchable: true, sortDataType: 'numeric'},
                {label: 'Warnings', classes: 'cw-warning', isSearchable: false, type: 'warning', isFilterable: true},
                {label: 'Company', classes: 'cell-company', isSearchable: true, isFilterable: true},
                {label: 'Document', classes: 'cell-medium', isSearchable: true, isFilterable: true},
                {label: 'Doc Status', classes: 'cw-10', isFilterable: true},
                {label: 'Source', classes: 'cw-6h', isSearchable: false, isFilterable: true},
                {label: 'Completed On', classes: 'cw-date', isSearchable: false, type: 'date', defaultSort: true, defaultSortIsDesc: true},
                // {label: 'Expires In', classes: 'cw-date', isSearchable: false, type: 'date'},
                {label: 'Approved By', classes: 'cw-10', isSearchable: false},
                {label: 'Email', classes: 'cell-email', isSearchable: true},
                {label: 'SSN', classes: 'cw-ssn', isSearchable: true},
                {label: 'Last Paid', classes: 'cw-date', isSearchable: false, type: 'date'},
            ],
            sections: [
                {title: 'Active Employees', defaultIsClosed: false, id: 'active', hasDateFilter: false},
                {title: 'Inactive Employees', defaultIsClosed: true, id: 'inactive'},
            ],
            navOptions: [
                {'text': 'Completed Documents', 'value': 'hr-documents', },
                {'text': 'Incomplete Documents', 'value': 'hr-documents-incomplete',},
                {'text': 'Canceled Documents', 'value': 'hr-documents-canceled', },
            ]
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'HR Documents')
        this.getData()
        this.getWarnings()
        this.getChangelogs()
        if (this.$store.state.activeTour == 'hr-suite') {
            this.$refs.hrSuiteTour.start('hr-documents-completed')
        }
    },
    methods: {
        setCurrentDocument(evt) {
            this.currentDocument = evt
        },
        filterRelevantWarnings(w) {
            if (w.employee) {
                if (['i9-completed-late', 'i9-incomplete'].includes(w.warning_type)) {
                    return true
                }
            }
            return false
        },
        getData(silent) {
            if (!silent) {
                this.dataLoaded = false
                this.$store.dispatch('START_LOADING')
            }

            this.refreshing = true

            let url = `/clients/${this.client.id}/employee-hr-documents`
            const params = []
            this.sections.forEach(s => {
                const range = this.dateRanges[s.id]
                if (range) {
                    params.push(`date-filter.${s.id}.start=${encodeURIComponent(range.start)}`)
                    params.push(`date-filter.${s.id}.end=${encodeURIComponent(range.end)}`)
                }
            })

            url += '?' + params.join('&')

            this.$api.get(url).then(resp => {
                resp.forEach(d => {
                    d.company_security_profile_ids = d.employee.company_security_profile_ids
                })
                this.documents = resp
                this.dataLoaded = true
                this.refreshing = false
                if (!silent) {
                    this.$store.dispatch('STOP_LOADING')
                }
            }).catch((errors) => {
                if (errors.__status__ == 403) {
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.$emit('no-access')
                    return
                }

                if (!silent) {
                    this.$store.dispatch('STOP_LOADING')
                }
                this.refreshing = false
                this.$bus.showError(errors.__all__)
            })
        },
        refresh() {
            if (this.refreshing) {return}
            this.getData(true)
            this.getWarnings()
            this.getChangelogs()
        },
        filterRelevantChangelog(c) {
            return c.employee_id && c.tracked_object_slug == 'hr-document'
        },
        onDateFilter(dateRanges) {
            this.dateRanges = dateRanges
            this.getData()
        },
        onDocRemoved(docId) {
            this.documents = this.documents.filter(d => d.id != docId)
        },
        trackUpgradeClick() {
            this.$bus.trackEvent('HR Compliance Suite Upgrade Click', {
                'client_id': this.client.id,
                'client_business_type_rollup_primary': this.client.business_type_rollup_primary,
                'client_business_type_rollup_secondary': this.client.business_type_rollup_secondary,
                'client_business_type_rollup_brand': this.client.business_type_rollup_brand,
            })
        },
    },
}

</script>
