<template>
    <div v-if="client" id="hr-compliance-video-page">
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">

                <div class="container-fluid">
                    <div class="container-centered-l py-3">
                        <div class="d-flex justify-content-center mb-2">
                            <span class="feature-spotlight-pill">Feature Spotlight</span>
                        </div>
                        <div class="text-center mb-3">
                            <h1>HR Documents Compliance Suite</h1>
                            <hr/>
                            <p class="mt-3 mb-1">Get the <strong>right documents</strong> to the <strong>right employees</strong>.</p>
                            <p>Get signatures and responses to your documents <strong>quickly and easily</strong>.</p>
                        </div>

                        <video width="100%" ref="complianceVideo" controls @play="onVideoPlay" @ended="onVideoWatched" @timeupdate="updateElapsedTime">
                            <source src="https://getmypaystub-public.s3.us-east-1.amazonaws.com/promo/promo-hr-compliance-2.mp4" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>

                        <button-row stacked>
                            <router-link :to="{name: 'client-features-hr-compliance'}" class="btn btn-outline-primary" @click="trackLearnMore">Learn More</router-link>
                            <router-link v-if="showContinueButton" :to="{name: 'employer-home'}" class="btn btn-primary">Continue to Dashboard</router-link>
                            <button v-else-if="showSkipButton" @click="onVideoSkip" class="btn btn-outline-primary">Skip Video</button>
                            <button v-else @click="onPlayToContinueClick" class="btn btn-outline-secondary">{{ playToContinueButtonText }}</button>
                        </button-row>
                        <div v-if="showSkipButton" class="d-flex text-center justify-content-end">
                            <div class="col-12 col-rs-6 text-center">
                                <em>{{ skipsRemaining }} skips remaining</em>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonRow from '@/components/ButtonRow'
import moment from 'moment'

export default {
    props: ['client', ],
    components: {ButtonRow},
    data() {
        return {
            videoStarted: false,
            elapsedTime: 0,
            isVideoJustWatched: false,
        }
    },
    created() {
        this.$store.dispatch('SET_PAGE_TITLE', '')
    },
    computed: {
        // Dismissal logic: video page pops up once every 30 days for all client managers if they have access to at least 1 company that does not have this feature enabled.
        // The first time, you have to watch the whole video before the button appears to continue to the dashboard.
        // However, you may skip this 3 times before you're forced. A skip lasts until next login.
        // Once you've watched the video once, then on subsequent popups you can continue after 5 seconds.
        dismissalsRecord() {
            let rec = {}
            if (this.$store.state.user) {
                if (this.$store.state.user.dismissals_record['clients']) {
                    if (this.client.id in this.$store.state.user.dismissals_record['clients']) {
                        rec = this.$store.state.user.dismissals_record['clients'][this.client.id]
                    }
                }
            }
            return rec
        },
        isVideoWatchedEver() {
            return Boolean(this.dismissalsRecord['hr-compliance-video'])
        },
        isVideoWatchedRecently() {
            // did the user watch this video for this client in the last 30 days?
            let lastWatched = this.dismissalsRecord['hr-compliance-video']
            if (lastWatched) {
                let inputformat = 'YYYY-MM-DDTHH:mm:ss.SSSZ'
                let lastWatchedGoodUntil = moment(lastWatched, inputformat).add(30, 'days');
                if (lastWatchedGoodUntil.isSameOrAfter()) {
                    return true
                }
            }
            return false
        },
        skipsRemaining() {
            let skipsRecord = this.dismissalsRecord['skips']
            if (skipsRecord) {
                let numSkips = skipsRecord['hr-compliance-video']?.num_skips
                if (numSkips) {
                    return 3 - numSkips
                }
            }
            return 3
        },
        timeUntilContinue() {
            return Math.max((5 - Math.floor(this.elapsedTime)), 0)
        },
        showContinueButton() {
            return this.isVideoJustWatched || this.isVideoWatchedRecently || (this.isVideoWatchedEver && this.timeUntilContinue <= 0)
        },
        showSkipButton() {
            return Boolean(!this.isVideoJustWatched && !this.isVideoWatchedEver && (this.skipsRemaining > 0))
        },
        playToContinueButtonText() {
            if (this.isVideoWatchedEver && this.videoStarted) {
                return `Continue in ${this.timeUntilContinue}`
            } else {
                return 'Play Video to Continue'
            }
        }
    },
    methods: {
        onVideoPlay() {
            this.videoStarted = true
        },
        onPlayToContinueClick() {
            this.$refs.complianceVideo.play()
        },
        updateElapsedTime() {
            this.elapsedTime = this.$refs.complianceVideo.currentTime;
        },
        onVideoWatched() {
            this.isVideoJustWatched = true
            this.$api.post(`/me/dismiss`, {'client_id': this.client.id, 'slug': 'hr-compliance-video'}).then(resp => {
                this.$store.dispatch('GET_USER')
            }).catch(errors => {
                this.$bus.showError(errors.__all__)
            })
        },
        onVideoSkip() {
            this.$api.post(`/me/dismiss`, {'client_id': this.client.id, 'slug': 'hr-compliance-video', 'action': 'skip', 'session_key': this.$store.state.session.session_key }).then(resp => {
                this.$router.push({name: 'employer-home'})
            }).catch(errors => {
                this.$bus.showError(errors.__all__)
            })
        },
        trackLearnMore() {
            this.$bus.trackEvent('HR Compliance Suite Video Learn More', {
                'client_id': this.client.id,
                'client_business_type_rollup_primary': this.client.business_type_rollup_primary,
                'client_business_type_rollup_secondary': this.client.business_type_rollup_secondary,
                'client_business_type_rollup_brand': this.client.business_type_rollup_brand,
            })
        },
    },
}

</script>

<style lang="scss">
    @import "@/assets/scss/globals";

    #hr-compliance-video-page {
        .feature-spotlight-pill {
            background-color: $teal2;
            color: #fff;
            border-radius: 6px;
            padding: 3px 8px;
        }
    }
</style>