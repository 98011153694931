<template>
<div v-if="documentDataLoaded">
    <form class="mui-form">
        <h3 class="py-2 mb-0">{{ mode == 'duplicate' ? 'Copy' : 'Add' }} Company Document{{ addVersionMode ? ' Version' : '' }}: <span class="text-thin text-nowrap">{{ stepAsText }}</span></h3>
        <div v-if="step != 'doc-companies'" class="mb-2">
            <div class="p-1 unpad-x bg-150 rounded">
                <div><span class="text-muted">Company: </span>{{ companyAsText }}</div>
                <div v-if="formData.name && !(addDocMode && step == 'doc-name-upload')"><span class="text-muted">Document Name: </span>{{ formData.name }}</div>
                <div v-if="addVersionMode"><span class="text-muted">Version: </span>{{ formData.version }}</div>
            </div>
        </div>
        <div v-if="mode == 'duplicate' && step == 'doc-companies'" class="mb-2">
            <div class="p-1 unpad-x bg-150 rounded">
                <div><span class="text-muted">Company: </span>{{ duplicateFromCompanyAsText }}</div>
                <div><span class="text-muted">Document Name: </span>{{ document.name }}</div>
            </div>
        </div>

        <div v-if="step == 'doc-companies'">
            <p v-if="mode == 'duplicate'">Select the company or companies to copy this document to:</p>
            <p v-else>Select the company or companies this document will be available for.</p>
            <company-selector :client="client" :companies="companiesWithFeature" v-model="formData.companies"/>
        </div>

        <div v-if="step == 'doc-name-upload'">
            <form-input v-if="addDocMode" v-model="formData.name" :errors="errors.name" label="Document Name" type="text" />

            <div class="form-row">
                <div class="col">
                    <form-input @upload="onFileUpload($event)" :errors="errors.pdf_file" label="Upload your document:" helper-text="File extension must be .pdf" type="file" accept=".pdf"/>
                </div>
            </div>
            <div class="pdf-inline-wrapper">
                <pdf-inline
                    v-if="pdfPreviewUrl || documentDraftPdfUrl || duplicatedPdfUrl"
                    :url="pdfPreviewUrl || documentDraftPdfUrl || duplicatedPdfUrl"
                    :hide-controls="true"
                />
            </div>
        </div>

        <div v-if="step == 'doc-settings'">
            <info-widget>
                <template v-slot:title>Signature Settings</template>
                <div class="card-body pt-2 pb-1">
                    <p class="text-semibold">Is a manager signature required to approve this document?</p>
                    <div class="form-check">
                        <label>
                            <input name="manager_signature_required" type="radio" @click="formData.manager_signature_required = true" :checked="formData.manager_signature_required == true" class="form-check-input">
                            <strong>Yes</strong>, manager signature is required.
                        </label>
                        <p class="mt-0 small">
                            A manager must review and sign this document after submission to approve.
                        </p>
                    </div>
                    <div class="form-check">
                        <label>
                            <input name="manager_signature_required" type="radio" @click="formData.manager_signature_required = false" :checked="formData.manager_signature_required == false" class="form-check-input">
                            <strong>No</strong>, manager signature is not required.
                        </label>
                    </div>
                </div>
            </info-widget>
        </div>

        <div v-if="step == 'doc-assignment'">
            <p class="text-semibold">How do you want to select the employees who are required to have this document completed?</p>
            <div class="form-check">
                <label>
                    <input name="assign_to" type="radio" @click="formData.assign_to = 'positions'" :checked="formData.assign_to == 'positions'" class="form-check-input">
                    <strong>By Position</strong>
                </label>
                <p class="mt-0">
                    You will be asked to select positions. All employees in the positions you select will be required to have this document completed.
                </p>
            </div>
            <div class="form-check">
                <label>
                    <input name="assign_to" type="radio" @click="formData.assign_to = 'individuals'; formData.positions = []; formData.include_in_onboarding = null;" :checked="formData.assign_to == 'individuals'" class="form-check-input">
                    <strong>By Individual Employee</strong>
                </label>
                <p class="mt-0">
                    After you create this document, you may manually require individual employees to have this document completed via their employee record.
                </p>
            </div>
            <alert-box type="info" class="mt-2">
                You can always change the required positions and individual employees after creating this document.
            </alert-box>
        </div>

        <div v-if="step == 'doc-positions'">
            <p>Select the positions that are required to have this document completed.</p>

            <position-selector :all-positions="allCompanyPositions" :companies="formData.companies" v-model="formData.positions" />
        </div>

        <div v-if="step == 'version-settings'">
            <p class="text-semibold">Is this new version required for active employees who already have a previous version of this document completed?</p>
            <div class="form-check pt-1 pb-1">
                <label>
                    <input name="is_major_version" type="radio" @click="formData.is_major_version = true" :checked="formData.is_major_version == true" class="form-check-input">
                    <p class="mb-1"><strong>Yes, this is a required version</strong>.</p>
                    <span v-if="formData.collection_method == 'employee'">All active employees who are required to have {{ formData.name }} completed must now complete this new version, <em>even if they have already completed a previous version of this document</em>.</span>
                    <span v-else>All active employees who are required to have {{ formData.name }} completed must now have this new version completed, <em>even if they already have a previous version of this document completed</em>.</span>
                </label>
                <p class="small mt-0">
                    <em>(example: you are making a significant revision to this document so employees must complete it again)</em>
                </p>
            </div>
            <div class="form-check">
                <label>
                    <input name="is_major_version" type="radio" @click="formData.is_major_version = false" :checked="formData.is_major_version == false" class="form-check-input">
                    <span v-if="document.major_version_number > 0"><strong>No</strong>, active employees who already have the previous required version of this document completed (version {{ document.major_version_number }}{{ (document.major_version_number < document.version_number) ? ' or higher' : '' }}) are <strong>not required</strong> to also have this new version completed.</span>
                    <span v-else-if="document.major_version_number == 0"><strong>No</strong>, active employees are <strong>not required</strong> to have this new version completed.</span>
                    <br/>
                </label>
                <p class="small mt-0">
                    <em>(example: you are fixing a typo or making a change that does not require employees to complete this document again)</em>
                </p>
            </div>
        </div>

        <div v-if="step == 'doc-collection-settings'">
            <p>
                <span class="text-semibold">How should GetMyPaystub collect this document for required employees?</span><br/>
                <small v-if="formData.positions.length || formData.include_in_onboarding !== null">You may collect the document differently for new employees and for current active employees.</small>
            </p>
            <info-widget v-if="formData.positions.length || formData.include_in_onboarding !== null">
                <template v-slot:title>New Employees</template>
                <div class="card-body pt-2 pb-1">
                    <p class="text-semibold">How do you want to collect this document for new employees?</p>
                    <div class="form-check">
                        <label>
                            <input name="include_in_onboarding" type="radio" @click="formData.include_in_onboarding = true" :checked="formData.include_in_onboarding == true" class="form-check-input">
                            <strong>Employees</strong> will be prompted to complete this document online when they onboard or first log in.
                        </label>
                        <p class="mt-0 small">
                            New employees will be blocked from accessing paystubs, W-2s, etc. until they have completed this document.
                        </p>
                    </div>
                    <div class="form-check">
                        <label>
                            <input name="include_in_onboarding" type="radio" @click="formData.include_in_onboarding = false" :checked="formData.include_in_onboarding == false" class="form-check-input">
                            <strong>Managers</strong> will be given a task to upload this document for each new employee.
                        </label>
                        <p class="mt-0 small">
                            New employees will be allowed to access paystubs, W-2s, etc. before this document is completed.
                        </p>
                    </div>
                </div>
            </info-widget>
            <info-widget>
                <template v-slot:title>{{ activeEmployeeLabel }} Employees</template>
                <div class="card-body pt-2 pb-1">
                    <p class="text-semibold">How do you want to collect this document for {{ activeEmployeeLabel.toLowerCase() }} employees?</p>
                    <div class="form-check pb-1">
                        <label>
                            <input name="collection_method" type="radio" @click="formData.collection_method = 'employee'" :checked="formData.collection_method == 'employee'" class="form-check-input">
                            <strong>Employees</strong> will receive an email and will be prompted to complete this document online.
                        </label>
                        <div class="pl-2 pr-1 pt-2 pb-1 mb-1 bg-100 rounded" v-show="formData.collection_method == 'employee'">
                            <div class="form-check">
                                <label>
                                    <input name="block_ee_until_completion" type="radio" @click="formData.block_ee_until_completion = true" :checked="formData.block_ee_until_completion == true" class="form-check-input">
                                    Block access to paystubs, W-2s, etc. until employee has completed this document
                                </label>
                            </div>
                            <div class="form-check">
                                <label>
                                    <input name="block_ee_until_completion" type="radio" @click="formData.block_ee_until_completion = false" :checked="formData.block_ee_until_completion == false" class="form-check-input">
                                    Allow employee to access paystubs, W-2s, etc. before completing this document
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-check">
                        <label>
                            <input name="collection_method" type="radio" @click="formData.collection_method = 'manager'; formData.block_ee_until_completion = false" :checked="formData.collection_method == 'manager'" class="form-check-input">
                            <strong>Managers</strong> will be given a task to upload this document for each required employee.
                        </label>
                        <p class="mt-0 small">
                            Employees will be allowed to access paystubs, W-2s, etc. before this document is completed.
                        </p>
                    </div>
                    <div class="form-check pb-1">
                        <label>
                            <input name="collection_method" type="radio" @click="formData.collection_method = 'do-not-collect'" :checked="formData.collection_method == 'do-not-collect'" class="form-check-input">
                            <strong>Do not collect</strong> this document for active employees.
                        </label>
                    </div>
                    <alert-box type="info">
                        In any case, managers can always upload or complete a document on an employee’s behalf.
                    </alert-box>
                </div>
            </info-widget>
        </div>

        <div v-if="step == 'doc-termination-settings'">
            <p>When an employee who has <strong>not completed</strong> this document is terminated, what should happen?</p>
            <div class="form-check">
                <label>
                    <input name="remove_requirement_on_termination" type="radio" @click="formData.remove_requirement_on_termination = false" :checked="formData.remove_requirement_on_termination == false" class="form-check-input">
                    Document is <strong>still required</strong> for this employee after they are terminated.
                </label>
            </div>
            <div class="form-check">
                <label>
                    <input name="remove_requirement_on_termination" type="radio" @click="formData.remove_requirement_on_termination = true" :checked="formData.remove_requirement_on_termination == true" class="form-check-input">
                    Document is <strong>canceled</strong> and no longer required for this employee when they are terminated.
                </label>
            </div>
        </div>

        <div v-if="step == 'doc-summary'">
            <p v-if="addDocMode">Review the settings below before saving this document.</p>
            <p v-if="addVersionMode">
                <span class="text-semibold">Review the settings below before saving and making this the active version of {{ document.name }}</span>.
                Once you make this the active version, future responses will use these updated settings.
            </p>

            <info-widget>
                <template v-slot:title>Signature Settings</template>
                <template v-slot:headerbutton>
                    <button class="btn btn-outline-primary" type="button" @click.prevent="goToStepFromSummary('doc-settings')">
                        <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
                        Edit
                    </button>
                </template>
                <div class="card-body">
                    <template v-if="formData.manager_signature_required">
                        <p class="mb-1"><strong>Manager signature is required to approve this document.</strong></p>
                        <p class="mb-0">A manager must review and sign this document after submission to approve.</p>
                    </template>
                    <template v-else>
                        <strong>Manager signature is not required.</strong>
                    </template>
                </div>
            </info-widget>

            <info-widget>
                <template v-slot:title>Required Employees</template>
                <template v-slot:headerbutton v-if="addDocMode">
                    <button class="btn btn-outline-primary" type="button" @click.prevent="goToStepFromSummary('doc-assignment')">
                        <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
                        Edit
                    </button>
                </template>
                <div class="card-body">
                    <template v-if="formData.positions.length">
                        <p class="mb-hf">This document is required for employees in <strong>{{ selectedPositions.length }} position{{ selectedPositions.length > 1 ? 's' : '' }}.</strong></p>
                        <ul class="mb-2">
                            <li v-for="p in selectedPositions" :key="p.terminal_value">
                                {{ p.terminal_value }}
                            </li>
                        </ul>
                    </template>
                    <template v-if="document && document.individual_document_requirements && document.individual_document_requirements.length">
                        <p class="mb-hf">This document is required for <strong>{{ this.document.individual_document_requirements.length }} individual employee{{ formData.positions.length > 1 ? 's' : '' }}.</strong></p>
                        <ul>
                            <li v-for="r in this.document.individual_document_requirements" :key="r.id">
                                {{ r.employee.name }}
                            </li>
                        </ul>
                    </template>
                    <template v-if="!formData.positions.length && !(document && document.individual_document_requirements)">
                        <strong>This document is not required for any employees.</strong><br/>
                        You can add required positions and individual employees after creating this document.
                    </template>
                </div>
            </info-widget>

            <info-widget v-if="addVersionMode">
                <template v-slot:title>Version Settings</template>
                <template v-slot:headerbutton>
                    <button class="btn btn-outline-primary" type="button" @click.prevent="goToStepFromSummary('version-settings')">
                        <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
                        Edit
                    </button>
                </template>
                <div class="card-body">
                    <template v-if="formData.is_major_version">
                        <p class="mb-1"><strong>This is a required version.</strong></p>
                        <p class="mb-0" v-if="formData.collection_method == 'employee'">
                            All required active employees must now complete this new version, <em>even if they have already completed a previous version of this document</em>.
                        </p>
                        <p class="mb-0" v-if="formData.collection_method == 'manager'">
                            All required active employees must now have this new version completed, <em>even if they already have a previous version of this document completed</em>.
                        </p>
                    </template>
                    <template v-else>
                        Active employees who already have the previous required version of this document completed (version {{ document.major_version_number }}{{ (document.major_version_number < document.version_number) ? ' or higher' : '' }}) are <strong>not required</strong> to also have this new version completed.
                    </template>
                </div>
            </info-widget>

            <info-widget v-if="addDocMode || formData.is_major_version">
                <template v-slot:title>Collection Settings</template>
                <template v-slot:headerbutton>
                    <button class="btn btn-outline-primary" type="button" @click.prevent="goToStepFromSummary('doc-collection-settings')">
                        <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
                        Edit
                    </button>
                </template>
                <div class="card-body">
                    <p>GetMyPayStub will collect this document from required employees using these methods:</p>
                    <template v-if="formData.include_in_onboarding !== null">
                        <h5>New Employees</h5>
                        <p v-if="formData.include_in_onboarding">
                            <strong>New employees</strong> will be prompted to complete this document online when they onboard or first log in.<br/>
                            <small>New employees will be blocked from accessing paystubs, W-2s, etc. until they have completed this document.</small>
                        </p>
                        <p v-else>
                            <strong>Managers</strong> will be given a task to upload this document for each new employee.<br/>
                            <small>New employees will be allowed to access paystubs, W-2s, etc. before this document is completed.</small>
                        </p>
                    </template>
                    <h5 class="pt-1">{{ activeEmployeeLabel }} Employees</h5>
                    <template v-if="formData.collection_method == 'employee'">
                        <p>
                            <strong>{{ activeEmployeeLabel }} employees</strong> will receive an email and will be prompted to complete this document online.<br/>
                            <small v-if="formData.block_ee_until_completion">{{ activeEmployeeLabel }} employees will be blocked from accessing paystubs, W-2s, etc. until they have completed this document.</small>
                            <small v-else>{{ activeEmployeeLabel }} employees will be allowed to access paystubs, W-2s, etc. before this document is completed.</small>
                        </p>
                        <alert-box type="info" v-if="willSendEmails" class="mt-2 mb-0">
                            This will send emails to {{ numRequiredEmployees }} employees.
                        </alert-box>
                    </template>
                    <p v-if="formData.collection_method == 'manager'">
                        <strong>Managers</strong> will be given a task to upload this document for each {{ activeEmployeeLabel.toLowerCase() }} employee.<br/>
                        <small>{{ activeEmployeeLabel }} employees will be allowed to access paystubs, W-2s, etc. before this document is completed.</small>
                    </p>
                    <p v-else-if="formData.collection_method == 'do-not-collect'">
                        This document will not be collected for active employees.
                    </p>
                </div>
            </info-widget>

            <info-widget v-if="addDocMode">
                <template v-slot:title>Termination Settings</template>
                <template v-slot:headerbutton>
                    <button class="btn btn-outline-primary" type="button" @click.prevent="goToStepFromSummary('doc-termination-settings')">
                        <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
                        Edit
                    </button>
                </template>
                <div class="card-body">
                    <span v-if="formData.remove_requirement_on_termination">Document is <strong>canceled</strong> and no longer required for an employee when they are terminated.</span>
                    <span v-else>Document is <strong>still required</strong> for an employee after they are terminated.</span>
                </div>
            </info-widget>
        </div>

    </form>

    <form-errors :errors="formErrors"/>

    <button-row stacked>
        <button type="button" class="btn btn-outline-primary" @click.prevent="onPrevStep">Back</button>
        <button v-if="showSaveAsDraft" type="button" class="btn btn-outline-primary" @click.prevent="saveAsDraft">Save as Draft</button>
        <button v-if="showSaveButton" type="button" class="btn btn-primary" @click.prevent="saveAndPublish">{{ saveAndPublishText }}</button>
        <button v-else type="button" class="btn btn-primary" @click.prevent="onNextStep">Continue</button>
    </button-row>
</div>
</template>

<script>
import FormMixin from '@/mixins/Form'
import CompanySelector from './CompanySelector'
import PositionSelector from './PositionSelector'
import InfoWidget from '@/components/InfoWidget'
import PdfInline from '@/components/PdfInline'
import ButtonRow from '@/components/ButtonRow.vue'

// Steps used when adding a new doc (i.e. 'add', 'edit-draft', and 'duplicate' modes)
const STEPS = [
    'doc-companies',
    'doc-name-upload',
    'doc-settings',
    'doc-assignment',
    'doc-positions',
    'doc-collection-settings',
    'doc-termination-settings',
    'doc-summary'
]

// Steps used when adding or editing a version (i.e. 'add-version' and 'edit-draft-version' modes)
const VERSION_STEPS = [
    'doc-name-upload',
    'doc-settings',
    'version-settings',
    'doc-summary'
]

const STEP_METADATA = {
    'doc-companies': {'title': 'Companies', 'showSaveAsDraft': false},
    'doc-name-upload': {'title': 'Upload', 'showSaveAsDraft': true},
    'doc-settings': {'title': 'Document Settings', 'showSaveAsDraft': true, 'skipUntilHRDocsSuiteReleased': true,},
    'doc-assignment': {'title': 'Required Employees', 'showSaveAsDraft': true, 'skipWithoutCompanyDocsModule': true, 'saveOnNext': true},
    'version-settings': {'title': 'Version Settings', 'showSaveAsDraft': true, 'skipWithoutCompanyDocsModule': true,},
    'doc-positions': {'title': 'Required Positions', 'showSaveAsDraft': true, 'saveOnNext': true},
    'doc-collection-settings': {'title': 'Collection Settings', 'showSaveAsDraft': true, 'skipWithoutCompanyDocsModule': true,},
    'doc-termination-settings': {'title': 'Termination Settings', 'showSaveAsDraft': true, 'skipWithoutCompanyDocsModule': true,},
    'doc-summary': {'title': 'Summary', 'showSaveAsDraft': true, 'skipWithoutCompanyDocsModule': true},
}

export default {
    components: {CompanySelector, PositionSelector, InfoWidget, PdfInline, ButtonRow, },
    props: ['client', 'documentId', 'mode'], // mode can be add, edit-draft, add-version, edit-draft-version, duplicate
    mixins: [FormMixin, ],
    data() {
        let data = {
            step: this.getStartingStep(),
            formData: this.makeFormData(),
            allPositions: [],
            document: {},
            documentDataLoaded: false,
            documentPdfUrl: null,
            pdfPreviewUrl: null,
            numRequiredEmployeesInBatch: 0,
            navigatedFromSummary: false
        }
        return data
    },
    computed: {
        companyAsText() {
            let companyText = ''
            if (this.formData.companies && this.formData.companies.length > 0) {
                let company = (this.client.companies.find(company => company.id == this.formData.companies[0]))
                if (company) {
                    companyText = company.name
                }
                if (this.formData.companies && this.formData.companies.length > 1) {
                    companyText += ` + ${this.formData.companies.length - 1} More`
                }
            }
            return companyText
        },
        duplicateFromCompanyAsText() {
            let companyText = ''
            if (this.mode == 'duplicate' && this.document.company_id) {
                let company = (this.client.companies.find(company => company.id == this.document.company_id))
                if (company) {
                    companyText = company.name
                }
            }
            return companyText
        },
        stepAsText() {
            if (this.step) {
                return STEP_METADATA[this.step]['title']
            } else {
                return ''
            }
        },
        showSaveAsDraft() {
            if (this.step) {
                return this.hrDocsSuiteReleased && STEP_METADATA[this.step]['showSaveAsDraft']
            } else {
                return false
            }
        },
        allCompanyPositions() {
            // positions filtered to currently selected companies
            return this.allPositions.filter(p => this.formData.companies && this.formData.companies.includes(p.company_id))
        },
        selectedPositions() {
            return this.allCompanyPositions.filter(p => this.formData.positions && this.formData.positions.includes(p.id))
        },
        selectedCompanies() {
            return this.client.companies.filter(c => this.formData.companies && this.formData.companies.includes(c.id))
        },
        companiesWithFeature() {
            return this.client.companies.filter(c => (c.has_company_docs_module || c.has_paperless_onboarding))
        },
        documentDraftPdfUrl() {
            // url to pdf uploaded to previous edit of this draft
            if ((this.mode == 'edit-draft' || this.mode == 'edit-draft-version') && this.document && this.document.draft_version) {
                let version = this.document.draft_version
                return {
                    url: `${this.$api.API_BASE}/clients/${this.client.id}/hr-documents/${version.hr_document_id}/file/pdf?file_id=${version.id}`,
                    withCredentials: true,
                }
            } else {
                return null
            }
        },
        duplicatedPdfUrl() {
            // url to pdf for the document from which we are duplicating
            if (this.mode == 'duplicate' && this.document && this.document.current_version) {
                let version = this.document.current_version
                return {
                    url: `${this.$api.API_BASE}/clients/${this.client.id}/hr-documents/${version.hr_document_id}/file/pdf?file_id=${version.id}`,
                    withCredentials: true,
                }
            }
        },
        hrDocsSuiteReleased() {
            return this.$store.state.system_flags?.hr_docs_suite_released || this.client.preview_hr_docs_suite
        },
        hasCompanyDocsModule() {
            // NB: show company docs module -only steps in the wizard so long as at least one selected company has the module
            // (but company docs module -only features like setting major_version_number > 0 and sending doc to active employees will only be applied on the backend for companies with has_company_docs_module)
            return this.client.has_company_docs_module && this.selectedCompanies && this.selectedCompanies.some(c => c.has_company_docs_module)
        },
        showSaveButton() {
            if (this.step == 'doc-summary') {
                return true
            } else if (!this.hasCompanyDocsModule && this.step == 'doc-positions') {
                return true
            } else if (!this.hasCompanyDocsModule && this.addVersionMode && this.step == 'doc-name-upload') {
                return true
            } else {
                return false
            }
        },
        addDocMode() {
            return this.mode == 'add' || this.mode == 'edit-draft' || this.mode == 'duplicate'
        },
        addVersionMode() {
           return this.mode == 'add-version' || this.mode == 'edit-draft-version'
        },
        numRequiredEmployees() {
            if (this.addDocMode) {
                return this.numRequiredEmployeesInBatch
            } else if (this.addVersionMode && this.document) {
                return this.document.num_required_employees
            }
            return 0
        },
        willSendEmails() {
            return this.hasCompanyDocsModule && (this.addDocMode || this.formData.is_major_version) && this.formData.collection_method == 'employee' && this.numRequiredEmployees > 0
        },
        willCreateTasks() {
            return this.hasCompanyDocsModule && (this.addDocMode || this.formData.is_major_version) && this.formData.collection_method == 'manager' && this.numRequiredEmployees > 0
        },
        saveAndPublishText() {
            if (this.willSendEmails) {
                return 'Save & Send'
            } else {
                if (this.addVersionMode) {
                    if (this.hrDocsSuiteReleased) {
                        return 'Save & Make Active'
                    } else {
                        return 'Save Version'
                    }
                } else {
                    return 'Save Document'
                }
            }
        },
        activeEmployeeLabel() {
            return (this.formData.include_in_onboarding === null && !this.formData.positions.length) ? 'Required' : 'Active'
        }
    },
    created() {
        if (this.documentId) {
            this.getDocument()
        } else {
            this.documentDataLoaded = true
        }
        this.getPositions()
    },
    methods: {
        getDocument() {
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/clients/${this.client.id}/hr-documents/${this.documentId}`).then(resp => {
                this.document = resp
                this.documentDataLoaded = true
                this.formData = this.makeFormData()
                this.step = this.getStartingStep()
                this.$store.dispatch('STOP_LOADING')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        getPositions() {
            // positions filtered by security profile on backend
            this.$api.get(`/clients/${this.client.id}/positions`).then(resp => {
                this.allPositions = resp
                this.allPositions.sort((a, b) => {return a.terminal_value.toLowerCase() > b.terminal_value.toLowerCase() ? 1 : -1})
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })
        },
        makeFormData() {
            let data = {}
            if (this.mode == 'add') {
                data = {
                    action: this.mode,
                    version: 1,
                    companies: [],
                    name: '',
                    filename: '',
                    pdf_file: null,
                    upload_from_draft_version_id: null,
                    designation: 'regular',
                    assign_to: '',
                    positions: [],
                    is_major_version: undefined,
                    manager_signature_required: undefined,
                    collection_method: '',
                    block_ee_until_completion: undefined,
                    remove_requirement_on_termination: undefined,
                    include_in_onboarding: undefined,
                    is_draft: true,
                    batch_key: '',
                    publish_version: false,
                    draft_data: {}
                }
            } else if (this.document && this.mode == 'add-version') {
                data = this.makeFormDataFromDocumentVersion(this.document.current_version)
                data.version = data.version + 1
                data.is_major_version = undefined
            } else if (this.document && this.mode == 'edit-draft') {
                data = this.makeFormDataFromDocumentVersion(this.document.draft_version)
                data.batch_key = this.document.batch_key
                data.companies = this.document.batch_company_ids
            } else if (this.document && this.mode == 'edit-draft-version') {
                data = this.makeFormDataFromDocumentVersion(this.document.draft_version)
            } else if (this.document && this.mode == 'duplicate') {
                data = this.makeDuplicateFormDataFromDocumentVersion(this.document.current_version)
            }
            return data
        },
        // TODO KB this method currently has no way of knowing if this data was actually filled by a user before saving as draft or if it's using the model's default values
        // which has the annoying effect of showing filled values to the user when they come back to a draft form when we'd actually prefer those values to be undefined until explicitly selected
        // For that reason and more we should ideally have a separate version of this method for creating a new version vs. loading from a draft version
        makeFormDataFromDocumentVersion(documentVersion) {
            const dv = JSON.parse(JSON.stringify(documentVersion))
            let formData = {
                action: this.mode,
                version: dv.version,
                companies: [this.document.company_id],
                name: this.document.name,
                filename: '',
                pdf_file: null, // no longer needed after having saved the pdf file on the backend
                upload_from_draft_version_id: null,
                designation: 'regular',
                assign_to: dv.draft_data['assign_to'],
                positions: dv.draft_data['positions'] || this.document.positions.map(p => p.id), // TODO KB upon reapplying this, filter to make sure we still have access to all these positions
                is_major_version: dv.is_major_version,
                manager_signature_required: dv.manager_signature_required,
                collection_method: dv.draft_data['collection_method'] || this.document.collection_method,
                block_ee_until_completion: dv.draft_data['block_ee_until_completion'] || this.document.block_ee_until_completion,
                remove_requirement_on_termination: this.document.remove_requirement_on_termination,
                include_in_onboarding: dv.draft_data['include_in_onboarding'] ||  this.document.include_in_onboarding,
                is_draft: true,
                batch_key: '',
                publish_version: false,
                draft_data: dv.draft_data
            }
            return formData
        },
        makeDuplicateFormDataFromDocumentVersion(documentVersion) {
            const dv = JSON.parse(JSON.stringify(documentVersion))
            let formData = {
                action: this.mode,
                version: 1,
                companies: [],
                name: this.document.name,
                filename: '',
                pdf_file: null,
                upload_from_duplicate_version_id: dv.id,
                designation: 'regular',
                assign_to: '',
                positions: [],
                is_major_version: dv.is_major_version,
                manager_signature_required: dv.manager_signature_required,
                collection_method: this.document.collection_method,
                block_ee_until_completion: this.document.block_ee_until_completion,
                remove_requirement_on_termination: this.document.remove_requirement_on_termination,
                include_in_onboarding: this.document.include_in_onboarding,
                is_draft: true,
                batch_key: '',
                publish_version: false,
                draft_data: {}
            }
            return formData
        },
        makeDraftData() {
            // Save info about the draft for resumption that would not otherwise be saved on the model on a draft save
            let draftData = {
                step: this.step,
                collection_method: this.formData.collection_method,
                block_ee_until_completion: this.formData.block_ee_until_completion,
                include_in_onboarding: this.formData.include_in_onboarding
            }
            if (this.addDocMode) {
                draftData.assign_to = this.formData.assign_to,
                draftData.positions = this.formData.positions
            }
            return draftData
        },
        getStartingStep() {
            if (this.mode == 'add') {
                return STEPS[0]
            } else if (this.mode == 'add-version') {
                return VERSION_STEPS[0]
            } else if (this.mode == 'edit-draft') {
                if (this.document && this.document.draft_version) {
                    return this.document.draft_version.draft_data.step || STEPS[0]
                } else {
                    return STEPS[0]
                }
            } else if (this.mode == 'edit-draft-version') {
                if (this.document && this.document.draft_version) {
                    return this.document.draft_version.draft_data.step || VERSION_STEPS[0]
                } else {
                    return VERSION_STEPS[0]
                }
            } else if (this.mode == 'duplicate') {
                return STEPS[0]
            }
        },
        getNextStep(step) {
            if (this.navigatedFromSummary && step != 'doc-assignment') {
                this.navigatedFromSummary = false
                return 'doc-summary'
            }
            if (this.addVersionMode && this.document.major_version_number == 0 && this.formData.is_major_version) {
                // if we're upgrading from old to new company doc, make user pick collection settings
                if (step == 'version-settings') { return 'doc-collection-settings' }
                if (step == 'doc-collection-settings') { return 'doc-summary'}
            }
            let steps = this.addVersionMode ? VERSION_STEPS : STEPS
            let stepIdx = steps.indexOf(step)
            if (stepIdx == -1 || stepIdx + 1 >= steps.length) {
                return null
            } else {
                let nextStep = steps[stepIdx + 1]
                if (nextStep == 'doc-positions' && this.formData.assign_to == 'individuals') {
                    nextStep = this.getNextStep(nextStep)
                }
                if (!this.hasCompanyDocsModule && STEP_METADATA[nextStep]['skipWithoutCompanyDocsModule']) {
                    nextStep = this.getNextStep(nextStep)
                }
                if (!this.hrDocsSuiteReleased && STEP_METADATA[nextStep]['skipUntilHRDocsSuiteReleased']) {
                    nextStep = this.getNextStep(nextStep)
                }
                return nextStep
            }
        },
        getPrevStep(step) {
            if (this.navigatedFromSummary && step != 'doc-positions') {
                this.navigatedFromSummary = false
                return 'doc-summary'
            }
            if (this.addVersionMode && this.document.major_version_number == 0 && this.formData.is_major_version) {
                // if we're upgrading from old to new company doc, make user pick collection settings
                if (step == 'doc-summary') { return 'doc-collection-settings' }
                if (step == 'doc-collection-settings') { return 'version-settings'}
            }
            let steps = this.addVersionMode ? VERSION_STEPS : STEPS
            let stepIdx = steps.indexOf(step)
            if (stepIdx <= 0) {
                return null
            } else {
                let prevStep = steps[stepIdx - 1]
                if (prevStep == 'doc-positions' && this.formData.assign_to == 'individuals') {
                    prevStep = this.getPrevStep(prevStep)
                }
                if (!this.hasCompanyDocsModule && STEP_METADATA[prevStep]['skipWithoutCompanyDocsModule']) {
                    prevStep = this.getPrevStep(prevStep)
                }
                if (!this.hrDocsSuiteReleased && STEP_METADATA[prevStep]['skipUntilHRDocsSuiteReleased']) {
                    prevStep = this.getNextStep(prevStep)
                }
                return prevStep
            }
        },
        goToStep(step) {
            this.step = step
        },
        goToNextStep() {
            this.goToStep(this.getNextStep(this.step))
        },
        goToStepFromSummary(step) {
            this.navigatedFromSummary = true
            this.goToStep(step)
        },
        onNextStep() {
            this.validateFormData().then(validated => {
                if (!validated) {
                    return false
                } else {
                    if (this.hrDocsSuiteReleased && STEP_METADATA[this.step]['saveOnNext']) {
                        this.formData.publish_version = false
                        this.formData.draft_data = this.makeDraftData()
                        this.formData.draft_data['step'] = this.step // TODO KB this should ideally be nextStep instead of this.step, but we have a problem with doing getNextStep more than once + navigatedFromSummary logic
                        this.saveDocument({callback: this.goToNextStep, silent: false})
                    } else {
                        this.goToStep(this.getNextStep(this.step))
                    }
                }
            })
        },
        onPrevStep() {
            // first check if we should exit out of this component
            if (this.step == STEPS[0]) {
                this.$emit('prev') // return to doc type selector in parent component
                return
            }
            if (this.step == VERSION_STEPS[0] && this.addVersionMode) {
                this.exitToDocuments() // return to Settings / HR Documents table
                return
            }
            this.goToStep(this.getPrevStep(this.step))
        },
        onFileUpload(f) {
            this.formData.filename = f[0].name
            this.formData.pdf_file = null
            this.pdfPreviewUrl = null

            const dataURLReader = new FileReader()
            dataURLReader.onload = (e) => {
                this.pdfPreviewUrl = e.target.result
            }
            dataURLReader.readAsDataURL(f[0])

            const binaryStringReader = new FileReader()
            binaryStringReader.onload = () => {
                this.formData.pdf_file = btoa(binaryStringReader.result)
            }
            binaryStringReader.readAsBinaryString(f[0])
        },
        exitToDocuments() {
            this.$router.push({name: 'settings-hr-documents'})
        },
        async validateFormData() {
            // validate form data on each next step
            this.formErrors = []
            this.errors = {}
            if (this.step == 'doc-companies') {
                if (!this.formData.companies.length) {
                    this.formErrors = ['At least one company is required.']
                    return false
                }
                return true
            }
            if (this.step == 'doc-name-upload') {
                if (!(this.formData.pdf_file || this.documentDraftPdfUrl || this.duplicatedPdfUrl)) {
                    this.errors['pdf_file'] = ['Document upload is required.']
                    return false
                }
                if (this.addDocMode) {
                    if (!this.formData.name) {
                        this.errors['name'] = ['Document name is required.']
                        return false
                    }
                    try {
                        let resp = await this.$api.post(`/clients/${this.client.id}/hr-documents?action=validate-name`, this.getFormData())
                        return true
                    } catch(errors) {
                        this.errors = errors
                        this.formErrors = errors.__all__
                        this.onError()
                        return false
                    }
                }
            }
            if (this.step == 'doc-settings') {
                if (this.formData.manager_signature_required === undefined) {
                    this.formErrors = ['Manager signature settings are required to continue.']
                    return false
                }
            }
            if (this.step == 'doc-assignment') {
                if (this.formData.assign_to == '') {
                    this.formErrors = ['Select one of the above options to continue.']
                    return false
                }
            }
            if (this.step == 'version-settings') {
                if (this.formData.is_major_version === undefined) {
                    this.formErrors = ['Select one of the above options to continue.']
                    return false
                }
            }
            if (this.step == 'doc-collection-settings') {
                if (this.formData.include_in_onboarding === undefined || (this.formData.positions.length && this.formData.include_in_onboarding === null)) {
                    this.formErrors = ['Select collection settings for new employees to continue.']
                    return false
                }
                if (this.formData.collection_method == '') {
                    this.formErrors = ['Select collection settings for active employees to continue.']
                    return false
                }
                if (this.formData.collection_method == 'employee' && this.formData.block_ee_until_completion === undefined) {
                    this.formErrors = ['Select collection settings for active employees to continue.']
                    return false
                }
            }
            if (this.step == 'doc-termination-settings') {
                if (this.formData.remove_requirement_on_termination === undefined) {
                    this.formErrors = ['Select termination settings to continue.']
                    return false
                }
            }
            return true
        },
        getFormData() {
            if ((this.mode == 'edit-draft' || this.mode == 'edit-draft-version') && this.document.draft_version && !this.formData.pdf_file) {
                this.formData.upload_from_draft_version_id = this.document.draft_version.id
            }
            return this.formData
        },
        saveAsDraft() {
            this.formData.publish_version = false
            this.formData.draft_data = this.makeDraftData()
            this.saveDocument({callback: this.exitToDocuments})
        },
        saveAndPublish() {
            this.formData.publish_version = true;
            this.saveDocument({callback: this.exitToDocuments})
        },
        saveDocument(options) {
            this.validateFormData().then(validated => {
                if (!validated) {
                    return false
                } else {
                    if (!(options && options.silent)) {
                        this.$store.dispatch('START_LOADING')
                    }
                    if (this.addDocMode) {
                        this.$api.post(`/clients/${this.client.id}/hr-documents`, this.getFormData()).then(resp => {
                            this.formData.batch_key = resp['batch_key']
                            this.numRequiredEmployeesInBatch = resp['num_required_employees']
                            this.$store.dispatch('STOP_LOADING')
                            if (options['callback']) {
                                options['callback']()
                            }
                        }).catch(errors => {
                            this.$store.dispatch('STOP_LOADING')
                            this.errors = errors
                            this.formErrors = errors.__all__
                            this.onError()
                        })
                    } else if (this.addVersionMode) {
                        this.$api.post(`/clients/${this.client.id}/hr-documents/${this.documentId}/files`, this.getFormData()).then(resp => {
                            this.$store.dispatch('STOP_LOADING')
                            if (options['callback']) {
                                options['callback']()
                            }
                        }).catch(errors => {
                            this.$store.dispatch('STOP_LOADING')
                            this.errors = errors
                            this.formErrors = errors.__all__
                            this.onError()
                        })
                    }
                }
            })
        },
    }
}
</script>

<style scoped>
.pdf-inline-wrapper {
    overflow-y: scroll;
    max-height: 710px;
}
</style>
