<template>
    <div ref="trWrapper" class="tr-wrapper" :class="{
      'active': (source.id == activeRowId) || (source.isActive), // NB: prefer passing activeRowId over isActive in computed tableData for performance reasons
      'tr-sectionhead': source.isHeading,
      'tr-head': source.isHeaders,
      'tr-empty': source.isEmpty,
      'tr-content': !source.isHeading && !source.isHeaders && !source.isEmpty
    }">
        <!-- Section Heading -->
        <div class="tr-sectionhead-bar" v-if="source.isHeading" :id="source.id">
            <div class="tr-sectionhead-sticky" :style="{'width': stickyWidth + 'px'}">
                <div class="tr-sectionhead-title-wrapper">
                    <a href class="btn pl-0 d-inline collapse-trigger" :class="{'is-expanded': !source.isClosed}" role="button" aria-expanded="true" @click.prevent="toggleSection">
                      <collapse-toggle-button />
                      <span class="tr-sectionhead-title">
                        <h5 class="m-0">{{ source.title }} <span class="text-regular">({{ source.count }})</span></h5>
                      </span>
                    </a>
                </div>

                <div class="tr-sectionhead-menu" v-if="source.useDateFilters">
                    <date-filter-trigger :date-filter="source.dateFilter" @click="onDateFilterClick" />
                </div>
            </div>
        </div>
        <!-- Column Headers -->
        <div class="tr" v-if="source.isHeaders && !source.isClosed" >
            <div class="td anchor-col col-sortable" @click.prevent="onSort(headers[0].id)" :class="{'col-sort-asc': (headers[0].id == sortColumnId && sortIsAsc), 'col-sort-desc': (headers[0].id == sortColumnId && !sortIsAsc), 'has-checkbox': hasRowSelect}">
                <label class="row-checkbox" v-if="hasRowSelect"><input type="checkbox" aria-label="Select All" @click.prevent.stop="toggleSelectAll"></label>
                <div class="cell-content">{{ headers[0].label }} <span class="sort-icon"></span></div>
            </div>
            <!-- <VueDraggable v-model="draggableHeaders" @end="onHeaderRearrange"> -->
                <div v-for="header in draggableHeaders" @click.prevent="onSort(header.id)" class="td col-sortable draggable" :class="[header.classes, (sortColumnId == header.id && sortIsAsc) ? 'col-sort-asc' : '', (sortColumnId == header.id && !sortIsAsc) ? 'col-sort-desc' : '']" :key="header.id">
                    <div class="cell-content">{{ header.label }} <span class="sort-icon"></span></div>
                </div>
            <!-- </VueDraggable> -->
        </div>

        <div class="tr-empty-sticky" v-if="source.isEmpty">
            <em class="text-muted" v-if="!(searchIsActive || filterIsActive)">This section is empty.</em>
            <em class="text-muted" v-if="searchIsActive || filterIsActive">This section has no matching results.</em>
        </div>

        <div class="tr" v-if="!source.isHeading && !source.isHeaders && !source.isEmpty" @click.prevent="onClick">
            <div class="td anchor-col" :class="{ 'has-checkbox': hasRowSelect || hasRowRadioSelect }">
                <label class="row-checkbox" v-if="hasRowSelect"><input type="checkbox" aria-label="Select Row" @click.stop="onSelect" :checked="source.isSelected" :disabled="source.selectDisabled"></label>
                <label class="row-checkbox" v-if="hasRowRadioSelect"><input type="radio" aria-label="Select Row" @click.stop="onRadioRowSelect" :checked="source.isSelected" :disabled="source.selectDisabled"></label>
                <cell :cell="source.cells[0]" :header="headers[0]" @click.prevent.stop="onCellClick($event, 0)" />
            </div>

            <div v-for="(header, index) in headers.slice(1)" :class="['td', header.classes]">
                <cell :cell="source.cells[index + 1]" :header="headers[index + 1]" @click.prevent.stop="onCellClick($event, index + 1)" />
            </div>
        </div>

        <template v-if="source.subrows && !source.isHeading && !source.isHeaders  && !source.isEmpty">
            <div class="tr tr-subrow" v-for="subrow in source.subrows" @click.prevent="onClick">
                <div class="td anchor-col"><div class="cell-content"></div></div>

                <div v-for="(header, index) in headers.slice(1)" :class="['td', header.classes, {'td-empty': !((subrow[index + 1] && subrow[index + 1]._meta) ? subrow[index + 1].value : subrow[index + 1]) }]">
                    <cell :cell="subrow[index + 1]" :header="headers[index + 1]" @click.prevent.stop="onCellClick($event, index + 1)"/>
                </div>
            </div>
        </template>

    </div>
</template>

<script>
import Cell from './Cell'
import DateFilterTrigger from './DateFilterTrigger'
import { VueDraggable } from 'vue-draggable-plus'

export default {
    props: ['source', 'headers', 'onclick', 'onselect', 'onRadioSelect', 'onSort', 'onToggleSection', 'onToggleSelectAll', 'sortColumnId', 'sortIsAsc', 'stickyWidth', 'dateFilterClickHandler', 'onColumnSort', 'searchIsActive', 'filterIsActive', 'hasRowSelect', 'hasRowRadioSelect', 'activeRowId', ],
    components: {Cell, DateFilterTrigger, VueDraggable},
    emits: ['click'],
    data() {
        return {
            draggableHeaders: this.headers.slice(1),
            foobar: false,
        }
    },
    watch: {
        headers() {
            this.draggableHeaders = this.headers.slice(1)
        },
    },
    methods: {
        onDateFilterClick() {
            this.dateFilterClickHandler(this.source.id)
        },
        onCellClick($event, columnIdx) {
            this.onclick({
                row: this.source,
                columnIdx: columnIdx,
            })
        },
        onClick() {
            this.onclick({
                row: this.source,
                columnIdx: null,
            })
        },
        toggleSection() {
            this.onToggleSection(this.source.id)
        },
        onHeaderRearrange() {
            const ids = [this.headers[0].id].concat(this.draggableHeaders.map(h => h.id))
            this.onColumnSort(ids)
        },
        toggleSelectAll() {
            this.onToggleSelectAll(this.source.id)
        },
        onSelect() {
            this.onselect(this.source)
        },
        onRadioRowSelect() {
            this.onRadioSelect(this.source)
        },
    },
}
</script>

