<template>
    <sidebar @close="$emit('close')">
        <template v-slot:title v-if="document">
            {{ document.name }}
        </template>

        <template v-slot:header v-if="document">

            <div class="row mb-0">
                <div class="col">
                    <span class="text-muted">
                        {{ companyAsText }}
                    </span>
                </div>
            </div>
            <div class="row mb-0">
                <div class="col">
                    <span class="text-muted">
                        HR Document
                        &#8226;
                        <span v-if="document.is_active && !documentVersion.is_draft">
                            Active
                        </span>
                        <span class="text-bold" v-if="document.is_active && documentVersion.is_draft">
                            Draft
                        </span>
                        <span class="text-danger-dark text-bold text-italic" v-if="!document.is_active">
                            Inactive
                        </span>
                        <template v-if="documentType != 'hr-form-doc'">
                            &#8226;
                            <span>Version {{ documentVersion.version_number || document.version }}</span>
                        </template>
                    </span>
                </div>
            </div>
            <div class="row mb-1">
                <div class="col">
                    <span class="text-muted">
                        Last modified: {{ $filters.date(document.updated_on) }}
                    </span>
                </div>
            </div>

            <!-- Tabs -->
            <div class="nav-tabs-container">
                <div class="nav-tabs-scroll-helper">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'main'}" href id="tabLinkMain1" data-toggle="tab" role="tab" aria-controls="tabContentMain1" :aria-selected="activeTab == 'main'" @click.prevent="activeTab = 'main'">Main</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'changelogs'}" href id="tabLinkHistory1" data-toggle="tab" role="tab" aria-controls="tabContentHistory" :aria-selected="activeTab == 'changelogs'" @click.prevent="activeTab = 'changelogs'">History</a>
                        </li>
                    </ul>
                </div>
            </div>
        </template>

        <slot>
            <!-- Tabs Content Container -->
            <div class="tab-content" v-if="document">
                <!-- Tab Pane 1 -->
                <div class="tab-pane" :class="{'show active': activeTab == 'main'}" id="tabContentMain1" role="tabpanel" aria-labelledby="tabLinkMain1">

                    <alert-box v-if="$store.state.activeTour == 'hr-suite'" type="info" class="mb-2">
                        This document is just for show! It will not be sent to your real employees and will disappear after this tutorial.
                    </alert-box>

                    <!-- Uniform Info Widget -->
                    <info-widget v-if="documentType == 'uniform'" :collapsible="false">
                        <template v-slot:title>Document Information</template>

                        <template v-slot:headerbutton></template>

                        <slot>
                            <div class="card-body py-1 mt-1">
                                <alert-box v-if="!isFeatureEnabled" type="info" class="mb-2">
                                    This document is uneditable because this {{ document.company.name }} does not have the Uniform module enabled. Employees will not be prompted to fill this Uniform document.
                                </alert-box>
                                <alert-box v-if="document.is_active && !document.is_draft && documentVersion.is_draft" type="info" class="mb-2">
                                    This is a draft version of an active document.
                                    <a href class="btn btn-link py-0" @click.prevent="changeDocumentVersion(document.current_version.version_number)"> Go to the active version <i class="fas fa-fw fa-chevron-right"></i></a>
                                </alert-box>
                                <alert-box v-if="documentVersionNumber && document.is_active && !document.is_draft && document.draft_version && !documentVersion.is_draft" type="info" class="mb-2">
                                    You have a draft version of this document in progress.
                                    <a href class="btn btn-link py-0" @click.prevent="changeDocumentVersion(document.draft_version.version_number)"> Go to the draft version <i class="fas fa-fw fa-chevron-right"></i></a>
                                </alert-box>

                                <div class="d-flex align-items-start">
                                    <div class="flex-fill overflow-auto">
                                        <div class="mui-textfield">
                                            <span class="textfield">{{ document.name }}</span>
                                    <label>Document Name</label>
                                        </div>
                                    </div>
                                    <div class="flex-0">
                                        <button v-if="showEditDocumentName" class="btn btn-outline-primary btn-collapse-xl ml-hf" @click.prevent="editDocumentName">
                                            <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
                                            <span class="btn-text">Edit</span>
                                        </button>
                                    </div>
                                </div>

                                <div class="mui-textfield">
                                    <span class="textfield">Uniform Form</span>
                                    <label>Document Type</label>
                                </div>

                                <div v-if="!documentVersion.is_draft">
                                    <div class="mui-textfield" v-for="(item, idx) in document.current_version.item_data" :key="idx">
                                        <span class="textfield">
                                            <span>{{ item.name }}</span><br/>
                                            <span>{{ uniformItemOptionsAsText(item) }}</span>
                                        </span>
                                        <label>{{ `Clothing Item ${idx + 1}` }}</label>
                                    </div>
                                </div>

                                <!-- This is a draft document -->
                                <button-row v-if="document.is_draft && isFeatureEnabled" stacked class="pt-0">
                                    <button type="button" class="btn btn-outline-primary" @click.prevent="deleteDraftDocument()">
                                        <i class="far fa-fw fa-file-minus"></i>Delete Draft
                                    </button>
                                    <router-link class="btn btn-primary" :to="{name: 'settings-hr-documents-edit-draft', params: {client: client.id, documentType: 'uniform', documentId: document.id} }">
                                        <i class="far fa-fw fa-pen"></i> Edit Draft
                                    </router-link>
                                </button-row>

                                <!-- This is an active document but we're looking at a draft version -->
                                <button-row v-else-if="documentVersion.is_draft && isFeatureEnabled" stacked class="pt-0">
                                    <button type="button" class="btn btn-outline-primary" @click.prevent="deleteDraftDocumentVersion()">
                                        <i class="far fa-fw fa-file-minus"></i>Delete Draft
                                    </button>
                                    <router-link class="btn btn-primary" :to="{name: 'settings-hr-documents-edit-draft-version', params: {client: client.id, documentType: 'uniform', documentId: document.id, documentVersionNumber: documentVersionNumber} }">
                                        <i class="far fa-fw fa-pen"></i> Edit Draft
                                    </router-link>
                                </button-row>

                                <!-- This is an active or inactive document, and we're looking at the current version -->
                                <button-row v-else-if="!document.is_draft && !documentVersion.is_draft && isFeatureEnabled" stacked class="pt-0">
                                    <button type="button" class="btn btn-outline-primary" @click.prevent="deactivateDocument()" v-if="document.is_active">
                                        <i class="far fa-fw fa-file-minus"></i>Deactivate
                                    </button>
                                    <button type="button" class="btn btn-outline-primary" @click.prevent="reactivateDocument()" v-if="!document.is_active">
                                        <i class="far fa-fw fa-file-plus"></i>Reactivate Document
                                    </button>
                                    <!-- <router-link class="btn btn-outline-primary btn-collapse" :to="{name: 'settings-hr-documents-duplicate', params: {client: client.id, documentType: 'uniform', documentId: document.id} }">
                                        <i class="far fa-fw fa-copy"></i><span class="btn-text">Copy</span>
                                    </router-link> -->
                                </button-row>
                            </div>
                        </slot>
                    </info-widget>

                    <!-- Custom Form Info Widget -->
                    <info-widget v-if="documentType == 'hr-custom-form'" :collapsible="false">
                        <template v-slot:title>Document Information</template>

                        <template v-slot:headerbutton></template>

                        <slot>
                            <div class="card-body py-1 mt-1">
                                <alert-box v-if="!isFeatureEnabled" type="info" class="mb-2">
                                    This document is uneditable because this {{ document.company.name }} does not have the Custom Forms module enabled. Employees will not be prompted to fill this Uniform document.
                                </alert-box>
                                <alert-box v-if="document.is_active && !document.is_draft && documentVersion.is_draft" type="info" class="mb-2">
                                    This is a draft version of an active document.
                                    <a href class="btn btn-link py-0" @click.prevent="changeDocumentVersion(document.current_version.version_number)"> Go to the active version <i class="fas fa-fw fa-chevron-right"></i></a>
                                </alert-box>
                                <alert-box v-if="documentVersionNumber && document.is_active && !document.is_draft && document.draft_version && !documentVersion.is_draft" type="info" class="mb-2">
                                    You have a draft version of this document in progress.
                                    <a href class="btn btn-link py-0" @click.prevent="changeDocumentVersion(document.draft_version.version_number)"> Go to the draft version <i class="fas fa-fw fa-chevron-right"></i></a>
                                </alert-box>

                                <div class="d-flex align-items-start">
                                    <div class="flex-fill overflow-auto">
                                        <div class="mui-textfield">
                                            <span class="textfield">{{ document.name }}</span>
                                    <label>Document Name</label>
                                        </div>
                                    </div>
                                    <div class="flex-0">
                                        <button v-if="showEditDocumentName" class="btn btn-outline-primary btn-collapse-xl ml-hf" @click.prevent="editDocumentName">
                                            <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
                                            <span class="btn-text">Edit</span>
                                        </button>
                                    </div>
                                </div>

                                <div class="mui-textfield">
                                    <span class="textfield">Custom Form</span>
                                    <label>Document Type</label>
                                </div>

                                <!-- This is a draft document -->
                                <button-row v-if="document.is_draft && isFeatureEnabled" stacked class="pt-0">
                                    <button type="button" class="btn btn-outline-primary" @click.prevent="deleteDraftDocument()">
                                        <i class="far fa-fw fa-file-minus"></i>Delete Draft
                                    </button>
                                    <router-link class="btn btn-primary" :to="{name: 'settings-hr-documents-edit-draft', params: {client: client.id, documentType: 'hr-custom-form', documentId: document.id} }">
                                        <i class="far fa-fw fa-pen"></i> Edit Draft
                                    </router-link>
                                </button-row>

                                <!-- This is an active document but we're looking at a draft version -->
                                <button-row v-else-if="documentVersion.is_draft && isFeatureEnabled" stacked class="pt-0">
                                    <button type="button" class="btn btn-outline-primary" @click.prevent="deleteDraftDocumentVersion()">
                                        <i class="far fa-fw fa-file-minus"></i>Delete Draft
                                    </button>
                                    <router-link class="btn btn-primary" :to="{name: 'settings-hr-documents-edit-draft-version', params: {client: client.id, documentType: 'hr-custom-form', documentId: document.id, documentVersionNumber: documentVersionNumber} }">
                                        <i class="far fa-fw fa-pen"></i> Edit Draft
                                    </router-link>
                                </button-row>

                                <!-- This is an active or inactive document, and we're looking at the current version -->
                                <button-row v-else-if="!document.is_draft && !documentVersion.is_draft && isFeatureEnabled" stacked class="pt-0">
                                    <button type="button" class="btn btn-outline-primary" @click.prevent="deactivateDocument()" v-if="document.is_active">
                                        <i class="far fa-fw fa-file-minus"></i>Deactivate
                                    </button>
                                    <button type="button" class="btn btn-outline-primary" @click.prevent="reactivateDocument()" v-if="!document.is_active">
                                        <i class="far fa-fw fa-file-plus"></i>Reactivate Document
                                    </button>
                                    <router-link class="btn btn-outline-primary btn-collapse" :to="{name: 'settings-hr-documents-duplicate', params: {client: client.id, documentType: 'hr-custom-form', documentId: document.id} }">
                                        <i class="far fa-fw fa-copy"></i><span class="btn-text">Copy</span>
                                    </router-link>
                                </button-row>
                            </div>
                        </slot>
                    </info-widget>

                    <!-- Company Doc Info Widget -->
                    <info-widget v-if="documentType == 'hr-doc'" :collapsible="false">
                        <template v-slot:title>Document Information</template>

                        <slot>
                            <div class="card-body py-1 mt-1">
                                <alert-box v-if="!isFeatureEnabled" type="info" class="mb-2">
                                    This document is uneditable because {{ document.company.name }} does not have the Company Documents module enabled. Employees will not be prompted to fill this document.
                                </alert-box>

                                <alert-box v-if="document.is_active && !document.is_draft && documentVersion.is_draft" type="info" class="mb-2">
                                    This is a draft version of an active document.
                                    <a href class="btn btn-link py-0" @click.prevent="changeDocumentVersion(document.current_version.version_number)"> Go to the active version <i class="fas fa-fw fa-chevron-right"></i></a>
                                </alert-box>
                                <alert-box v-if="documentVersionNumber && document.is_active && !document.is_draft && document.draft_version && !documentVersion.is_draft" type="info" class="mb-2">
                                    You have a draft version of this document in progress.
                                    <a href class="btn btn-link py-0" @click.prevent="changeDocumentVersion(document.draft_version.version_number)"> Go to the draft version <i class="fas fa-fw fa-chevron-right"></i></a>
                                </alert-box>

                                <div class="d-flex align-items-start">
                                    <div class="flex-fill overflow-auto">
                                        <div class="mui-textfield">
                                            <span class="textfield">{{ document.name }}</span>
                                    <label>Document Name</label>
                                        </div>
                                    </div>
                                    <div class="flex-0">
                                        <button v-if="showEditDocumentName" class="btn btn-outline-primary btn-collapse-xl ml-hf" @click.prevent="editDocumentName">
                                            <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
                                            <span class="btn-text">Edit</span>
                                        </button>
                                    </div>
                                </div>

                                <div class="mui-textfield">
                                    <span class="textfield">{{ documentTypeAsText }}</span>
                                    <label>Document Type</label>
                                </div>

                                <!-- This is a draft document -->
                                <button-row v-if="document.is_draft && isFeatureEnabled" stacked class="pt-0">
                                    <button type="button" class="btn btn-outline-primary" @click.prevent="deleteDraftDocument()">
                                        <i class="far fa-fw fa-file-minus"></i>Delete Draft
                                    </button>
                                    <router-link class="btn btn-primary" :to="{name: 'settings-hr-documents-edit-draft', params: {client: client.id, documentType: 'hr-doc', documentId: document.id} }">
                                        <i class="far fa-fw fa-pen"></i> Edit Draft
                                    </router-link>
                                </button-row>

                                <!-- This is an active document but we're looking at a draft version -->
                                <button-row v-else-if="documentVersion.is_draft && isFeatureEnabled" stacked class="pt-0">
                                    <button type="button" class="btn btn-outline-primary" @click.prevent="deleteDraftDocumentVersion()">
                                        <i class="far fa-fw fa-file-minus"></i>Delete Draft
                                    </button>
                                    <router-link class="btn btn-primary" :to="{name: 'settings-hr-documents-edit-draft-version', params: {client: client.id, documentType: 'hr-doc', documentId: document.id, documentVersionNumber: documentVersionNumber} }">
                                        <i class="far fa-fw fa-pen"></i> Edit Draft
                                    </router-link>
                                </button-row>

                                <!-- This is an active or inactive document, and we're looking at the current version -->
                                <button-row v-else-if="!document.is_draft && !documentVersion.is_draft && isFeatureEnabled" stacked class="pt-0">
                                    <button type="button" class="btn btn-outline-primary" @click.prevent="deactivateDocument()" v-if="document.is_active">
                                        <i class="far fa-fw fa-file-minus"></i>Deactivate
                                    </button>
                                    <button type="button" class="btn btn-outline-primary" @click.prevent="reactivateDocument()" v-if="!document.is_active">
                                        <i class="far fa-fw fa-file-plus"></i>Reactivate
                                    </button>
                                    <router-link class="btn btn-outline-primary btn-collapse" :to="{name: 'settings-hr-documents-duplicate', params: {client: client.id, documentType: 'hr-doc', documentId: document.id} }">
                                        <i class="far fa-fw fa-copy"></i><span class="btn-text">Copy</span>
                                    </router-link>
                                </button-row>
                            </div>
                        </slot>
                    </info-widget>

                    <!-- Fillable Form Info Widget -->
                    <info-widget v-if="documentType == 'hr-form-doc'" :collapsible="false">
                        <template v-slot:title>Document Information</template>

                        <slot>
                            <div class="card-body py-1 mt-1">
                                <alert-box v-if="!isFeatureEnabled" type="info" class="mb-2">
                                    The Fillable Forms module is not enabled. Employees will not be prompted to fill this document.
                                </alert-box>

                                <div class="d-flex align-items-start">
                                    <div class="flex-fill overflow-auto">
                                        <div class="mui-textfield">
                                            <span class="textfield">{{ document.name }}</span>
                                    <label>Document Name</label>
                                        </div>
                                    </div>
                                    <div class="flex-0">
                                        <button v-if="showEditDocumentName" class="btn btn-outline-primary btn-collapse-xl ml-hf" @click.prevent="editDocumentName">
                                            <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
                                            <span class="btn-text">Edit</span>
                                        </button>
                                    </div>
                                </div>
                                <div class="mui-textfield">
                                    <span class="textfield">{{ documentTypeAsText }}</span>
                                    <label>Document Type</label>
                                </div>
                                <div class="mui-textfield">
                                    <span class="textfield">
                                        <a href @click.prevent="setFormAsCurrentPDF(document)">
                                            <i class="far fa-fw fa-file-pdf mr-hf"></i>
                                            <span>{{ document.yaml_form.name }} v{{ document.yaml_form.version_number}}</span>
                                        </a>
                                    </span>
                                    <label>Form</label>
                                </div>

                                <button-row v-if="isFeatureEnabled" stacked class="pt-0">
                                    <button type="button" class="btn btn-outline-primary" @click.prevent="deactivateDocument()" v-if="document.is_active">
                                        Deactivate Document
                                    </button>
                                    <button type="button" class="btn btn-outline-primary" @click.prevent="reactivateDocument()" v-if="!document.is_active">
                                        Reactivate Document
                                    </button>
                                </button-row>
                            </div>
                        </slot>
                    </info-widget>

                    <!-- Uniforms Versions Widget -->
                    <info-widget v-if="documentType=='uniform'" :collapsible="false">
                        <template v-slot:title>Versions</template>

                        <template v-slot:headerbutton>
                            <router-link v-if="document.is_active && !document.draft_version && isFeatureEnabled" class="btn btn-outline-primary btn-collapse" :to="{name: 'settings-hr-documents-add-version', params: {client: client.id, documentType: 'uniform', documentId: document.id} }">
                                <i class="far fa-fw fa-plus"></i> <span class="btn-text">Create New Version</span>
                            </router-link>
                        </template>

                        <slot>
                            <div v-if="sortedVersions.length">
                                <section class="table-section">
                                    <table class="table css-table widget-table table-fixed">
                                        <thead>
                                            <tr>
                                                <th class="cw-toggle"></th>
                                                <th>Name</th>
                                                <th class="cw-6 text-right">Version</th>
                                            </tr>
                                        </thead>
                                        <tbody v-for="version in sortedVersions" :key="version.version_number">
                                            <tr>
                                                <td class="cw-toggle position-relative">
                                                    <a href class="widget-toggle-wrapper collapse-trigger" role="button" :class="{'is-expanded': shownVersionDetails[version.version_number]}" @click.prevent="toggleVersionDetails(version.version_number)">
                                                        <collapse-toggle-button />
                                                    </a>
                                                </td>
                                                <td class="truncate-wrapper">
                                                    <span>{{ document.name }}</span>
                                                </td>
                                                <td class="text-right">
                                                    <span>{{ version.version_number }}{{ version.is_draft ? ' (Draft)' : ''}}</span>
                                                </td>
                                            </tr>
                                            <tr class="child no-transition" v-show="shownVersionDetails[version.version_number]">
                                                <td colspan="3">
                                                    <div class="card card-body item-details">
                                                        <div v-if="version.version_number == document.major_version_number" class="mb-1">
                                                            <strong>Required Version</strong>
                                                        </div>
                                                        <div class="mb-1">
                                                            Created on {{ $filters.datetime(version.created_on) }}<br/>
                                                            <span v-if="version.created_by_pretty">by {{ version.created_by_pretty }}</span>
                                                        </div>
                                                        <div v-if="version.published_on" class="mb-1">
                                                            Made active version on {{ $filters.datetime(version.published_on) }}<br/>
                                                            <span v-if="version.published_by_pretty">by {{ version.published_by_pretty }}</span>
                                                        </div>
                                                        <div class="mb-2">
                                                            Manager Signature Required: {{ version.manager_signature_required ? 'Yes' : 'No'}}
                                                        </div>
                                                        <template v-if="version.item_data">
                                                            <div class="mui-textfield" v-for="(item, idx) in version.item_data" :key="idx">
                                                                <span class="textfield">
                                                                    <span>{{ item.name }}</span><br/>
                                                                    <span>{{ uniformItemOptionsAsText(item) }}</span>
                                                                </span>
                                                                <label>{{ `Clothing Item ${idx + 1}` }}</label>
                                                            </div>
                                                        </template>
                                                        <button v-if="(documentVersionNumber && version.version_number != documentVersionNumber) && (version.is_draft || version.is_current_version)" @click.prevent="changeDocumentVersion(version.version_number)" type="button" class="btn btn-outline-primary">
                                                            Go to this version
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </section>
                            </div>
                        </slot>
                    </info-widget>

                    <!-- Custom Form Versions Widget -->
                    <info-widget v-if="documentType=='hr-custom-form'" :collapsible="false">
                        <template v-slot:title>Versions</template>

                        <template v-slot:headerbutton>
                            <router-link v-if="document.is_active && !document.draft_version && isFeatureEnabled" class="btn btn-outline-primary btn-collapse" :to="{name: 'settings-hr-documents-add-version', params: {client: client.id, documentType: 'hr-custom-form', documentId: document.id} }">
                                <i class="far fa-fw fa-plus"></i> <span class="btn-text">Create New Version</span>
                            </router-link>
                        </template>

                        <slot>
                            <div v-if="sortedVersions.length">
                                <section class="table-section">
                                    <table class="table css-table widget-table table-fixed">
                                        <thead>
                                            <tr>
                                                <th class="cw-toggle"></th>
                                                <th>Name</th>
                                                <th class="cw-6 text-right">Version</th>
                                            </tr>
                                        </thead>
                                        <tbody v-for="version in sortedVersions" :key="version.version_number">
                                            <tr>
                                                <td class="cw-toggle position-relative">
                                                    <a href class="widget-toggle-wrapper collapse-trigger" role="button" :class="{'is-expanded': shownVersionDetails[version.version_number]}" @click.prevent="toggleVersionDetails(version.version_number)">
                                                        <collapse-toggle-button />
                                                    </a>
                                                </td>
                                                <td class="truncate-wrapper">
                                                    <a v-if="!document.is_draft" href @click.prevent="setPreviewCustomForm(document, version)" class="truncate-content">
                                                        <i class="far fa-fw fa-file-alt mr-hf"></i>
                                                        <span>{{ document.name }}</span>
                                                    </a>
                                                    <span v-else>
                                                        <i class="far fa-fw fa-file-alt"></i>
                                                        {{ document.name }}
                                                    </span>
                                                </td>
                                                <td class="text-right">
                                                    <span>{{ version.version_number }}{{ version.is_draft ? ' (Draft)' : ''}}</span>
                                                </td>
                                            </tr>
                                            <tr class="child no-transition" v-show="shownVersionDetails[version.version_number]">
                                                <td colspan="3">
                                                    <div class="card card-body item-details">
                                                        <div v-if="version.version_number == document.major_version_number" class="mb-1">
                                                            <strong>Required Version</strong>
                                                        </div>
                                                        <div class="mb-1">
                                                            Created on {{ $filters.datetime(version.created_on) }}<br/>
                                                            <span v-if="version.created_by_pretty">by {{ version.created_by_pretty }}</span>
                                                        </div>
                                                        <div v-if="version.published_on" class="mb-1">
                                                            Made active version on {{ $filters.datetime(version.published_on) }}<br/>
                                                            <span v-if="version.published_by_pretty">by {{ version.published_by_pretty }}</span>
                                                        </div>
                                                        <div class="mb-1">
                                                            Manager Signature Required: {{ version.manager_signature_required ? 'Yes' : 'No'}}
                                                        </div>
                                                        <button v-if="(documentVersionNumber && version.version_number != documentVersionNumber) && (version.is_draft || version.is_current_version)" @click.prevent="changeDocumentVersion(version.version_number)" type="button" class=" mt-1 btn btn-outline-primary">
                                                            Go to this version
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </section>
                            </div>
                        </slot>
                    </info-widget>

                    <!-- HR Doc Versions Widget -->
                    <info-widget v-if="documentType=='hr-doc'" :collapsible="false" id="hrDocVersionsWidget">
                        <template v-slot:title>Versions</template>

                        <template v-slot:headerbutton>
                            <router-link v-if="document.is_active && !document.draft_version && isFeatureEnabled" class="btn btn-outline-primary btn-collapse" :to="{name: 'settings-hr-documents-add-version', params: {client: client.id, documentType: 'hr-doc', documentId: document.id} }">
                                <i class="far fa-fw fa-plus"></i> <span class="btn-text">Create New Version</span>
                            </router-link>
                        </template>

                        <slot>
                            <div v-if="!sortedFiles.length">
                                <div class="info-widget-empty p-2">
                                    This HR Document has no versions.
                                </div>
                            </div>
                            <div v-if="sortedFiles.length">
                                <section class="table-section pt-1">
                                    <table class="table css-table widget-table table-fixed">
                                        <thead>
                                            <tr>
                                                <th class="cw-toggle"></th>
                                                <th>Filename</th>
                                                <th class="cw-6 text-right">Version</th>
                                            </tr>
                                        </thead>
                                        <tbody v-for="file in sortedFiles" :key="file.id">
                                            <tr>
                                                <td class="cw-toggle position-relative">
                                                    <a href class="widget-toggle-wrapper collapse-trigger" role="button" :class="{'is-expanded': shownFileDetails[file.id]}" @click.prevent="toggleFileDetails(file.id)">
                                                        <collapse-toggle-button />
                                                    </a>
                                                </td>
                                                <td class="truncate-wrapper">
                                                    <a href @click.prevent="setCurrentPDF(file)" class="truncate-content">
                                                        <i class="far fa-fw fa-file-pdf mr-hf"></i>
                                                        <span>{{ file.filename }}</span>
                                                    </a>
                                                </td>
                                                <td class="text-right">
                                                    <span>{{ file.version_number }}{{ file.is_draft ? ' (Draft)' : ''}}</span>
                                                </td>
                                            </tr>
                                            <tr class="child no-transition" v-show="shownFileDetails[file.id]">
                                                <td colspan="3">
                                                    <div class="card card-body item-details">
                                                        <div v-if="file.version_number == document.major_version_number" class="mb-1">
                                                            <strong>Required Version</strong>
                                                        </div>
                                                        <div class="mb-1">
                                                            Created on {{ $filters.datetime(file.created_on) }}<br/>
                                                            <span v-if="file.created_by_pretty">by {{ file.created_by_pretty }}</span>
                                                        </div>
                                                        <div v-if="file.published_on" class="mb-1">
                                                            Made active version on {{ $filters.datetime(file.published_on) }}<br/>
                                                            <span v-if="file.published_by_pretty">by {{ file.published_by_pretty }}</span>
                                                        </div>
                                                        <div class="mb-1">
                                                            Employee Signature Required: {{ file.employee_signature_required ? "Yes" : "No"}}<br/>
                                                            Manager Signature Required: {{ file.manager_signature_required ? 'Yes' : 'No'}}
                                                        </div>
                                                        <button v-if="(documentVersionNumber && file.version_number != documentVersionNumber) && (file.is_draft || file.is_current_version)" @click.prevent="changeDocumentVersion(file.version_number)" type="button" class="btn btn-outline-primary mt-2">
                                                            Go to this version
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </section>
                            </div>
                        </slot>
                    </info-widget>

                    <div id="requirementWidgets">
                    <info-widget :collapsible="false" id="positionsRequiredWidget">
                        <template v-slot:title>Positions Required</template>

                        <template v-slot:headerbutton>
                            <button v-if="document.is_active && !documentVersion.is_draft && isFeatureEnabled" type="button" class="btn btn-outline-primary" @click.prevent="editPositions">
                                <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
                                Edit
                            </button>
                        </template>

                        <slot>
                            <div class="p-1">
                                <div v-if="document.positions && document.positions.length">
                                   <table class="table css-table widget-table table-fixed">
                                        <thead>
                                            <tr>
                                                <th>Position</th>
                                            </tr>
                                        </thead>
                                        <tbody v-for="position in document.positions" :key="position.id">
                                            <tr>
                                                <td class="truncate-wrapper">
                                                    <span>{{ position.terminal_value }}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                   </table>
                                </div>
                                <div class="info-widget-empty p-1" v-else>
                                    This document is not required for any positions.
                                </div>
                            </div>
                        </slot>
                    </info-widget>

                    <info-widget :collapsible="false" v-if="showEnhancedDocumentSettings" id="individualsRequiredWidget">
                        <template v-slot:title>Individuals Required</template>

                        <slot>
                            <div class="p-1">
                                <div v-if="document.positions && document.individual_document_requirements.length">
                                   <table class="table css-table widget-table table-fixed">
                                        <thead>
                                            <tr>
                                                <th>Employee</th>
                                            </tr>
                                        </thead>
                                        <tbody v-for="req in document.individual_document_requirements" :key="req.id">
                                            <tr>
                                                <td class="truncate-wrapper">
                                                    <span>{{ req.employee.full_name }}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                   </table>
                                </div>
                                <div class="info-widget-empty p-1" v-else>
                                    This document is not required individually for any employees.
                                </div>
                            </div>
                        </slot>
                    </info-widget>
                    </div>

                    <info-widget :collapsible="false" v-if="showEnhancedDocumentSettings" id="collectionSettingsWidget">
                        <template v-slot:title>Collection Settings</template>

                        <template v-slot:headerbutton>
                            <button v-if="document.is_active && !documentVersion.is_draft && isFeatureEnabled" type="button" class="btn btn-outline-primary" @click.prevent="editCollectionSettings">
                                <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
                                Edit
                            </button>
                        </template>

                        <slot>
                            <div class="p-2">
                                <div v-if="document.is_draft" class="info-widget-empty">
                                    Collection Settings have not yet been saved.
                                </div>
                                <div v-else>
                                    <template v-if="document.include_in_onboarding !== null">
                                        <strong>For new employees</strong><br/>
                                        <em v-if="document.collection_settings_changed_on">added after {{ $filters.datetime(document.collection_settings_changed_on) }}</em>
                                        <em v-else-if="document.published_on">added after {{ $filters.datetime(document.published_on) }}</em>
                                        <em v-else>added after {{ $filters.datetime(document.created_on) }}</em>
                                        <br/>
                                        <span v-if="document.include_in_onboarding">
                                            <i class="far fa-user mr-hf"></i>Employees will complete this document
                                            <br/>
                                            <i class="far fa-stop-circle mr-hf"></i>Block access to paystubs, W-2s, etc. until completed
                                        </span>
                                        <span v-else>
                                            <i class="far fa-user-shield mr-hf"></i>Managers will complete or upload this document
                                        </span>
                                        <br/><br/>
                                    </template>
                                    <template v-if="document.major_version_number > 0">
                                        <strong>For {{ document.include_in_onboarding === null ? 'required' : 'active' }} employees</strong><br/>
                                        <template v-if="document.include_in_onboarding !== null">
                                            <em v-if="document.collection_settings_changed_on">added before {{ $filters.datetime(document.collection_settings_changed_on) }}</em>
                                            <em v-else>added before {{ $filters.datetime(document.created_on) }}</em>
                                            <br/>
                                        </template>
                                        <span v-if="document.collection_method == 'employee'">
                                            <i class="far fa-user mr-hf"></i>Employees will complete this document
                                            <br/>
                                            <span v-if="document.block_ee_until_completion">
                                                <i class="far fa-stop-circle mr-hf"></i>Block access to paystubs, W-2s, etc. until completed
                                            </span>
                                            <span v-else>
                                                <i class="far fa-play-circle mr-hf"></i>Employees are able to access paystubs, W-2s, etc. before completing
                                            </span>
                                        </span>
                                        <span v-else-if="document.collection_method == 'manager'">
                                            <i class="far fa-user-shield mr-hf"></i>Managers will complete or upload this document
                                        </span>
                                        <span v-else-if="document.collection_method == 'do-not-collect'">
                                            <i class="far fa-file-minus mr-hf"></i>This document will not be collected
                                        </span>
                                    </template>
                                    <template v-else-if="document.major_version_number == 0">
                                        <strong>For active employees</strong><br/>
                                        <span class="info-widget-empty">
                                            Collection Settings have not yet been configured for active employees.<br/>
                                            Click <strong>Edit</strong> to configure and start collecting this document for active employees.
                                        </span>
                                    </template>
                                </div>
                            </div>
                        </slot>
                    </info-widget>

                    <info-widget :collapsible="false" v-if="showEnhancedDocumentSettings && document.is_expiring_doc">
                        <template v-slot:title>Document Expiration</template>

                        <template v-slot:headerbutton>
                            <button v-if="document.is_active && !documentVersion.is_draft && isFeatureEnabled" type="button" class="btn btn-outline-primary" @click.prevent="editExpirationSettings">
                                <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
                                Edit
                            </button>
                        </template>

                        <slot>
                            <div class="p-2">
                                <p class="mb-1"><strong>This is an expiring document.</strong></p>
                                <p class="mb-0">Begin reminders {{ document.remind_days_before_expiration }} days before expiration.</p>
                            </div>
                        </slot>
                    </info-widget>

                    <info-widget :collapsible="false" v-if="showEnhancedDocumentSettings && documentType !== 'uniform'" id="terminationSettingsWidget">
                        <template v-slot:title>Termination Settings</template>

                        <template v-slot:headerbutton>
                            <button v-if="document.is_active && !documentVersion.is_draft && isFeatureEnabled" type="button" class="btn btn-outline-primary" @click.prevent="editTerminationSettings">
                                <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
                                Edit
                            </button>
                        </template>

                        <slot>
                            <div class="p-2">
                                <div v-if="document.is_draft" class="info-widget-empty">
                                    Termination Settings have not yet been saved.
                                </div>
                                <div v-else-if="document.major_version_number == 0" class="info-widget-empty">
                                    Termination Settings have not yet been configured.
                                </div>
                                <div v-else>
                                    <strong>For terminated employees who have <em>not</em> completed this document</strong><br/>
                                    <em v-if="document.termination_settings_changed_on">terminated after {{ $filters.datetime(document.termination_settings_changed_on) }}</em>
                                    <em v-else-if="document.published_on">terminated after {{ $filters.datetime(document.published_on) }}</em>
                                    <em v-else>terminated after {{ $filters.datetime(document.created_on) }}</em>
                                    <br/>
                                    <span v-if="!document.remove_requirement_on_termination">
                                        <i class="far fa-file-check mr-hf"></i>Document is still required for an employee after they are terminated<br/>
                                    </span>
                                    <span v-else>
                                        <i class="far fa-file-minus mr-hf"></i>Document is canceled and no longer required for an employee after they are terminated
                                    </span>
                                </div>
                            </div>
                        </slot>
                    </info-widget>

                </div>
                <!-- Tab Pane 2 -->
                <div class="tab-pane" :class="{'show active': activeTab == 'changelogs'}" id="tabContentHistory1" role="tabpanel" aria-labelledby="tabLinkHistory1">
                    <change-history :client="client" :changelogs="document.history_logs" :active-changelog-id="activeChangelogId"/>
                </div>
            </div>

            <edit-document-name-modal v-if="document" v-show="editingName" ref="editNameModal" :document="document" :documentType="documentType" :client="client" @updated="onUpdate" @close="editingName = false"/>

            <edit-document-positions-modal v-if="document" v-show="editingPositions" ref="editPositionsModal" :document="document" :documentType="documentType" :documentHasDraft="documentHasDraft" :client="client" @updated="onUpdate" @close="editingPositions = false"/>

            <edit-document-collection-settings-modal v-if="editingCollectionSettings" ref="editCollectionSettingsModal" :document="document" :documentType="documentType" :documentHasDraft="documentHasDraft" :client="client" @updated="onCollectionSettingsUpdate" @close="editingCollectionSettings = false"/>

            <edit-document-termination-settings-modal v-if="editingTerminationSettings" ref="editTerminationSettingsModal" :document="document" :documentType="documentType" :documentHasDraft="documentHasDraft" :client="client" @updated="onUpdate" @close="editingTerminationSettings = false"/>

            <edit-document-expiration-settings-modal v-if="editingExpirationSettings" ref="editExpirationSettingsModal" :document="document" :documentType="documentType" :documentHasDraft="documentHasDraft" :client="client" @updated="onUpdate" @close="editingExpirationSettings = false"/>

            <pdf-modal :url="currentPDF.url" :title="currentPDF.title" v-if="currentPDF" @close="setCurrentPDF(null)" ref="pdfModal" />

            <custom-form-preview-modal v-if="previewCustomForm" ref="customFormPreviewModal" :doc-name="previewCustomForm.docName" :company-name="previewCustomForm.companyName" :questions="previewCustomForm.questions" :client="client" :hide-preview-submit="true" @close="previewCustomForm = null"/>
        </slot>
    </sidebar>
</template>

<script>
import Sidebar from '@/components/Sidebar'
import InfoWidget from '@/components/InfoWidget'
import ButtonRow from '@/components/ButtonRow'
import ChangeHistory from '@/components/ChangeHistory'
import EditDocumentPositionsModal from './EditDocumentPositionsModal'
import EditDocumentCollectionSettingsModal from './EditDocumentCollectionSettingsModal'
import EditDocumentTerminationSettingsModal from './EditDocumentTerminationSettingsModal'
import EditDocumentExpirationSettingsModal from './EditDocumentExpirationSettingsModal'
import EditDocumentNameModal from './EditDocumentNameModal'
import CustomFormPreviewModal from './CustomFormPreviewModal'
import PdfModal from '@/components/PdfModal'
import exampleDocument from '@/components/Tours/example-hr-document-sidebar.json'

export default {
    components: {Sidebar, InfoWidget, ButtonRow, ChangeHistory, EditDocumentPositionsModal, EditDocumentCollectionSettingsModal, EditDocumentTerminationSettingsModal, EditDocumentExpirationSettingsModal, EditDocumentNameModal, PdfModal, CustomFormPreviewModal},
    props: ['documentId', 'documentType', 'documentVersionNumber', 'client', 'tab', 'section', 'itemId', ],
    computed: {
        state() {return this.$store.state},
        sortedFiles() {
            if (!this.document || !this.documentType == 'hr-doc') {return []}
            return this.sortFiles()
        },
        sortedVersions() {
            if (!this.document || this.documentType == 'hr-doc') {return []}
            return this.sortVersions()
        },
        documentTypeAsText() { return {'hr-doc': 'Company Document', 'hr-form-doc': 'Fillable Form', 'uniform': 'Uniform Form', 'hr-custom-form': 'Custom Form'}[this.documentType] },
        documentTypeAndId() { return this.documentType + this.documentId },
        documentVersion() {
            if (this.document) {
                let versions = this.document.versions || this.document.files
                if (versions && versions.length)  {
                    if (this.documentVersionNumber) {
                        return versions.find(v => v.version_number == this.documentVersionNumber)
                    } else {
                        // return the current version by default
                        return versions.find(v => v.version_number == this.document.version_number)
                    }
                }
            }
            return {}
        },
        documentHasDraft() {
            if (this.document) {
                let versions = this.document.versions || this.document.files
                if (versions && versions.length) {
                    return Boolean(versions.find(v => v.is_draft))
                }
            }
        },
        companyAsText() {
            let text = ''
            if (this.document) {
                text = this.document.company.name
                if (this.document.is_draft && this.document.batch_company_ids.length > 1) {
                    text += ` + ${this.document.batch_company_ids.length - 1} more`
                }
            }
            return text
        },
        isFeatureEnabled() {
            if (this.document) {
                if (this.documentType == 'hr-doc') {
                    // NB: let them use company docs if they have the company docs module, or a limited version of the module if they have paperless onboarding
                    return (this.document.company.has_company_docs_module && this.client.has_company_docs_module) || this.client.has_paperless_onboarding
                } else if (this.documentType == 'uniform') {
                    return this.document.company.has_uniform_module && this.client.has_uniform_module
                } else if (this.documentType == 'hr-form-doc') {
                    return this.client.has_fillable_forms
                }  else if (this.documentType == 'hr-custom-form') {
                    return this.client.has_custom_forms_module
                } else {
                    return false
                }
            }
        },
        showEnhancedDocumentSettings() {
            // do we show collection settings, termination settings, document expiration, and individuals required widgets?
            if (this.documentType == 'hr-form-doc') {
                // fillable forms don't have enhanced settings implemented
                return false
            } else if (this.documentType == 'hr-doc') {
                // only show enhanced settings for company docs if they have the company docs module (as opposed to only the onboarding module)
                return (this.document.company.has_company_docs_module && this.client.has_company_docs_module)
            }
            // otherwise show all the enhanced settings, so long as the relevant document module is enabled
            return this.isFeatureEnabled
        },
        showEditDocumentName() {
            return this.isFeatureEnabled && this.document.is_active && !this.documentVersion.is_draft
        },
    },
    data() {
        return {
            document: null,
            shownFileDetails: this.makeShownFileDetails(),
            shownVersionDetails: this.makeShownVersionDetails(),
            currentPDF: null,
            previewCustomForm: null,
            activeTab: this.tab,
            activeChangelogId: (this.tab == 'changelogs') ? this.itemId : null,
            editingPositions: false,
            editingName: false,
            editingCollectionSettings: false,
            editingTerminationSettings: false,
            editingExpirationSettings: false,
        }
    },
    watch: {
        'documentTypeAndId'() {
            this.getDocument()
        },
        documentVersionNumber() {
            this.shownVersionDetails = this.makeShownVersionDetails()
        }
    },
    mounted() {
        this.getDocument()
    },
    methods: {
        getDocument() {
            if (this.$store.state.activeTour == 'hr-suite') {
                this.getExampleDocument()
                return
            }
            let promise
            if (this.documentType == 'hr-doc') {
                promise = this.$api.get(`/clients/${this.client.id}/hr-documents/${this.documentId}`)
            } else if (this.documentType == 'hr-form-doc') {
                promise = this.$api.get(`/clients/${this.client.id}/hr-form-documents/${this.documentId}`)
            } else if (this.documentType == 'uniform') {
                promise = this.$api.get(`/clients/${this.client.id}/uniforms/${this.documentId}`)
            } else if (this.documentType == 'hr-custom-form') {
                promise = this.$api.get(`/clients/${this.client.id}/hr-custom-forms/${this.documentId}`)
            }

            this.$store.dispatch('START_LOADING')
            promise.then(resp => {
                this.document = resp
                this.shownFileDetails = this.makeShownFileDetails()
                this.shownVersionDetails = this.makeShownVersionDetails()
                this.$store.dispatch('STOP_LOADING')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        getExampleDocument() {
            this.document = exampleDocument
            this.shownFileDetails = this.makeShownFileDetails()
            this.shownVersionDetails = this.makeShownVersionDetails()
        },
        close() {
            this.$emit('close')
        },
        toggleFileDetails(fileId) {
            this.shownFileDetails[fileId] = !this.shownFileDetails[fileId]
        },
        toggleVersionDetails(versionNumber) {
            this.shownVersionDetails[versionNumber] = !this.shownVersionDetails[versionNumber]
        },
        reactivateDocument() {
            if (!confirm('Are you sure you want to reactivate this document?')) {return}

            let promise
            if (this.documentType == 'hr-doc') {
                promise = this.$api.post(`/clients/${this.client.id}/hr-documents/${this.document.id}/status`, {status: 'active'})
            } else if (this.documentType == 'hr-form-doc') {
                promise = this.$api.post(`/clients/${this.client.id}/hr-form-documents/${this.document.id}/status`, {status: 'active'})
            } else if (this.documentType == 'uniform') {
                promise = this.$api.post(`/clients/${this.client.id}/uniforms/${this.document.id}/status`, {status: 'active'})
            } else if (this.documentType == 'hr-custom-form') {
                promise = this.$api.post(`/clients/${this.client.id}/hr-custom-forms/${this.document.id}/status`, {status: 'active'})
            }

            this.$store.dispatch('START_LOADING')
            promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.onUpdate(resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        deactivateDocument() {
            if (!confirm('Are you sure you want to deactivate this document?')) {return}

            let promise
            if (this.documentType == 'hr-doc') {
                promise = this.$api.post(`/clients/${this.client.id}/hr-documents/${this.document.id}/status`, {status: 'inactive'})
            } else if (this.documentType == 'hr-form-doc') {
                promise = this.$api.post(`/clients/${this.client.id}/hr-form-documents/${this.document.id}/status`, {status: 'inactive'})
            } else if (this.documentType == 'uniform') {
                promise = this.$api.post(`/clients/${this.client.id}/uniforms/${this.document.id}/status`, {status: 'inactive'})
            } else if (this.documentType == 'hr-custom-form') {
                promise = this.$api.post(`/clients/${this.client.id}/hr-custom-forms/${this.document.id}/status`, {status: 'inactive'})
            }

            this.$store.dispatch('START_LOADING')
            promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.onUpdate(resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        deleteDraftDocument() {
            if (!confirm('Are you sure you want to delete this draft document?')) {return}

            let promise
            if (this.documentType == 'hr-doc') {
                promise = this.$api.del(`/clients/${this.client.id}/hr-documents/${this.document.id}`)
            } else if (this.documentType == 'uniform') {
                promise = this.$api.del(`/clients/${this.client.id}/uniforms/${this.document.id}`)
            } else if (this.documentType == 'hr-custom-form') {
                promise = this.$api.del(`/clients/${this.client.id}/hr-custom-forms/${this.documentId}`)
            }

            this.$store.dispatch('START_LOADING')
            promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.onUpdate()
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        deleteDraftDocumentVersion() {
            if (!confirm('Are you sure you want to delete this draft version?')) {return}

            let promise
            if (this.documentType == 'hr-doc') {
                promise = this.$api.del(`/clients/${this.client.id}/hr-documents/${this.document.id}/file/${this.documentVersion.id}`)
            } else if (this.documentType == 'uniform') {
                promise = this.$api.del(`/clients/${this.client.id}/uniforms/${this.document.id}/version/${this.documentVersion.id}`)
            } else if (this.documentType == 'hr-custom-form') {
                promise = this.$api.del(`/clients/${this.client.id}/hr-custom-forms/${this.document.id}/version/${this.documentVersion.id}`)
            }

            this.$store.dispatch('START_LOADING')
            promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.onUpdate()
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        editDocumentName() {
            this.editingName = true
            this.$refs.editNameModal.open()
        },
        editPositions() {
            this.editingPositions = true
            this.$refs.editPositionsModal.open()
        },
        editTerminationSettings() {
            this.editingTerminationSettings = true
        },
        editCollectionSettings() {
            this.editingCollectionSettings = true
        },
        editExpirationSettings() {
            this.editingExpirationSettings = true
        },
        setCurrentPDF(file) {
            if (!file) {
                this.currentPDF = null
                return
            }

            this.currentPDF = {
                title: file.filename,
                url: {
                    url: `${this.$api.API_BASE}/clients/${this.client.id}/hr-documents/${file.hr_document_id}/file/pdf?file_id=${file.id}`,
                    withCredentials: true,
                }
            }
        },
        setFormAsCurrentPDF(formDoc) {
            if (!formDoc) {
                this.currentPDF = null
                return
            }

            this.currentPDF = {
                title: formDoc.yaml_form.name,
                url: {
                    url: `${this.$api.API_BASE}/clients/${this.client.id}/hr-form-documents/${formDoc.id}/file/pdf`,
                    withCredentials: true,
                }
            }
        },
        setPreviewCustomForm(doc, version) {
            if (!doc || !version) {
                this.previewCustomForm = null
                return
            }

            this.previewCustomForm = {
                docName: `${doc.name} (Version ${version.version_number}${version.is_draft ? ' Draft' : ''}) `,
                companyName: doc.company.name,
                questions: version.questions
            }
        },
        sortFiles() {
            if (!this.document || !this.document.files) {return []}
            const files = this.document.files.slice()  // Make a copy of the array
            files.sort((a, b) => {
                return a.version > b.version ? -1 : 1
            })

            return files
        },
        sortVersions() {
            if (!this.document || !this.document.versions) {return []}
            const versions = this.document.versions.slice()  // Make a copy of the array
            versions.sort((a, b) => {
                return a.version_number > b.version_number ? -1 : 1
            })

            return versions
        },
        makeShownFileDetails() {
            const files = this.sortFiles()
            const shownFileDetails = {}
            files.forEach((f, idx) => {
                shownFileDetails[f.id] = (idx == 0)
            })
            return shownFileDetails
        },
        makeShownVersionDetails() {
            let shownVersionDetails = {}
            if (this.document && this.document.versions) {
                this.document.versions.forEach(v => {
                    shownVersionDetails[v.version_number] = (v.version_number == this.documentVersionNumber)
                })
            }
            return shownVersionDetails
        },
        onCollectionSettingsUpdate(document) {
            // catch the case where we just edited collection settings and bumped doc major version to 1
            // prompt user to set termination settings at that time
            if (this.document.major_version_number == 0 && document.major_version_number == 1) {
                this.editTerminationSettings()
            }
            this.onUpdate(document)
        },
        onUpdate(document) {
            if (document) {
                Object.assign(this.document, document)
            }
            this.$emit('updated')
        },
        changeDocumentVersion(documentVersionNumber) {
            this.$emit('change_version', documentVersionNumber)
        },
        uniformItemOptionsAsText(item) {
            let itemOptionsAsTextArr = []
            for (let category of item.categories) {
                let colorNames = []
                for (let c of category.colors) {
                    colorNames.push(c.name)
                }
                let sizeNames = []
                for (let s of category.sizes) {
                    sizeNames.push(s.name)
                }
                let sizesAsText = ""
                if (sizeNames.length > 1) {
                    sizesAsText = sizeNames[0] + "-" + sizeNames[sizeNames.length-1]
                } else if (sizeNames.length == 1) {
                    sizesAsText = sizeNames[0]
                } else {
                    sizesAsText = "No sizes defined"
                }
                itemOptionsAsTextArr.push(`${category.name}: ${colorNames.join('/')} ${sizesAsText}`)
            }
            return itemOptionsAsTextArr.join(', ')
        },
    },
}
</script>

