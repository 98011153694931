<template>
    <div class="feature-marketing-page">
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">

                <div class="brand-banner">
                    <div class="container-fluid">
                        <div class="container-centered-l py-4 text-center">
                            <h1>HR Documents Compliance Suite</h1>
                            <p class="mt-2 mb-0">Get your critical documents completed & signed by the right people with this powerful set of tools.</p>
                        </div>
                    </div>
                </div>

                <div class="container-fluid section-blue">
                    <div class="container-centered-l py-4">
                        <div class="row">
                            <div class="col-md-5 col-sm-12 mb-3">
                                <video width="100%" ref="complianceVideo" controls>
                                    <source src="https://getmypaystub-public.s3.us-east-1.amazonaws.com/promo/promo-hr-compliance-2.mp4" type="video/mp4">
                                    Your browser does not support the video tag.
                                </video>
                                <i class="text-small">
                                    Watch our intro video to see how the HR Documents Compliance Suite can help you.
                                </i>
                            </div>
                            <div class="col-md-7 col-sm-12">
                                <p><strong>Our mobile-optimized HR Compliance Suite simplifies your paperwork.</strong></p>
                                <p>Send documents to employees or groups, and they sign them right on their phone. You can see in real time what’s been done and what still requires attention.</p>
                                <p>Rest assured that you’re in compliance, and get back to growing your business.</p>
                                <a href="mailto:sales@paymastersinc.com" @click="trackContact" class="btn btn-primary">Get Started Now</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-fluid">
                    <div class="container-centered-l pt-4 pb-1">
                        <alert-box type="info" class="mb-2" v-if="hasHRSuite">
                            The <strong>HR Compliance Suite</strong> feature is currently enabled at {{ hrSuiteCompanies.length }} of your {{ client.companies.length }} companies:
                            <ul class="mb-0">
                                <li v-for="c in shownHRSuiteCompanies" :key="c.id">{{ c.name }}</li>
                            </ul>
                            <a v-if="hrSuiteCompanies.length > 3" href @click.prevent="seeMoreCompanies = !seeMoreCompanies" class="btn btn-link">{{ seeMoreCompanies ? "See Less" : "See More" }}</a>
                        </alert-box>
                        <div class="row">
                            <div class="col-md-9 col-sm-12">
                                <p>Our <strong>Standard</strong> plan gives you all the basics for collecting and tracking company policy documents.</p>
                                <p>Upgrade to <strong>Premium</strong> to get access to our specialized document types – Custom Forms and Uniform Forms.</p>
                            </div>
                            <div class="col-md-3 col-sm-12">
                                <a class="btn btn-outline-primary" href @click.prevent="scrollToRef('pricingSection')">See Plans</a>
                            </div>
                        </div>
                    </div>
                </div>

                <hr/>

                <div class="container-fluid">
                    <div class="container-centered-l pt-4 pb-1">
                        <div class="row pb-4">
                            <div class="col-md-6 col-sm-12">
                                <img src="@/assets/img/features/doc-insights.png" alt="Document Insights Widget" class="w-100 p-1"/>
                            </div>
                            <div class="col-md-6 col-sm-12 d-flex flex-column justify-content-center">
                                <h3 class="text-primary mb-2 mt-2">See your compliance at a glance</h3>
                                <p>Don’t just send out documents to employees – use our <strong>one-of-a-kind visual insights</strong> to track completion, analyze compliance, and <strong>take action immediately. </strong></p>
                            </div>
                        </div>

                        <div class="row pb-4 flex-md-row-reverse">
                            <div class="col-md-6 col-sm-12">
                                <img src="@/assets/img/features/hr-docs-table.png" alt="HR Documents table" class="w-100 p-1"/>
                            </div>
                            <div class="col-md-6 col-sm-12 d-flex flex-column justify-content-center">
                                <h3 class="text-primary mb-2 mt-2">Manage & assign documents strategically</h3>
                                <p>
                                    Our new HR Documents page gives you <strong>access to the compliance data you need to know, fast.</strong> Use the Incomplete Documents tab to see <strong>where document completion is getting stalled</strong>, and strategically manage your employees accordingly.
                                </p>
                            </div>
                        </div>

                        <div class="row pb-4 mb-2">
                            <div class="col-md-6 col-sm-12">
                                <img src="@/assets/img/features/doc-match.png" alt="Document Match Tool" class="w-100 p-1"/>
                            </div>
                            <div class="col-md-6 col-sm-12 d-flex flex-column justify-content-center">
                                <h3 class="text-primary mb-2 mt-2">Document matching makes organizing a snap</h3>
                                <p>Add in documents completed offline quickly and easily with our <strong>smart document match feature</strong>, which can identify an uploaded document as a document already in your Library.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <hr/>

                <div class="container-fluid">
                    <div class="container-centered-l pt-4 pb-1">
                        <h1 class="text-teal2 text-center mb-4">4 Versatile Document Types to Handle Any Situation</h1>
                        <div class="row">
                            <div class="col-md-6 col-sm-12">
                                <div class="doc-module-square d-flex">
                                    <div class="col-9 pr-0">
                                        <span class="standard-pill">Standard</span>
                                        <h2 class="text-primary pt-1">Company Documents</h2>
                                        <h4>Get policies signed—fast.</h4>
                                        <ul class="pl-2">
                                            <li>Company handbooks</li>
                                            <li>Updates to company policies</li>
                                            <li>ACA health exchange notification for employees</li>
                                            <li>and more!</li>
                                        </ul>
                                    </div>
                                    <div class="doc-module-square-icon col-3 d-flex justify-content-end pr-0">
                                        <i class="fas fa-file-signature mr-1"/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <div class="doc-module-square d-flex">
                                    <div class="col-9 pr-0">
                                        <span class="standard-pill">Standard</span>
                                        <h2 class="text-primary pt-1">On-Demand Documents</h2>
                                        <h4>Tackle unique problems with employee-specific documents.</h4>
                                        <ul class="pl-2">
                                            <li>Incident reports</li>
                                            <li>Employee write-ups</li>
                                            <li>Employee loan agreements</li>
                                            <li>and more!</li>
                                        </ul>
                                    </div>
                                    <div class="doc-module-square-icon col-3 d-flex justify-content-end pr-0">
                                        <i class="fas fa-user mr-1"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 col-sm-12">
                                <div class="doc-module-square d-flex">
                                    <div class="col-9 pr-0">
                                        <span class="premium-pill"><i class="fas fa-star mr-1"/>Premium</span>
                                        <h2 class="text-primary pt-1">Custom Forms</h2>
                                        <h4>Collect any information you need.</h4>
                                        <ul class="pl-2">
                                            <li>Schedule or shift availability</li>
                                            <li>Driver information </li>
                                            <li>Car insurance</li>
                                            <li>Food safety certifications</li>
                                            <li>and more!</li>
                                        </ul>
                                    </div>
                                    <div class="doc-module-square-icon col-3 d-flex justify-content-end pr-0">
                                        <i class="fas fa-file-edit mr-1"/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <div class="doc-module-square d-flex">
                                    <div class="col-9 pr-0">
                                        <span class="premium-pill"><i class="fas fa-star mr-1"/>Premium</span>
                                        <h2 class="text-primary pt-1">Uniform Forms</h2>
                                        <h4>Get the right size and style for every employee.</h4>
                                        <ul class="pl-2">
                                            <li>Send different forms to different positions</li>
                                            <li>Customizable for sizes, style, and colors</li>
                                            <li>Hats, shirts, shoes, and more</li>
                                        </ul>
                                    </div>
                                    <div class="doc-module-square-icon col-3 d-flex justify-content-end pr-0">
                                        <i class="fas fa-hard-hat mr-1"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-fluid section-blue">
                    <div class="container-centered-l py-4">
                        <h1 class="text-center pt-2 pb-2">Extends the Power of Onboarding</h1>
                        <div class="row pl-2 pr-2">
                            <div class="col-sm-12 pb-1">
                                <p>GetMyPayStub’s Onboarding module enables you to set up new employees with all the right documents and tax forms quickly and automatically. </p>
                                <p>The HR Compliance Suite extends those capabilities to allow you to track document completion for ALL employees, giving you a complete picture of exactly who has signed what, beyond the initial hiring process.</p>
                                <p>Both features use the same central HR Document Library, enabling you to use a single set of powerful tools to manage all your employees and their documents. </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="brand-banner">
                    <div class="container-fluid">
                        <div class="container-centered-l py-4">
                            <div class="row pl-2 pr-2">
                                <div class="col-sm-12 col-md-8 text-center pt-1 mb-1">
                                    <p class="mb-0">Ready to take your HR Documents to the next level?</p>
                                </div>
                                <div class="col-sm-12 col-md-4 text-center">
                                    <a href="mailto:sales@paymastersinc.com" @click="trackContact" class="btn btn-outline-primary">Contact your Payroll Specialist</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-fluid">
                    <div class="container-centered-l pt-4 pb-4">
                        <h1 class="text-teal2 text-center pt-2">Explore the Suite</h1>
                    </div>
                </div>

                <div class="container-fluid section-grey">
                    <div class="container-centered-l pt-4 pb-1">
                        <h2 class="mb-4">Company Documents</h2>
                        <div class="row feature-row-a">
                            <div class="col-sm-12 col-md-4 d-flex mb-1">
                                <i class="fal fa-fw fa-clipboard-check mr-1 text-primary-dark explore-icon"/>
                                <span class="text-primary-dark">Achieve and maintain 100% compliance, even when documents change</span>
                            </div>
                            <div class="col-sm-12 col-md-4 d-flex mb-1">
                                <i class="fal fa-fw fa-books mr-1 text-primary-dark explore-icon"/>
                                <p class="text-primary-dark">Organize policies by documents and by position</p>
                            </div>
                            <div class="col-sm-12 col-md-4 d-flex mb-1">
                                <i class="fal fa-fw fa-file-signature mr-1 text-primary-dark explore-icon"/>
                                <p class="text-primary-dark">Flexibility to handle exceptions and special cases</p>
                            </div>
                        </div>
                        <div class="row pb-4 mt-4">
                            <div class="col-md-7 col-sm-12 d-flex flex-column justify-content-center pb-4">
                                <h3 class="pb-1">Get policies signed – fast.</h3>
                                <p>Upload important company documents to your HR Documents Library, assign the documents by employee position,  and let the system handle the rest. New and existing employees will digitally sign the correct documents in their self-service portal and you can track the progress in real time on your dashboard.</p>
                                <h3 class="mt-2 pb-1">Case Study: How do you share new policies with your employees?</h3>
                                <p>How do you ensure all of your employees view and sign off on a new policy, such as COVID protocols or social media policies? With Company Documents, you can upload the new policy to your HR Documents Library and send it out to all employees for their signatures in record time. Then, sit back, relax, and watch as the signatures roll in through the compliance tracker on your dashboard.</p>
                                <p>What do you do when you update your handbook? How do you get the new handbook into your existing employees’ hands? With Company Documents, you can add a new version of a handbook into your HR Documents Library and send it out to all employees to review and sign in just a few clicks. Only made cosmetic changes? Simply send the new version only to new employees, hassle-free.</p>
                            </div>
                            <div class="col-md-5 col-sm-12">
                                <img src="@/assets/img/features/explore-company-docs.png" alt="HR Document Compliance" class="w-100 pb-1"/>
                                <p class="text-small">Track employee signature completion straight from your dashboard</p>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="container-fluid">
                    <div class="container-centered-l pt-4 pb-1">
                        <h2 class="mb-4">On Demand Documents</h2>
                        <div class="row feature-row-a">
                            <div class="col-sm-12 col-md-4 d-flex mb-1">
                                <i class="fal fa-fw fa-file-signature mr-1 text-primary-dark explore-icon"/>
                                <span class="text-primary-dark">Create and send PDFs for employee signatures</span>
                            </div>
                            <div class="col-sm-12 col-md-4 d-flex mb-1">
                                <i class="fal fa-fw fa-books mr-1 text-primary-dark explore-icon"/>
                                <p class="text-primary-dark">Store PDFs in a central HR Documents Library for easy re-use</p>
                            </div>
                            <div class="col-sm-12 col-md-4 d-flex mb-1">
                                <i class="fal fa-fw fa-clipboard-check mr-1 text-primary-dark explore-icon"/>
                                <p class="text-primary-dark">Track employee completion on your dashboard</p>
                            </div>
                        </div>
                        <div class="row pb-4 mt-4">
                            <div class="col-md-7 col-sm-12 d-flex flex-column justify-content-center pb-4">
                                <h3 class="pb-1">Handle one-time documents with ease</h3>
                                <p>Send off ad hoc documents to employees without taking up space in your HR Documents Library. With our powerful On Demand Documents module, you can assign a unique document to an employee directly from their sidebar, filing it away under their record without clogging your Library with documents that can’t be re-used.</p>
                                <h3 class="mt-2 pb-1">Case Study: How do you handle insurance coverage waivers?</h3>
                                <p>When your employee declines insurance company, how do you handle it? Insurance coverage waivers are personalized documents intended for specific employees, so one template won’t work for everyone. Instead of adding each waiver to your HR Documents Library separately, simply add the document to the intended employee’s sidebar, and easily send the document off for signature. Then, monitor their completion through the Insights widget on your dashboard-getting updates in real time so you can rest assured that no documents will slip through the cracks.</p>
                            </div>
                            <div class="col-md-5 col-sm-12">
                                <img src="@/assets/img/features/explore-on-demand.png" alt="On Demand Document" class="w-100 pb-1"/>
                                <p class="text-small">Send employees one-time, private documents to sign</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-fluid section-grey">
                    <div class="container-centered-l pt-4 pb-1">
                        <div class="mb-2">
                            <h2 class="mb-4 d-inline">Custom Forms</h2><span class="premium-pill ml-1"><i class="fas fa-star mr-1"/>Premium</span>
                        </div>
                        <div class="row feature-row-a">
                            <div class="col-sm-12 col-md-4 d-flex mb-1">
                                <i class="fal fa-fw fa-file-edit mr-1 text-primary-dark explore-icon"/>
                                <span class="text-primary-dark">Collect information for your company’s unique needs</span>
                            </div>
                            <div class="col-sm-12 col-md-4 d-flex mb-1">
                                <i class="fal fa-fw fa-exclamation-circle mr-1 text-primary-dark explore-icon"/>
                                <p class="text-primary-dark">Notify employees and their managers when critical documents are expiring</p>
                            </div>
                            <div class="col-sm-12 col-md-4 d-flex mb-1">
                                <i class="fal fa-fw fa-upload mr-1 text-primary-dark explore-icon"/>
                                <p class="text-primary-dark">Employees can upload photos of their validating documents right from their phones</p>
                            </div>
                        </div>
                        <div class="row pb-4 mt-4">
                            <div class="col-md-7 col-sm-12 d-flex flex-column justify-content-center pb-4">
                                <h3 class="pb-1">Customize a form for any use, including collecting data on expiring documents</h3>
                                <p>When you need to gather information from your employees, Custom Forms gets the job done easily and effectively. Our simple but powerful tool enables you to create forms to collect whatever data you need, however you need. Mark questions as optional or required, or utilize our sleek drag and drop technology to you can re-order questions in your form. </p>
                                <p>Custom Forms also handles expiring documents with ease. Flexibly select how far in advance you will be warned about a document that is about to expire, or adjust the custom form’s settings to send it only to a manager for completion, in the case of ServSafe certifications.</p>
                                <h3 class="mt-2 pb-1">Case Study: How do you collect employee drivers license info?</h3>
                                <p>How do you ensure that your delivery drivers’ credentials are up to date at all times? With Custom Forms, you can send a Drivers License form to your drivers to complete. Select when you’d like to be warned of an upcoming expiring license, and then sit back and relax as the system automatically sends a new form for drivers to complete with their updated license number and expiration date.</p>
                            </div>
                            <div class="col-md-5 col-sm-12">
                                <img src="@/assets/img/features/explore-custom-forms.png" alt="Custom Form" class="w-100 pb-1"/>
                                <p class="text-small">Create forms for a variety of purposes including tracking expiring documents</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-fluid section">
                    <div class="container-centered-l pt-4 pb-1">
                        <div class="mb-2">
                            <h2 class="mb-4 d-inline">Uniform Forms</h2><span class="premium-pill ml-1"><i class="fas fa-star mr-1"/>Premium</span>
                        </div>
                        <div class="row feature-row-a">
                            <div class="col-sm-12 col-md-4 d-flex mb-1">
                                <i class="fal fa-fw fa-file-signature mr-1 text-primary-dark explore-icon"/>
                                <span class="text-primary-dark">Gather size & style information for each required accessory</span>
                            </div>
                            <div class="col-sm-12 col-md-4 d-flex mb-1">
                                <i class="fal fa-fw fa-books mr-1 text-primary-dark explore-icon"/>
                                <p class="text-primary-dark">Built-in form options give you all the tools you need</p>
                            </div>
                            <div class="col-sm-12 col-md-4 d-flex mb-1">
                                <i class="fal fa-fw fa-clipboard-check mr-1 text-primary-dark explore-icon"/>
                                <p class="text-primary-dark">Configure distinct uniform options by position or by company</p>
                            </div>
                        </div>
                        <div class="row pb-4 mt-4">
                            <div class="col-md-7 col-sm-12 d-flex flex-column justify-content-center pb-4">
                                <h3 class="pb-1">Get your employees dressed for success</h3>
                                <p>No more starting a uniform form from scratch. With our Uniform Forms module, you can utilize our easy-to-use wizard to input your specific company uniform options with ease. Customize uniform forms by position, or by company, and create a uniform form that reflects your company’s inventory at the moment.</p>
                                <h3 class="mt-2 pb-1">Case Study: How do you give the right options to each employee?</h3>
                                <p>Let’s say you’re one of many companies that provide different uniforms to managers vs. non-managers. How do you make sure that each employee chooses from the right set of options? </p>
                                <p>Our Uniform Forms tool makes it easy to customize accessories, colors, sizes and styles by position. So your manager can choose between a teal vs. blue polo shirt, while your kitchen staff can choose between a teal vs. white t-shirt and hat. Our system can support any combination of size categories, sizes, colors and styles.</p>
                            </div>
                            <div class="col-md-5 col-sm-12">
                                <img src="@/assets/img/features/explore-uniforms.png" alt="Uniform Form" class="w-100 pb-1"/>
                                <p class="text-small">Quickly and easily create uniform forms for employees to complete online</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-fluid section-blue" ref="pricingSection">
                    <div class="container-centered-l py-4">
                        <div class="row justify-content-end">
                            <div class="col-md-6 col-sm-12 mb-4 mb-md-0">
                                <h4 class="mb-2">HR Compliance - Standard Plan</h4>
                                <p>
                                    Company Documents<br/>
                                    On-Demand Documents<br/>
                                    <br class="d-none d-md-inline"/>
                                    <br class="d-none d-md-inline"/>
                                    <hr>
                                    <h4>$19.99/month per location</h4>
                                </p>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <h4 class="mb-2">HR Compliance - Premium Plan</h4>
                                <p>
                                    Company Documents<br/>
                                    On-Demand Documents<br/>
                                    Custom Forms<br/>
                                    Uniform Forms<br/>
                                    <hr>
                                    <h4>$24.99/month per location</h4>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="brand-banner">
                    <div class="container-fluid">
                        <div class="container-centered-l py-4">
                            <div class="row pl-2 pr-2">
                                <div class="col-sm-12 col-md-8 text-center pt-1 mb-1">
                                    <p class="mb-0">Ready to take your HR Documents to the next level?</p>
                                </div>
                                <div class="col-sm-12 col-md-4 text-center">
                                    <a href="mailto:sales@paymastersinc.com" @click="trackContact" class="btn btn-outline-primary">Contact your Payroll Specialist</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['client', ],
    components: {},
    data() {
        return {
            seeMoreCompanies: false,
        }
    },
    created() {
        this.$store.dispatch('SET_PAGE_TITLE', 'HR Documents')
    },
    computed: {
        hrSuiteCompanies() {
            if (this.client && this.client.has_enhanced_ess) {
                return this.client.companies.filter(c => c.has_company_docs_module)
            } else {
                return []
            }
        },
        shownHRSuiteCompanies() {
            if (this.seeMoreCompanies) {
                return this.hrSuiteCompanies
            } else {
                return this.hrSuiteCompanies.slice(0, 3)
            }
        },
        hasHRSuite() {
            return this.hrSuiteCompanies.length > 0
        }
    },
    methods: {
        trackContact() {
            this.$bus.trackEvent('Enhanced ESS Contact', {'client_id': this.client ? this.client.id : null})
        },
        scrollToRef(refName) {
            var element = this.$refs[refName]
            var top = element.offsetTop
            window.scrollTo(0, top)
        }
    }
}

</script>

<style scoped lang="scss">
    @import "@/assets/scss/globals";
    .feature-marketing-page {
        h5 {
            color: #2A7EFF;
        }
        .container-centered-l {
            max-width: 64rem;
        }
        .brand-banner {
            background-color: #FE4E00;
            background-image: linear-gradient(135deg, #DD3B00 0%, #FE4E00 30%, #FD8753 100%);
            color: white;
            h1, h2, h3, h4, h5 {
                color: white;
            }
            h5 {
                letter-spacing: 4px;
            }
        }
        .feature-tile {
            border: 1px solid #CCCCCC;
            background-color: #F8F8F8;
            height: 290px;
            i {
                color: #D44100;
                font-size: 64px;
            }
        }
        .icon-circle {
            background-color: #2A7EFF;
            height: 44px;
            width: 44px;
            border-radius: 50%;
            position: relative;
            i {
                color: white;
                font-size: 22px;
                position: absolute;
                width: 22px;
                top: 11px;
                left: 11px;
            }
        }
        .section-blue {
            background-color: #E8F4F9;
        }
        .section-grey {
            background-color: #F8F8F8;
        }
        .text-teal2 {
            color: #206067 !important;
        }
        .feature-row-a {
            i {
                font-size: 40px;
            }
        }
        .premium-pill {
            i {
                color: #debb00;
            }
            background-color: #fbf4cc;
            border: 1px solid #ffd447;
            color: #333;
            border-radius: 6px;
            padding: 4px 8px;
        }
        .standard-pill {
            background-color: $gray-200;
            border: 1px solid $gray-200;
            color: #333;
            border-radius: 6px;
            padding: 4px 8px;
        }
        .doc-module-square {
            border: 2px solid #efbca5;
            border-radius: 6px;
            height: 300px;
            padding: 12px;
            padding-top: 24px;
            margin-bottom: 24px;
            .doc-module-square-icon {
                font-size: 64px;
                opacity: 0.25;
            }
        }
    }
</style>