<template>
    <modal @close="$emit('close')" :autoshow="true" ref="modal" :is-closeable="isCloseable">
        <template #title>POS Update: {{ updateObj.action_pretty }}</template>

        <slot>
            <div class="d-flex justify-content-between">
                <h4>
                    Status: {{ updateObj.status_pretty }}
                </h4>

                <h4>
                    {{ $filters.datetimeshort(updateObj.created_on) }}
                </h4>
            </div>

            <div v-for="detail in errorDetails" class="my-2 card">
                <div class="card-header p-1">
                    <b class="text-danger">Error {{ detail.errorCode }}</b>
                </div>
                <div class="card-body p-1">
                    <p>
                        {{ detail.cliErrorMsg }}
                    </p>
                </div>
            </div>

            <a href @click.prevent="debugDataOpen = !debugDataOpen"><span v-if="debugDataOpen">Hide</span><span v-else>Show</span> Detailed Info</a>
            <br><br>

            <div v-if="debugDataOpen">
                <div>
                    <b>Data Sent</b>
                    <pre class="json-data">
{{ updateObj.employee_data_redacted }}
                    </pre>
                </div>

                <div>
                    <b>Response</b>
                    <pre class="json-data">
{{ updateObj.webhook_response }}
                    </pre>
                </div>
            </div>
        </slot>

        <template #footer>
            <div class="form-row">
                <div class="col">
                    <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Close</button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import api from '@/api'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    mixins: [ModalMixin, ],
    props: ['client', 'employee', 'updateObj'],
    data() {
        return {
            debugDataOpen: false,
            errorDetails: this.updateObj.webhook_response[0] ? this.updateObj.webhook_response[0]['rejectionReason'] : []
        }
    },
    methods: {
    }
}
</script>

<style scoped>
.json-data {
    white-space: pre-wrap;
}
</style>
