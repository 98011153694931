<template>
    <employer-dashboard v-if="showDashboard" :client="client"/>
    <employer-home v-else :client="client"/>
</template>

<script>
// temporary component to switch between new and old dashboard until it's enabled for all
import EmployerHome from '@/pages/client/EmployerHome'
import EmployerDashboard from '@/pages/client/EmployerDashboard'

export default {
    props: ['client'],
    components: {EmployerHome, EmployerDashboard},
    computed: {
        showDashboard() {
            return this.client && (this.client.preview_dashboard || this.$store.state.system_flags?.dashboard_released)
        }
    }
}
</script>
