import { createRouter, createWebHistory } from 'vue-router'

import store from '@/store'
import NotFound from '@/pages/NotFound'
import AppDownload from '@/pages/AppDownload'
import Diagnostics from '@/pages/Diagnostics'
import PrivacyPolicy from '@/pages/PrivacyPolicy'
import DeleteAccountPolicy from '@/pages/DeleteAccountPolicy'
import AutoLogin from '@/pages/AutoLogin'
import AppLogout from '@/pages/AppLogout'
import Login from '@/pages/Login'
import ForgotPassword from '@/pages/ForgotPassword'
import ForgotPIN from '@/pages/ForgotPIN'
import ResetPIN from '@/pages/ResetPIN'
import NoSystemAccess from '@/pages/NoSystemAccess'
import AccountClosed from '@/pages/AccountClosed'
import LoginValidateEmail from '@/pages/LoginValidateEmail'
import Signup from '@/pages/Signup'
import SignupValidateEmail from '@/pages/SignupValidateEmail'
import SignupDigitalConsent from '@/pages/SignupDigitalConsent'
import SignupSetPassword from '@/pages/SignupSetPassword'
import SignupNoEmail from '@/pages/SignupNoEmail'
import UserAccount from '@/pages/UserAccount'
import ReviewTrustedDevices from '@/pages/ReviewTrustedDevices'
import CriticalWarnings from '@/pages/CriticalWarnings'

// Client views
import Client from '@/pages/client/Client'
import EmployerHomeRouter from '@/pages/client/EmployerHomeRouter'
import SelfServiceAccess from '@/pages/client/self-service-access'
import Onboarding from '@/pages/client/Onboarding'
import Warnings from '@/pages/client/warnings'
import HistoryLogs from '@/pages/client/history-logs'
import Paychecks from '@/pages/client/paychecks'
import Employees from '@/pages/client/employees'
import EmployeeW2s from '@/pages/client/employee-w2s'
import ClientTaxForms from '@/pages/client/tax-forms/TaxForms'
import ClientEVerify from '@/pages/client/e-verify'
import ClientPOSIntegration from '@/pages/client/pos-integration/POSIntegration'
import ClientPOSIntegrationBase from '@/pages/client/pos-integration/POSIntegrationBase'
import ClientPOSIntegrationStatus from '@/pages/client/pos-integration/POSIntegrationStatus'
import ClientPOSIntegrationReview from '@/pages/client/pos-integration/POSIntegrationReview'
import ClientPOSIntegrationReviewFinal from '@/pages/client/pos-integration/POSIntegrationReviewFinal'
import ClientPOSIntegrationComplete from '@/pages/client/pos-integration/POSIntegrationComplete'
import ClientPOSIntegrationError from '@/pages/client/pos-integration/POSIntegrationError'
import ClientTipsAllocator from '@/pages/client/tips-allocator/TipsAllocator'
import ClientTipsAllocatorBase from '@/pages/client/tips-allocator/TipsAllocatorBase'
import ClientTipsAllocatorStoreTotals from '@/pages/client/tips-allocator/TipsAllocatorStoreTotals'
import ClientTipsAllocatorEmployees from '@/pages/client/tips-allocator/TipsAllocatorEmployees'
import ClientTipsAllocatorComplete from '@/pages/client/tips-allocator/TipsAllocatorComplete'
import ClientTipsAllocatorStatus from '@/pages/client/tips-allocator/TipsAllocatorStatus'

import ClientCompletedHRDocuments from '@/pages/client/hr-documents/CompletedDocuments'
import ClientIncompleteHRDocuments from '@/pages/client/hr-documents/IncompleteDocuments'
import ClientCanceledHRDocuments from '@/pages/client/hr-documents/CanceledDocuments'
import CovidBase from '@/pages/client/covid/Base'
import CovidVaccinationStatuses from '@/pages/client/covid/VaccinationStatuses'
import SettingsBase from '@/pages/client/Settings/SettingsBase'
import SettingsUsers from '@/pages/client/Settings/Users'
import SettingsEVerifyUsers from '@/pages/client/Settings/EVerifyUsers'
import SettingsDocuments from '@/pages/client/Settings/Documents'
import SettingsAddDocument from '@/pages/client/Settings/AddDocument'
import SettingsEditDocument from '@/pages/client/Settings/EditDocument'
// import SettingsTemplates from '@/pages/client/Settings/Templates'
import SettingsChangeHistory from '@/pages/client/Settings/ChangeHistory'
import ActivateSecurityProfile from '@/pages/client/ActivateSecurityProfile'
import ModalChildOverlayTest from '@/pages/client/ModalChildOverlayTest'

// Feature marketing views
import FeaturesEnhancedESS from '@/pages/client/features/EnhancedESS'
import FeaturesHRCompliance from '@/pages/client/features/HRCompliance'
import FeaturesHRComplianceVideo from '@/pages/client/features/HRComplianceVideo'

// E-Verify Training
import EVerifyTraining from '@/pages/client/training/e-verify/EVerifyTraining'
import EVerifyTrainingPage1 from '@/pages/client/training/e-verify/Page1'
import EVerifyTrainingPage1a from '@/pages/client/training/e-verify/Page1a'
import EVerifyTrainingPage1b from '@/pages/client/training/e-verify/Page1b'
import EVerifyTrainingPage1c from '@/pages/client/training/e-verify/Page1c'
import EVerifyTrainingPage2 from '@/pages/client/training/e-verify/Page2'
import EVerifyTrainingPage2a from '@/pages/client/training/e-verify/Page2a'
import EVerifyTrainingPage2b from '@/pages/client/training/e-verify/Page2b'
import EVerifyTrainingPage3 from '@/pages/client/training/e-verify/Page3'
import EVerifyTrainingPage3a from '@/pages/client/training/e-verify/Page3a'
import EVerifyTrainingPage4 from '@/pages/client/training/e-verify/Page4'
import EVerifyTrainingPage5 from '@/pages/client/training/e-verify/Page5'
import EVerifyTrainingTest from '@/pages/client/training/e-verify/Test'
import EVerifyTrainingTestPass from '@/pages/client/training/e-verify/TestPass'
import EVerifyTrainingTestFail from '@/pages/client/training/e-verify/TestFail'

// Employee views
import Company from '@/pages/ess/Company'
import EmployeeHome from '@/pages/ess/EmployeeHome'
import EmployeeCompanyHome from '@/pages/ess/EmployeeCompanyHome'
import EmployeeTasks from '@/pages/ess/EmployeeTasks'
import EmployeeOnboardingApplication from '@/pages/ess/onboarding/EmployeeOnboardingApplication'
import LinkEmployee from '@/pages/ess/LinkEmployee'
import EmployeeProfileBase from '@/pages/ess/profile/EmployeeProfileBase'
import EmployeeProfilePersonalInfo from '@/pages/ess/profile/EmployeeProfilePersonalInfo'
import EmployeeProfileDirectDeposit from '@/pages/ess/profile/EmployeeProfileDirectDeposit'
import EmployeeProfileTaxWithholdings from '@/pages/ess/profile/EmployeeProfileTaxWithholdings'
import EmployeePaychecks from '@/pages/ess/EmployeePaychecks'
import EmployeePaycheck from '@/pages/ess/EmployeePaycheck'
import EmployeeTaxForms from '@/pages/ess/tax-forms/TaxForms'
import EmployeeTaxFormDetail from '@/pages/ess/tax-forms/TaxFormDetail'
import EmployeeHRDocuments from '@/pages/ess/hr-docs/HRDocuments'
import EmployeeCovidVaccinationStatus from '@/pages/ess/covid'

// Employee/onboarding
import OnboardingWOTCQuestions from '@/pages/ess/onboarding/OnboardingWOTCQuestions'
import OnboardingHRInfo from '@/pages/ess/onboarding/OnboardingHRInfo'
import OnboardingAddress from '@/pages/ess/onboarding/OnboardingAddress'
import OnboardingAddressDetails from '@/pages/ess/onboarding/OnboardingAddressDetails'
import OnboardingAddressCorrection from '@/pages/ess/onboarding/OnboardingAddressCorrection'
import OnboardingAddressSchoolDistrict from '@/pages/ess/onboarding/OnboardingAddressSchoolDistrict'
import OnboardingEmergencyContact from '@/pages/ess/onboarding/OnboardingEmergencyContact'
import OnboardingEEOInfo from '@/pages/ess/onboarding/OnboardingEEOInfo'
import OnboardingFormSelection from '@/pages/ess/onboarding/OnboardingFormSelection'
import OnboardingForms from '@/pages/ess/onboarding/OnboardingForms'
import OnboardingDirectDeposit from '@/pages/ess/onboarding/OnboardingDirectDeposit'
import OnboardingComplete from '@/pages/ess/onboarding/OnboardingComplete'

// Admin views

import AdminHome from '@/pages/admin/Index'
import TaxEngineBase from '@/pages/admin/tax-engine/Base'
import TaxEngineStatesCities from '@/pages/admin/tax-engine/StatesCities'
import TaxEngineJurisdictions from '@/pages/admin/tax-engine/Jurisdictions'
import TaxEngineFed from '@/pages/admin/tax-engine/Fed'
import TaxEngineStates from '@/pages/admin/tax-engine/States'
import TaxEngineCities from '@/pages/admin/tax-engine/Cities'
import TaxEngineEarningTypes from '@/pages/admin/tax-engine/EarningTypes'
import TaxEngineDeductionTypes from '@/pages/admin/tax-engine/DeductionTypes'
import TaxEngineReimbursements from '@/pages/admin/tax-engine/ReimbursementTypes'
import TaxEngineTaxes from '@/pages/admin/tax-engine/Taxes'
import TaxEngineTaxExemptionsGroups from '@/pages/admin/tax-engine/TaxExemptionsGroups'

import PDFFormsBase from '@/pages/admin/pdf-forms/Base'
import PDFForms from '@/pages/admin/pdf-forms/Forms'
import PDFFormQuestions from '@/pages/admin/pdf-forms/Questions'
import PDFFormSelectionTester from '@/pages/admin/pdf-forms/SelectionTester'
import StateFormsRulesList from '@/pages/admin/pdf-forms/RulesList'
import StateFormsRulesTest from '@/pages/admin/pdf-forms/RulesTest'

import AdminPayrolls from '@/pages/admin/payrolls/Payrolls'
import AdminClients from '@/pages/admin/clients/Clients'
import AdminClientBase from '@/pages/admin/clients/Base'
import AdminClientCompanies from '@/pages/admin/clients/Companies'
import AdminClientPOSIntegrations from '@/pages/admin/clients/POSIntegrations'
import AdminClientPayItems from '@/pages/admin/clients/PayItems'
import AdminClientConfigOrgs from '@/pages/admin/clients/Orgs'
import AdminClientConfigPaygroups from '@/pages/admin/clients/Paygroups'
import AdminClientConfigLocations from '@/pages/admin/clients/Locations'
import AdminClientConfigPositions from '@/pages/admin/clients/Positions'
import AdminClientFormEntitlements from '@/pages/admin/clients/FormEntitlements'

import AdminATSLogs from '@/pages/admin/ats/ATSLogs'

import AdminW2Review from '@/pages/admin/w2-review/W2Review'

import AdminGeocodingBase from '@/pages/admin/geocoding/Base'
import AdminGeocodingTester from '@/pages/admin/geocoding/Tester'
import AdminGeocodingSchoolDistrict from '@/pages/admin/geocoding/SchoolDistrict'
import AdminGeocodingOHFinderCheckMatches from '@/pages/admin/geocoding/OHFinderCheckMatches'

import AdminAnalyticsBase from '@/pages/admin/analytics/Base'
import AdminAnalyticsAdoption from '@/pages/admin/analytics/Adoption'
import AdminAnalyticsBanks from '@/pages/admin/analytics/Banks'

import AdminUtilities from '@/pages/admin/utilities'
import AdminUtilitiesOBCCheckMatch from '@/pages/admin/utilities/obc-check-match'

import AdminDebug from '@/pages/admin/debug'

import Calculator from '@/pages/calculator'

import Index from '@/pages/Index.vue'
import $ from 'jquery'

import bus from '@/bus'
import hasher from '@/hasher'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {name: '404', path: '/:pathMatch(.*)*', component: NotFound, meta: {requiresAuth: false}},
        {name: 'index', path: '/', component: Index, meta: {requiresAuth: true}},
        {name: 'privacy', path: '/privacy', component: PrivacyPolicy, meta: {requiresAuth: false}},
        {name: 'delete-account', path: '/delete-account', component: DeleteAccountPolicy, meta: {requiresAuth: false}},
        {name: 'download-app', path: '/download-app', component: AppDownload, meta: {requiresAuth: false}},
        {name: 'diagnostics', path: '/diagnostics', component: Diagnostics, meta: {requiresAuth: false}},

        {name: 'login', path: '/login', component: Login, meta: {requiresAuth: false}},
        {name: 'login-validate-email', path: '/login/validate-email', component: LoginValidateEmail, meta: {requiresAuth: false}},
        {name: 'no-system-access', path: '/no-access', component: NoSystemAccess, meta: {requiresAuth: false}},
        {name: 'account-closed', path: '/account-closed', component: AccountClosed, meta: {requiresAuth: false}},
        {name: 'auto-login', path: '/login/auto/:challengeKey/:secureToken', component: AutoLogin, meta: {requiresAuth: false}},
        {name: 'app-logout', path: '/app-logout', component: AppLogout, meta: {requiresAuth: false}},
        {name: 'forgot-password', path: '/forgot-password', component: ForgotPassword, meta: {requiresAuth: false}},
        {name: 'forgot-pin', path: '/forgot-pin', component: ForgotPIN, meta: {requiresAuth: false}},
        {name: 'reset-pin', path: '/reset-pin/:secret', component: ResetPIN, meta: {requiresAuth: false}},

        {name: 'signup', path: '/signup', component: Signup, meta: {requiresAuth: false}},
        {name: 'signup-validate-email', path: '/signup/validate-email', component: SignupValidateEmail, meta: {requiresAuth: false}},
        {name: 'signup-digital-consent', path: '/signup/digital-consent', component: SignupDigitalConsent, meta: {requiresAuth: false}},
        {name: 'signup-set-password', path: '/signup/set-password', component: SignupSetPassword, meta: {requiresAuth: false}},
        {name: 'signup-no-email', path: '/signup/no-email', component: SignupNoEmail, meta: {requiresAuth: false}},
        {name: 'account', path: '/account', component: UserAccount, meta: {requiresAuth: true}},
        {name: 'review-trusted-devices', path: '/review-trusted-devices', component: ReviewTrustedDevices, meta: {requiresAuth: true}},

        {name: 'employee-home', path: '/employee', component: EmployeeHome, meta: {requiresAuth: true, mode: 'ess'}},
        {name: 'account-ess', path: '/employee/account', component: UserAccount, meta: {requiresAuth: true, mode: 'ess'}},

        {name: 'employee-company-home', path: '/employee/companies/:hashedCompanyId', component: EmployeeCompanyHome, meta: {requiresAuth: true, mode: 'ess'}},
        {path: '/employee/companies/:hashedCompanyId', component: Company, meta: {requiresAuth: true, mode: 'ess'}, children: [
            {name: 'employee-profile', path: 'profile', component: EmployeeProfileBase, meta: {requiresAuth: true, mode: 'ess'}, children: [
                {name: 'employee-profile-personal-info', path: 'personal-info', component: EmployeeProfilePersonalInfo, meta: {requiresAuth: true, mode: 'ess'}},
                {name: 'employee-profile-direct-deposit', path: 'direct-deposit', component: EmployeeProfileDirectDeposit, meta: {requiresAuth: true, mode: 'ess'}},
                {name: 'employee-profile-tax-withholdings', path: 'tax-forms', component: EmployeeProfileTaxWithholdings, meta: {requiresAuth: true, mode: 'ess'}},
            ]},
            {name: 'employee-covid', path: 'covid', component: EmployeeCovidVaccinationStatus, meta: {requiresAuth: true, mode: 'ess'}},
            {name: 'employee-link-record', path: 'link', component: LinkEmployee, meta: {requiresAuth: true, mode: 'ess'}},  // XXX

            {name: 'employee-tasks', path: 'tasks', component: EmployeeTasks, meta: {requiresAuth: true, mode: 'ess'}},

            {name: 'employee-paychecks', path: 'paychecks', component: EmployeePaychecks, meta: {requiresAuth: true, mode: 'ess'}},
            {name: 'employee-paycheck', path: 'paychecks/:paycheckId', component: EmployeePaycheck, meta: {requiresAuth: true, mode: 'ess'}},
            {name: 'employee-tax-forms', path: 'tax-forms', component: EmployeeTaxForms, meta: {requiresAuth: true, mode: 'ess'}},
            {name: 'employee-tax-form-detail', path: 'tax-forms/:taxFormId', component: EmployeeTaxFormDetail, meta: {requiresAuth: true, mode: 'ess'}},
            {name: 'employee-hr-docs', path: 'hr-docs', component: EmployeeHRDocuments, meta: {requiresAuth: true, mode: 'ess'}},

            {name: 'employee-onboarding', path: 'onboarding/:appId', component: EmployeeOnboardingApplication, meta: {requiresAuth: true, mode: 'ess'}, children: [
                {name: 'employee-onboarding-wotc', path: 'wotc', component: OnboardingWOTCQuestions, meta: {requiresAuth: true, mode: 'ess'}},
                {name: 'employee-onboarding-hr-info', path: 'hr-info', component: OnboardingHRInfo, meta: {requiresAuth: true, mode: 'ess'}},
                {name: 'employee-onboarding-address', path: 'address', component: OnboardingAddress, meta: {requiresAuth: true, mode: 'ess'}},
                {name: 'employee-onboarding-address-details', path: 'address-details', component: OnboardingAddressDetails, meta: {requiresAuth: true, mode: 'ess'}},
                {name: 'employee-onboarding-address-correction', path: 'address-correction', component: OnboardingAddressCorrection, meta: {requiresAuth: true, mode: 'ess'}},
                {name: 'employee-onboarding-address-school-district', path: 'address-school-district', component: OnboardingAddressSchoolDistrict, meta: {requiresAuth: true, mode: 'ess'}},
                {name: 'employee-onboarding-emergency-contact', path: 'emergency-contact', component: OnboardingEmergencyContact, meta: {requiresAuth: true, mode: 'ess'}},
                {name: 'employee-onboarding-eeo-info', path: 'eeo-info', component: OnboardingEEOInfo, meta: {requiresAuth: true, mode: 'ess'}},
                {name: 'employee-onboarding-form-selection', path: 'form-selection', component: OnboardingFormSelection, meta: {requiresAuth: true, mode: 'ess'}},
                {name: 'employee-onboarding-forms', path: 'forms', component: OnboardingForms, meta: {requiresAuth: true, mode: 'ess'}},
                {name: 'employee-onboarding-direct-deposit', path: 'direct-deposit', component: OnboardingDirectDeposit, meta: {requiresAuth: true, mode: 'ess'}},
                {name: 'employee-onboarding-complete', path: 'complete', component: OnboardingComplete, meta: {requiresAuth: true, mode: 'ess'}},
            ]},
        ]},

        {name: 'link-client', path: '/clients/:hashedClientId/link', component: ActivateSecurityProfile, meta: {requiresAuth: true, mode: 'client'}},
        {path: '/clients/:hashedClientId/', component: Client, meta: {requiresAuth: true, mode: 'client'}, children: [
            {name: 'employer-home', path: '', component: EmployerHomeRouter, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'self-service-access', path: 'self-service-access', component: SelfServiceAccess, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'onboarding', path: 'onboarding', component: Onboarding, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'warnings', path: 'warnings', component: Warnings, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'changelogs', path: 'changelogs', component: HistoryLogs, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'paychecks', path: 'paychecks', component: Paychecks, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'employees', path: 'employees', component: Employees, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'employee-w2s', path: 'employee-w2s', component: EmployeeW2s, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'hr-documents', path: 'hr-documents', component: ClientCompletedHRDocuments, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'hr-documents-incomplete', path: 'hr-documents-incomplete', component: ClientIncompleteHRDocuments, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'hr-documents-canceled', path: 'hr-documents-canceled', component: ClientCanceledHRDocuments, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'tax-forms', path: 'tax-forms', component: ClientTaxForms, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'e-verify', path: 'e-verify', component: ClientEVerify, meta: {requiresAuth: true, mode: 'client'}},

            {name: 'pos-integration', path: 'pos-integration', component: ClientPOSIntegration, meta: {requiresAuth: true, mode: 'client'}},
            {path: 'pos-integration/:taskId', component: ClientPOSIntegrationBase, meta: {requiresAuth: true, mode: 'client'}, children: [
                {name: 'pos-integration-status', path: 'status', component: ClientPOSIntegrationStatus, meta: {requiresAuth: true, mode: 'client', wizardStep: 1}},
                {name: 'pos-integration-review', path: 'review', component: ClientPOSIntegrationReview, meta: {requiresAuth: true, mode: 'client', wizardStep: 2}},
                {name: 'pos-integration-review-final', path: 'review-final', component: ClientPOSIntegrationReviewFinal, meta: {requiresAuth: true, mode: 'client', wizardStep: 3}},
                {name: 'pos-integration-complete', path: 'complete', component: ClientPOSIntegrationComplete, meta: {requiresAuth: true, mode: 'client', wizardStep: 3}},
                {name: 'pos-integration-error', path: 'error', component: ClientPOSIntegrationError, meta: {requiresAuth: true, mode: 'client', wizardStep: 2}},
            ]},

            {name: 'tips-allocator', path: 'tips-allocator', component: ClientTipsAllocator, meta: {requiresAuth: true, mode: 'client'}},
            {path: 'tips-allocator/:taskId', component: ClientTipsAllocatorBase, meta: {requiresAuth: true, mode: 'client'}, children: [
                {name: 'tips-allocator-store-totals', path: 'store-totals', component: ClientTipsAllocatorStoreTotals, meta: {requiresAuth: true, mode: 'client'}},
                {name: 'tips-allocator-employees', path: 'employees', component: ClientTipsAllocatorEmployees, meta: {requiresAuth: true, mode: 'client'}},
                {name: 'tips-allocator-complete', path: 'complete', component: ClientTipsAllocatorComplete, meta: {requiresAuth: true, mode: 'client'}},
                {name: 'tips-allocator-error', path: 'error', component: ClientTipsAllocatorComplete, meta: {requiresAuth: true, mode: 'client'}},
                {name: 'tips-allocator-status', path: 'status', component: ClientTipsAllocatorStatus, meta: {requiresAuth: true, mode: 'client'}},
            ]},

            {name: 'settings', path: 'settings', component: SettingsBase, meta: {requiresAuth: true, mode: 'client'}, children: [
                {name: 'settings-users', path: 'users', component: SettingsUsers, meta: {requiresAuth: true, mode: 'client'}},
                {name: 'settings-everify-users', path: 'everify-users', component: SettingsEVerifyUsers, meta: {requiresAuth: true, mode: 'client'}},
                {name: 'settings-hr-documents', path: 'hr-documents', component: SettingsDocuments, meta: {requiresAuth: true, mode: 'client'}},
                // {name: 'settings-hr-templates', path: 'hr-templates', component: SettingsTemplates, meta: {requiresAuth: true, mode: 'client'}},
                {name: 'settings-change-history', path: 'changelogs', component: SettingsChangeHistory, meta: {requiresAuth: true, mode: 'client'}},
            ]},
            {name: 'settings-hr-documents-add', path: 'settings/hr-documents/add', component: SettingsAddDocument, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'settings-hr-documents-edit-draft', path: 'settings/hr-documents/:documentType/:documentId/edit', component: SettingsEditDocument, meta: {requiresAuth: true, mode: 'client', documentMode: 'edit-draft'}},
            {name: 'settings-hr-documents-add-version', path: 'settings/hr-documents/:documentType/:documentId/versions/add', component: SettingsEditDocument, meta: {requiresAuth: true, mode: 'client', documentMode: 'add-version'}},
            {name: 'settings-hr-documents-edit-draft-version', path: 'settings/hr-documents/:documentType/:documentId/versions/:documentVersionNumber/edit', component: SettingsEditDocument, meta: {requiresAuth: true, mode: 'client', documentMode: 'edit-draft-version'}},
            {name: 'settings-hr-documents-duplicate', path: 'settings/hr-documents/:documentType/:documentId/duplicate', component: SettingsEditDocument, meta: {requiresAuth: true, mode: 'client', documentMode: 'duplicate'}},
            {name: 'account-client', path: 'account', component: UserAccount, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'critical-warnings', path: 'critical-warnings', component: CriticalWarnings, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'modal-child-overlay-test', path: 'modal-child-overlay-test', component: ModalChildOverlayTest, meta: {requiresAuth: true, mode: 'client'}},

            {name: 'covid', path: 'covid', component: CovidBase, meta: {requiresAuth: true, mode: 'client'}, children: [
                {name: 'covid-vaccination-statuses', path: 'vaccination-statuses', component: CovidVaccinationStatuses, meta: {requiresAuth: true, mode: 'client'}},
            ]},

            // Client-facing feature pages
            {name: 'client-features-enhanced-ess', path: 'features/enhanced-ess', component: FeaturesEnhancedESS, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'client-features-hr-compliance', path: 'features/hr-compliance', component: FeaturesHRCompliance, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'client-features-hr-compliance-video', path: 'features/hr-compliance-video', component: FeaturesHRComplianceVideo, meta: {requiresAuth: true, mode: 'client'}},

            // E-Verify Training
            {name: 'training-e-verify', path: 'training/e-verify', component: EVerifyTraining, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'training-e-verify-page1', path: 'training/e-verify/page-1', component: EVerifyTrainingPage1, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'training-e-verify-page1a', path: 'training/e-verify/page-1a', component: EVerifyTrainingPage1a, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'training-e-verify-page1b', path: 'training/e-verify/page-1b', component: EVerifyTrainingPage1b, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'training-e-verify-page1c', path: 'training/e-verify/page-1c', component: EVerifyTrainingPage1c, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'training-e-verify-page2', path: 'training/e-verify/page-2', component: EVerifyTrainingPage2, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'training-e-verify-page2a', path: 'training/e-verify/page-2a', component: EVerifyTrainingPage2a, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'training-e-verify-page2b', path: 'training/e-verify/page-2b', component: EVerifyTrainingPage2b, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'training-e-verify-page3', path: 'training/e-verify/page-3', component: EVerifyTrainingPage3, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'training-e-verify-page3a', path: 'training/e-verify/page-3a', component: EVerifyTrainingPage3a, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'training-e-verify-page4', path: 'training/e-verify/page-4', component: EVerifyTrainingPage4, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'training-e-verify-page5', path: 'training/e-verify/page-5', component: EVerifyTrainingPage5, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'training-e-verify-test', path: 'training/e-verify/test', component: EVerifyTrainingTest, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'training-e-verify-test-pass', path: 'training/e-verify/test-pass/:testId', component: EVerifyTrainingTestPass, meta: {requiresAuth: true, mode: 'client'}},
            {name: 'training-e-verify-test-fail', path: 'training/e-verify/test-fail/:testId', component: EVerifyTrainingTestFail, meta: {requiresAuth: true, mode: 'client'}},
        ]},

        // Public feature pages
        {name: 'features-enhanced-ess', path: '/features/enhanced-ess', component: FeaturesEnhancedESS, meta: {requiresAuth: false}},
        {name: 'features-hr-compliance', path: '/features/hr-compliance', component: FeaturesHRCompliance, meta: {requiresAuth: false}},

        // Admin pages
        {name: 'admin-home', path: '/admin-tools', component: AdminHome, meta: {requiresAuth: true, mode: 'admin'}},
        {name: 'account-admin', path: '/admin-tools/account', component: UserAccount, meta: {requiresAuth: true, mode: 'admin'}},

        {name: 'admin-tax-engine', path: '/admin-tools/tax-engine', component: TaxEngineBase, meta: {requiresAuth: true, mode: 'admin'}, children: [
            {name: 'admin-tax-engine-jurisdictions', path: 'jurisdictions', component: TaxEngineJurisdictions, meta: {requiresAuth: true, mode: 'admin'}},
            {name: 'admin-tax-engine-states-cities', path: 'states-cities', component: TaxEngineStatesCities, meta: {requiresAuth: true, mode: 'admin'}, children: [
                {name: 'admin-tax-engine-fed', path: 'fed', component: TaxEngineFed, meta: {requiresAuth: true, mode: 'admin'}},
                {name: 'admin-tax-engine-states', path: 'states', component: TaxEngineStates, meta: {requiresAuth: true, mode: 'admin'}},
                {name: 'admin-tax-engine-cities', path: 'cities', component: TaxEngineCities, meta: {requiresAuth: true, mode: 'admin'}},
            ]},
            {name: 'admin-tax-engine-earning-types', path: 'earning-types', component: TaxEngineEarningTypes, meta: {requiresAuth: true, mode: 'admin'}},
            {name: 'admin-tax-engine-deduction-types', path: 'deduction-types', component: TaxEngineDeductionTypes, meta: {requiresAuth: true, mode: 'admin'}},
            {name: 'admin-tax-engine-reimbursement-types', path: 'reimbursement-types', component: TaxEngineReimbursements, meta: {requiresAuth: true, mode: 'admin'}},
            {name: 'admin-tax-engine-taxes', path: 'taxes', component: TaxEngineTaxes, meta: {requiresAuth: true, mode: 'admin'}},
            {name: 'admin-tax-engine-tax-exempt-groups', path: 'tax-exempt-groups', component: TaxEngineTaxExemptionsGroups, meta: {requiresAuth: true, mode: 'admin'}},
        ]},

        {name: 'admin-geocoding', path: '/admin-tools/geocoding', component: AdminGeocodingBase, meta: {requiresAuth: true, mode: 'admin'}, children: [
            {name: 'admin-geocoding-tester', path: 'tester', component: AdminGeocodingTester, meta: {requiresAuth: true, mode: 'admin'}},
            {name: 'admin-geocoding-school-district', path: 'school-district', component: AdminGeocodingSchoolDistrict, meta: {requiresAuth: true, mode: 'admin'}},
            {name: 'admin-geocoding-oh-finder-check-matches', path: 'oh-finder-check-matches', component: AdminGeocodingOHFinderCheckMatches, meta: {requiresAuth: true, mode: 'admin'}},
        ]},

        {name: 'admin-analytics', path: '/admin-tools/analytics', component: AdminAnalyticsBase, meta: {requiresAuth: true, mode: 'admin'}, children: [
            {name: 'admin-analytics-adoption', path: 'adoption', component: AdminAnalyticsAdoption, meta: {requiresAuth: true, mode: 'admin'}},
            {name: 'admin-analytics-banks', path: 'banks', component: AdminAnalyticsBanks, meta: {requiresAuth: true, mode: 'admin'}},
        ]},

        {name: 'admin-pdf-forms', path: '/admin-tools/pdf-forms', component: PDFFormsBase, meta: {requiresAuth: true, mode: 'admin'}, children: [
            {name: 'admin-pdf-forms-forms', path: 'forms', component: PDFForms, meta: {requiresAuth: true, mode: 'admin'}},
            {name: 'admin-pdf-forms-questions', path: 'questions', component: PDFFormQuestions, meta: {requiresAuth: true, mode: 'admin'}},
            {name: 'admin-pdf-forms-tester', path: 'tester', component: PDFFormSelectionTester, meta: {requiresAuth: true, mode: 'admin'}},
            {name: 'admin-pdf-forms-rules', path: 'rules', component: StateFormsRulesList, meta: {requiresAuth: true, mode: 'admin'}},
            {name: 'admin-pdf-forms-rules-test', path: 'rules-test', component: StateFormsRulesTest, meta: {requiresAuth: true, mode: 'admin'}},
        ]},
        {name: 'admin-payrolls', path: '/admin-tools/payrolls', component: AdminPayrolls, meta: {requiresAuth: true, mode: 'admin'}},

        {name: 'admin-clients', path: '/admin-tools/clients', component: AdminClients, meta: {requiresAuth: true, mode: 'admin'}},
        {name: 'admin-client', path: '/admin-tools/clients/:hashedClientId', component: AdminClientBase, meta: {requiresAuth: true, mode: 'admin'}, children: [
            {name: 'admin-client-companies', path: 'companies', component: AdminClientCompanies, meta: {requiresAuth: true, mode: 'admin'}},
            {name: 'admin-client-pos-integrations', path: 'integrations', component: AdminClientPOSIntegrations, meta: {requiresAuth: true, mode: 'admin'}},
            {name: 'admin-client-pay-items', path: 'pay-items', component: AdminClientPayItems, meta: {requiresAuth: true, mode: 'admin'}},
            {name: 'admin-client-config-orgs', path: 'orgs', component: AdminClientConfigOrgs, meta: {requiresAuth: true, mode: 'admin'}},
            {name: 'admin-client-config-paygroups', path: 'paygroups', component: AdminClientConfigPaygroups, meta: {requiresAuth: true, mode: 'admin'}},
            {name: 'admin-client-config-locations', path: 'locations', component: AdminClientConfigLocations, meta: {requiresAuth: true, mode: 'admin'}},
            {name: 'admin-client-config-positions', path: 'positions', component: AdminClientConfigPositions, meta: {requiresAuth: true, mode: 'admin'}},
            {name: 'admin-client-form-entitlements', path: 'form-entitlements', component: AdminClientFormEntitlements, meta: {requiresAuth: true, mode: 'admin'}},
        ]},

        {name: 'admin-w2-review', path: '/admin-tools/w2-review', component: AdminW2Review, meta: {requiresAuth: true, mode: 'admin'}},

        {name: 'admin-ats-logs', path: '/admin-tools/ats-logs', component: AdminATSLogs, meta: {requiresAuth: true, mode: 'admin'}},

        {name: 'admin-utilities', path: '/admin-tools/utilities', component: AdminUtilities, meta: {requiresAuth: true, mode: 'admin'}},
        {name: 'admin-utilities-obc-check-match', path: '/admin-tools/utilities/obc-check-match', component: AdminUtilitiesOBCCheckMatch, meta: {requiresAuth: true, mode: 'admin'}},

        {path: '/admin/onboarding/:appId', component: Company, meta: {requiresAuth: true, mode: 'admin'}, children: [
            {name: 'admin-onboarding', path: '', component: EmployeeOnboardingApplication, meta: {requiresAuth: true, mode: 'admin'}, children: [
                {name: 'admin-onboarding-wotc', path: 'wotc', component: OnboardingWOTCQuestions, meta: {requiresAuth: true, mode: 'admin'}},
                {name: 'admin-onboarding-hr-info', path: 'hr-info', component: OnboardingHRInfo, meta: {requiresAuth: true, mode: 'admin'}},
                {name: 'admin-onboarding-address', path: 'address', component: OnboardingAddress, meta: {requiresAuth: true, mode: 'admin'}},
                {name: 'admin-onboarding-address-details', path: 'address-details', component: OnboardingAddressDetails, meta: {requiresAuth: true, mode: 'admin'}},
                {name: 'admin-onboarding-address-correction', path: 'address-correction', component: OnboardingAddressCorrection, meta: {requiresAuth: true, mode: 'admin'}},
                {name: 'admin-onboarding-address-school-district', path: 'address-school-district', component: OnboardingAddressSchoolDistrict, meta: {requiresAuth: true, mode: 'admin'}},
                {name: 'admin-onboarding-emergency-contact', path: 'emergency-contact', component: OnboardingEmergencyContact, meta: {requiresAuth: true, mode: 'admin'}},
                {name: 'admin-onboarding-eeo-info', path: 'eeo-info', component: OnboardingEEOInfo, meta: {requiresAuth: true, mode: 'admin'}},
                {name: 'admin-onboarding-form-selection', path: 'form-selection', component: OnboardingFormSelection, meta: {requiresAuth: true, mode: 'admin'}},
                {name: 'admin-onboarding-forms', path: 'forms', component: OnboardingForms, meta: {requiresAuth: true, mode: 'admin'}},
                {name: 'admin-onboarding-direct-deposit', path: 'direct-deposit', component: OnboardingDirectDeposit, meta: {requiresAuth: true, mode: 'admin'}},
                {name: 'admin-onboarding-complete', path: 'complete', component: OnboardingComplete, meta: {requiresAuth: true, mode: 'admin'}},
            ]},
        ]},

        {name: 'admin-debug', path: '/admin-tools/debug', component: AdminDebug, meta: {requiresAuth: true, mode: 'admin'}},
        {name: 'calculator', path: '/calculator', component: Calculator, meta: {requiresAuth: true}},
    ],
    scrollBehavior() {
        return {left: 0, top: 0}
    },
})

router.beforeEach((to, from, next) => {
    store.dispatch('SET_CLOSEABLE', true)
    store.dispatch('STOP_LOADING')
    store.dispatch('SET_PAGE_TITLE', '')
    store.dispatch('CLOSE_LEFT_DRAWER')
    $('body').removeClass('modal-open')
    $('body .modal-backdrop').remove()
    if (to.meta.requiresAuth && !store.state.user) {
        next('/login?next=' + encodeURIComponent(to.path))
    }

    if (typeof global !== 'undefined' && global.webkit && global.webkit.messageHandlers && global.webkit.messageHandlers.cordova_iab) {
        global.webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({
            command: 'navigate',
            from: {
                fullPath: from.fullPath,
                path: from.path,
                name: from.name,
                params: from.params,
            },
            to: {
                fullPath: to.fullPath,
                path: to.path,
                name: to.name,
                params: to.params,
            },
        }))
    }

    bus.onNavigation()

    next()
})

router.afterEach((to) => {
    if (to.meta.mode == 'client' && store.state.user && to.params.hashedClientId) {
        bus.subscribeToClientUpdates(hasher.decode(to.params.hashedClientId))
    }
    else {
        bus.unsubscribeFromClientUpdates()
    }
    bus.trackEvent('Page View', {'name': to.name, 'params': to.params})
})


export default router
