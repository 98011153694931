<script>
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

export default {
    data() {
        return {
            tour: null,
            tourConfig: { // Our default config; see https://driverjs.com/docs/configuration
                showProgress: false,
                allowClose: true,
                smoothScroll: true,
                overlayOpacity: 0.5,
                showButtons: ['next', 'previous', 'close'],
                nextBtnText: 'Next',
                prevBtnText: 'Back',
                doneBtnText: 'Done',
                disableActiveInteraction: false,
                steps: [],
                onCloseClick: (element) => { this.$emit('close'); this.tour.destroy() }
            }
        }
    },
    mounted() {},
    unmounted() {
        this.tour?.destroy()
    },
    methods: {
        setSteps(steps) {
            steps.forEach(s => {
                if (s.moveNextOnElementClick) { // our own shortcut for a step where the user must click the highlighted element to continue
                    s.popover.showButtons ||= ['close']
                    s.popover.onNextClick ||= () => {}
                    s.popover.onPrevClick ||= () => {}
                    s.onHighlightStarted ||= (element) => {
                        element.classList.add("move-next-on-element-click");
                        element.addEventListener('click', this.tour.moveNext, { once: true });
                    }
                }
            })
            this.tourConfig.steps = steps
        },
        setConfig(config) {
            Object.assign(this.tourConfig, config)
        },
        _start() {
            this.tour = driver(this.tourConfig)
            this.tour.drive()
        },
        // utilities for manipulating UI during tour
        openMainMenu() {
            document.getElementById('navbarDropdownMenu')?.classList.add('show');
            document.getElementById('navbarDropdown')?.classList.add('show');
        },
        closeMainMenu() {
            document.getElementById('navbarDropdownMenu')?.classList.remove('show');
            document.getElementById('navbarDropdown')?.classList.remove('show');
        },
    }
}
</script>

<style lang="scss">
    @import "@/assets/scss/globals";

    :not(body):has(>.driver-active-element) {
        overflow: revert !important; // hack for highlight
    }
    .driver-popover {
        background-color: $brand-orange-light;
    }
    .driver-popover-arrow-side-bottom {
        border-bottom-color: $brand-orange-light;
    }
    .driver-popover-arrow-side-top {
        border-top-color: $brand-orange-light;
    }
    .driver-popover-arrow-side-left {
        border-left-color: $brand-orange-light;
    }
    .driver-popover-arrow-side-right {
        border-right-color: $brand-orange-light;
    }
    #driver-popover-title {
        font-size: 1.125rem;
    }
    #driver-popover-description {
        font-size: $font-size-base;
    }
    .driver-popover-footer .driver-popover-navigation-btns {
        justify-content: space-between;

        button {
            display: inline-block;
            font-weight: $font-weight-normal;
            color: #333333;
            text-align: center;
            vertical-align: middle;
            user-select: none;
            background-color: transparent;
            border: 1px solid transparent;
            padding: $btn-padding-y $btn-padding-x;
            font-size: $font-size-base;
            line-height: $line-height-base;
            border-radius: $capsule-border-radius;
            transition: all 0.15s ease-in-out;
            cursor: pointer;
            box-shadow: none;
            text-shadow: none;
        }
        .driver-popover-next-btn {
            color: #fff;
            background-color: $brand-orange;
            border-color: $brand-orange;
        }
        .driver-popover-next-btn:hover {
            color: #fff;
            background-color: $brand-orange-dark;
            border-color: $brand-orange-dark;
        }
        .driver-popover-prev-btn {
            color: $brand-orange;
            background-color: #fff;
            border-color: $brand-orange;
        }
        .driver-popover-prev-btn:hover {
            color: #fff;
            background-color: $brand-orange;
            border-color: $brand-orange;
        }
    }
    .driver-popover-close-btn {
        opacity: 0.5;
    }
    .move-next-on-element-click.driver-no-interaction, .move-next-on-element-click.driver-no-interaction * {
        pointer-events: all !important;
    }
</style>