<template>
    <modal @close="$emit('close')" :autoshow="true" ref="modal" :is-closeable="isCloseable">
        <template v-slot:title>Remaining Locations</template>

        <slot>
            <div>
                <div v-for="store in stores">
                    <h4>{{ store.store }}</h4>
                </div>
            </div>
            <br>
        </slot>

        <template v-slot:footer>
            <div class="form-row">
                <div class="col">
                    <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Close</button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'

export default {
    mixins: [ModalMixin],
    components: { },
    props: ['stores', ],
    methods: {
    }
}
</script>
