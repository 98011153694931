<template>
    <insight-widget :collapsible="true" :is-expanded="widgetExpanded" @toggle_expand="widgetExpanded = !widgetExpanded" id="documentsInsightsWidget">
        <template v-slot:title>Documents <span v-if="!isDisabled" class="text-regular">({{ incompleteDocTypes['all'] }})</span></template>
        <template v-if="!isDisabled" v-slot:headerbutton><router-link :to="{name: 'hr-documents-incomplete', params: {hashedClientId: $hasher.encode(client.id)}}">Go<span class='d-none d-sm-inline'> To Page </span><i class="fas fa-fw fa-chevron-right"></i></router-link></template>
        <slot>
            <div v-if="!isDisabled" class="documents-insight-widget-content">
                <!-- Tabs -->
                <div class="nav-tabs-container pt-qt">
                    <div class="nav-tabs-scroll-helper">
                        <ul class="nav nav-tabs">
                            <li class="nav-item">
                                <a class="nav-link pl-2 pr-2" href data-toggle="tab" role="tab" :class="{active: activeTab == 'all'}" @click.prevent="activeTab = 'all'">All</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link pl-1 pr-1" href data-toggle="tab" role="tab" :class="{active: activeTab == 'active_ee'}" @click.prevent="activeTab = 'active_ee'">Active Employees</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link pl-1 pr-1" href data-toggle="tab" role="tab" :class="{active: activeTab == 'inactive_ee'}" @click.prevent="activeTab = 'inactive_ee'">Inactive Employees</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="p-2">
                    <h3>Overall Compliance</h3>
                    <div class="progress-container overall-progress-container">
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" :style="`width: ${overallDocProgressPercent[activeTab]}%`" :aria-valuenow="overallDocProgressPercent[activeTab]" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <div class="progress-label">{{ overallDocProgressPercent[activeTab] }}%</div>
                    </div>
                    <div class="progress-text d-flex justify-content-between pt-hf mb-2 text-dark">
                        <div class="">{{ overallDocsCompleted[activeTab] }} of {{ overallDocsRequired[activeTab] }} complete</div>
                        <div class="text-bold text-italic">{{ overallDocsRequired[activeTab] - overallDocsCompleted[activeTab] }} remaining</div>
                    </div>
                    <template v-if="docTasks.length">
                        <div v-if="activeTab == 'inactive_ee'">
                            <h4>Time Since Termination</h4>
                            <bar-chart :chart-data="overallTimeSinceTerminatedChartData"></bar-chart>
                        </div>
                        <div v-else>
                            <h4>Time Since Documents Were Sent</h4>
                            <bar-chart :chart-data="overallTimeSinceDocSentChartData[activeTab]"></bar-chart>
                        </div>
                    </template>
                </div>
                <div v-if="docTasks.length && companiesWithRequiredDocs.length" class="table-section accordion pb-0" id="documentsInsightsWidgetIncompleteDocs">
                    <a href class="table-section-titlebar collapse-trigger pl-0 pr-0" role="button" :class="{'collapsed': !incompleteDocsExpanded}" @click.prevent="incompleteDocsExpanded = !incompleteDocsExpanded">
                        <collapse-toggle-button class="pt-hf pl-1"></collapse-toggle-button>
                        <span class="table-section-title incomplete-docs-section-title text-dark text-semibold">Incomplete Documents<span class="text-regular"> ({{ incompleteDocTypes[activeTab] }})</span></span>
                    </a>
                    <section v-show="incompleteDocsExpanded">
                        <div v-for="company in companiesWithRequiredDocs" :key="company.id" class="table-section accordion pb-0">
                            <a v-show="getCompanyNumIncompleteDocTypes(company)[activeTab]" href class="table-section-titlebar collapse-trigger pl-0 pr-0" role="button" :class="{'collapsed': !isCompanyExpanded[company.id]}" @click.prevent="isCompanyExpanded[company.id] = !isCompanyExpanded[company.id]">
                                <collapse-toggle-button class="pl-1"></collapse-toggle-button>
                                <h5 class="table-section-title">{{ company.name }} <span class="text-regular">({{ getCompanyNumIncompleteDocTypes(company)[activeTab] }})</span></h5>
                            </a>
                            <section v-if="getCompanyNumIncompleteDocTypes(company)[activeTab]" v-show="isCompanyExpanded[company.id]" class="p-1 pl-2 pr-2">
                                <div v-for="doc in company.requiredLibraryDocs" class="incomplete-doc" :key="doc.doc_key">
                                    <template v-if="doc[activeTab]['numIncomplete']">
                                        <h4 class="mb-0 pt-hf">
                                            <router-link :to="{name: 'hr-documents-incomplete', query: getDocFilterQueryParams(doc)}">{{ doc.name }}</router-link>
                                        </h4>
                                        <div class="progress-container">
                                            <div class="progress">
                                                <div class="progress-bar" role="progressbar" :style="`width: ${getPercent(doc[activeTab]['numCompleted'], doc[activeTab]['numRequired'])}%`"></div>
                                            </div>
                                            <div class="progress-label">{{ getPercent(doc[activeTab]['numCompleted'], doc[activeTab]['numRequired']) }}%</div>
                                        </div>
                                        <div class="progress-text d-flex justify-content-between pt-0 mb-2">
                                            <div class="">{{ doc[activeTab]['numCompleted']  }} of {{ doc[activeTab]['numRequired'] }} complete</div>
                                            <div class="text-bold text-italic">{{ doc[activeTab]['numIncomplete'] }} remaining</div>
                                        </div>
                                    </template>
                                </div>
                                <div v-if="company.requiredIndividualDocs[activeTab]['numIncomplete']" class="incomplete-doc pb-1">
                                    <h4 class="mb-0 pt-hf pb-hf"><router-link :to="{name: 'hr-documents-incomplete'}">Individual Documents</router-link></h4>
                                    <div class="progress-text">
                                        <div class="text-bold text-italic">{{ company.requiredIndividualDocs[activeTab]['numIncomplete'] }} awaiting signatures</div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </section>
                </div>
            </div>
            <div v-if="isDisabled" class="documents-insight-widget-content p-2">
                <h6>Get your documents completed & signed—<em>fast</em>.</h6>
                <p><em>Purchase the HR Compliance Suite to see compliance data about your documents.</em></p>
                <div class="d-flex justify-content-center">
                    <router-link target="_blank" class="btn btn-outline-primary" :to="{name: 'client-features-hr-compliance', params: {client: client.id} }" @click="trackLearnMoreHRSuite">
                        <i class="far mr-hf fa-book-open"></i>Learn More
                    </router-link>
                </div>
            </div>
        </slot>
    </insight-widget>
</template>

<script>
    import InsightWidget from '@/components/EmployerDashboard/InsightWidget'
    import BarChart from '@/components/Charts/BarChart'
    import {objectToQueryParams} from '@/components/FastTable/filter-utils'

    export default {
        props: ['client', 'docTasks'],
        components: {InsightWidget, BarChart},
        data() {
            return {
                widgetExpanded: true,
                activeTab: 'all',
                incompleteDocsExpanded: false,
                isCompanyExpanded: this.makeIsCompanyExpanded()
            }
        },
        computed: {
            overallDocsCompleted() {
                let allCompleted = this.docTasks.filter(d => d.status == 'completed')
                return {
                    'all': allCompleted.length,
                    'active_ee': allCompleted.filter(d => d.employee.employment_status == 'Active').length,
                    'inactive_ee': allCompleted.filter(d => d.employee.employment_status != 'Active').length,
                }
            },
            overallDocsRequired() {
                return {
                    'all': this.docTasks.length,
                    'active_ee': this.docTasks.filter(d => d.employee.employment_status == 'Active').length,
                    'inactive_ee': this.docTasks.filter(d => d.employee.employment_status != 'Active').length,
                }
            },
            overallDocProgressPercent() {
                return {
                    'all': this.overallDocsRequired['all'] > 0 ? Math.round((this.overallDocsCompleted['all'] / this.overallDocsRequired['all']) * 100) : 0,
                    'active_ee': this.overallDocsRequired['active_ee'] > 0 ? Math.round((this.overallDocsCompleted['active_ee'] / this.overallDocsRequired['active_ee']) * 100) : 0,
                    'inactive_ee': this.overallDocsRequired['inactive_ee'] > 0 ? Math.round((this.overallDocsCompleted['inactive_ee'] / this.overallDocsRequired['inactive_ee']) * 100) : 0,
                }
            },
            overallTimeSinceDocSentChartData() {
                let allIncomplete = this.docTasks.filter(d => d.status != 'completed')
                let chartTabData = [
                    {
                        value: 0,
                        label: "< 30 days",
                    },
                    {
                        value: 0,
                        label: "30-90 days",
                    },
                    {
                        value: 0,
                        label: "3-6 months",
                    },
                    {
                        value: 0,
                        label: "6+ months",
                    },
                ]
                let chartData = {
                    all: JSON.parse(JSON.stringify(chartTabData)),
                    active_ee: JSON.parse(JSON.stringify(chartTabData)),
                    inactive_ee: JSON.parse(JSON.stringify(chartTabData))
                }
                allIncomplete.forEach(d => {
                    let tab = 'inactive_ee'
                    if (d.employee.employment_status == 'Active') {
                        tab = 'active_ee'
                    }
                    if (d.days_since_assigned < 30) {
                        chartData['all'][0]['value'] += 1
                        chartData[tab][0]['value'] += 1
                    } else if (d.days_since_assigned >= 30 && d.days_since_assigned <= 90) {
                        chartData['all'][1]['value'] += 1
                        chartData[tab][1]['value'] += 1
                    } else if (d.days_since_assigned > 90 && d.days_since_assigned <= 180) {
                        chartData['all'][2]['value'] += 1
                        chartData[tab][2]['value'] += 1
                    } else if (d.days_since_assigned > 180) {
                        chartData['all'][3]['value'] += 1
                        chartData[tab][3]['value'] += 1
                    }
                })
                return chartData
            },
            overallTimeSinceTerminatedChartData() {
                let allIncompleteAndTermed = this.docTasks.filter(d => d.status != 'completed' && d.employee.employment_status != 'Active')
                let chartData = [
                    {
                        value: 0,
                        label: "< 30 days",
                    },
                    {
                        value: 0,
                        label: "30-90 days",
                    },
                    {
                        value: 0,
                        label: "3-6 months",
                    },
                    {
                        value: 0,
                        label: "6+ months",
                    },
                ]
                allIncompleteAndTermed.forEach(d => {
                    if (d.employee.days_since_terminated < 30) {
                        chartData[0]['value'] += 1
                    } else if (d.employee.days_since_terminated >= 30 && d.employee.days_since_terminated <= 90) {
                        chartData[1]['value'] += 1
                    } else if (d.employee.days_since_terminated > 90 && d.employee.days_since_terminated <= 180) {
                        chartData[2]['value'] += 1
                    } else if (d.employee.days_since_terminated > 180) {
                        chartData[3]['value'] += 1
                    }
                })
                return chartData
            },
            companiesWithRequiredDocs() {
                let companiesData = this.client.companies.map(c => {
                    return {
                        id: c.id,
                        name: c.name,
                        requiredLibraryDocs: [],
                        requiredIndividualDocs: {
                            all: {
                                numCompleted: 0,
                                numIncomplete: 0,
                                numRequired: 0
                            },
                            active_ee: {
                                numCompleted: 0,
                                numIncomplete: 0,
                                numRequired: 0
                            },
                            inactive_ee: {
                                numCompleted: 0,
                                numIncomplete: 0,
                                numRequired: 0
                            },
                        }
                    }
                })
                this.docTasks.forEach(docTask => {
                    let tab = 'inactive_ee'
                    if (docTask.employee.employment_status == 'Active') {
                        tab = 'active_ee'
                    }
                    let companyData = companiesData.find(c => c.id == docTask.company.id)
                    let docCompletionData
                    if (docTask.required_document.is_ad_hoc) {
                        docCompletionData = companyData.requiredIndividualDocs
                    } else {
                        docCompletionData = companyData.requiredLibraryDocs.find(d => d.doc_key == docTask.required_document.doc_key)
                    }
                    if (!docCompletionData) {
                        docCompletionData = {
                            id: docTask.required_document.id,
                            doc_key: docTask.required_document.doc_key,
                            name: docTask.required_document.name,
                            all: {
                                numCompleted: 0,
                                numIncomplete: 0,
                                numRequired: 0
                            },
                            active_ee: {
                                numCompleted: 0,
                                numIncomplete: 0,
                                numRequired: 0
                            },
                            inactive_ee: {
                                numCompleted: 0,
                                numIncomplete: 0,
                                numRequired: 0
                            },
                        }
                        companyData.requiredLibraryDocs.push(docCompletionData)
                    }
                    docCompletionData['all']['numRequired'] += 1
                    docCompletionData[tab]['numRequired'] += 1
                    if (docTask.status == 'completed') {
                        docCompletionData['all']['numCompleted'] += 1
                        docCompletionData[tab]['numCompleted'] += 1
                    } else {
                        docCompletionData['all']['numIncomplete'] += 1
                        docCompletionData[tab]['numIncomplete'] += 1
                    }
                })
                return companiesData
            },
            incompleteDocTypes() {
                // the number of Library or Ad Hoc documents where at least one requirement has not been completed
                return {
                    'all': this.companiesWithRequiredDocs.reduce((sum, company) => sum + this.getCompanyNumIncompleteDocTypes(company)['all'], 0),
                    'active_ee': this.companiesWithRequiredDocs.reduce((sum, company) => sum + this.getCompanyNumIncompleteDocTypes(company)['active_ee'], 0),
                    'inactive_ee': this.companiesWithRequiredDocs.reduce((sum, company) => sum + this.getCompanyNumIncompleteDocTypes(company)['inactive_ee'], 0)
                }
            },
            isDisabled() {
                return !this.client.has_company_docs_module
            }
        },
        methods: {
            makeIsCompanyExpanded() {
                let isCompanyExpanded = {}
                this.client.companies.forEach(c => {
                    isCompanyExpanded[c.id] = true
                })
                return isCompanyExpanded
            },
            getPercent(numerator, denominator) {
                if (denominator == 0) {
                    return 0
                }
                return Math.round(numerator/denominator*100)
            },
            getCompanyNumIncompleteDocTypes(company) {
                return {
                    'all': company.requiredLibraryDocs.filter(d => { return d['all']['numIncomplete'] > 0 }).length + company.requiredIndividualDocs['all']['numIncomplete'],
                    'active_ee': company.requiredLibraryDocs.filter(d => { return d['active_ee']['numIncomplete'] > 0 }).length + company.requiredIndividualDocs['active_ee']['numIncomplete'],
                    'inactive_ee': company.requiredLibraryDocs.filter(d => { return d['inactive_ee']['numIncomplete'] > 0 }).length + company.requiredIndividualDocs['inactive_ee']['numIncomplete'],
                }
            },
            getDocFilterQueryParams(doc) {
                return objectToQueryParams({
                    numFilters: 1,
                    filters: {
                        document: [
                            {
                                value: doc.name,
                                label: doc.name
                            }
                        ]
                    }
                })
            },
            trackLearnMoreHRSuite() {
                this.$bus.trackEvent('HR Compliance Suite Docs Widget Learn More', {
                    'client_id': this.client.id,
                    'client_business_type_rollup_primary': this.client.business_type_rollup_primary,
                    'client_business_type_rollup_secondary': this.client.business_type_rollup_secondary,
                    'client_business_type_rollup_brand': this.client.business_type_rollup_brand,
                })
            },
        }
    }
</script>

<style lang="scss">
    .documents-insight-widget-content {
        .progress {
            border-radius: 14px;
        }
        .progress-bar {
            background-color: #368F99;
        }
        .overall-progress-container .progress {
            height: 14px;
        }
        .doc-bar {
            color: #fff;
            height: 100%;
            text-align: right;
            padding-right: 8px;
            font-weight: 500;
            min-width: 24px;
        }
        .table-section {
            .table-section-titlebar {
                font-size: 16px;
            }
        }
        .incomplete-doc {
            h4 {
                font-size: 16px;
            }
        }
        // animation
        .doc-bar, .progress-bar {
            transform-origin: left;
            animation: revealing-bars 0.3s ease-out;
            transition: width 0.2s ease-out;
        }
        @keyframes revealing-bars {
            0%  { transform: scaleX( 0.8 ); }
            100% { transform: scaleX( 1 ); }
        }
    }
</style>