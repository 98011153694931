<template>
    <div v-if="chartData" class="bar-chart-container">
        <table class="charts-css bar show-labels data-spacing-2" :class="{'stacked multiple': stacked, 'hide-total': hideTotal}">
            <tbody>
                <tr v-for="row in chartData">
                    <th scope="row"> {{ row.label }} </th>
                    <template v-if="stacked">
                        <td v-for="(stack, idx) in row.stacks" class="stack" :class="{'last-stack': idx == getRowStacksWithValue(row).length - 1}" :style="getBarStyle(stack)" :title="`${stack.label} (${stack.value})`">
                            <span class="data" :class="{'data-hidden': stack.value == 0 || ((stack.value/maxBarValue) < 0.1)}">{{ stack.value }}</span>
                        </td>
                        <td v-if="!hideTotal" class="stacked-row-total">
                            <span class="data">{{ row.value }}</span>
                        </td>
                    </template>
                    <template v-else>
                        <td :style="getBarStyle(row)" :title="`${row.label} (${row.value})`" :class="{'td-min-width': row.value > 0}">
                            <span class="data" :class="{'data-hidden': row.value == 0}">{{ row.value }}</span>
                        </td>
                    </template>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import '@/assets/css/charts.css'

export default {
    components: {},
    props: {
        chartData: {
            type: Array,
            default: null,
        },
        // expects chartData in the format:
        // [
        //     {
        //         label: "3+ days ago",
        //         value: 30,
        //         color:  '#368F99',
        //     },
        //     ...
        // ]
        // or for a stacked bar chart:
        // [
        //     {
        //         label: "3+ days ago",
        //         value: 30,
        //         stacks: [
        //             {
        //                 value: 10,
        //                 label: "In Progress",
        //                 color: '#368F99',
        //             },
        //             ...
        //         ]
        //     },
        //     ...
        // ]
        stacked: {
            type: Boolean,
            default: false
        },
        hideTotal: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            totalValLabelWidth: 0.15
        }
    },
    computed: {
        maxBarValue() {
            let maxBarVal = 0
            for (let row of this.chartData) {
                if (row.value > maxBarVal) {
                    maxBarVal = row.value
                }
            }
            return maxBarVal
        }
    },
    methods: {
        getBarStyle(rowOrStack) {
            let style = `--size: calc( ${rowOrStack.value}/${this.maxBarValue});`
            if (rowOrStack.color) {
                style +=  ` --color: ${rowOrStack.color};`
            }
            return style
        },
        getRowStacksWithValue(row) {
            return row.stacks.filter(s => s.value > 0)
        }
    }
}
</script>

<style lang="scss" scoped>
    .bar-chart-container {
        table.charts-css.bar {
            // colors can be overriden in chartData
            --color-1: #368F99;
            --color-2: #2A7EFF;
            --color-3: #FFA200;
            --color-4: #5E2F5A;
            --chart-bg-color: #fff;
            --labels-size: 100px;

            tbody tr {
                min-height: 1.75rem;
                th {
                    font-weight: normal;
                    justify-content: end !important;
                    padding-right: 12px;
                }
                td {
                    min-height: 1.5rem;
                    padding-block-end: 2px;
                    padding-block-start: 2px;
                    border-right: 2px solid #fff;

                    min-width: 0;
                    &.td-min-width {
                        min-width: 2rem;
                    }
                    .data {
                        color: #fff;
                        font-weight: 500;
                        padding-right: 6px;
                    }
                    .data-hidden {
                        padding-right: 0px;
                        display: none;
                    }

                    @keyframes revealing-bars {
                        0%  { transform: scaleX( 0.8 ); }
                        100% { transform: scaleX( 1 ); }
                    }
                    transform-origin: left;
                    animation: revealing-bars 0.3s ease-out;
                    transition: width 0.2s ease-out, min-width 0.2s ease-out;
                }
            }

            // stacked bar chart styles
            &.stacked {

                &:not(.hide-total) {
                    padding-right: 32px;
                }

                tbody tr td {
                    animation: none;
                    &.last-stack {
                        animation: revealing-bars 0.3s ease-out;
                    }
                    &.stacked-row-total {
                        --color: #fff;
                        width: 32px;
                        position: absolute;
                        top: 0;
                        right: -32px;

                        .data {
                            color: #000;
                        }
                    }
                }
            }
        }
    }
</style>


