<template>
    <div class="promo-tile" v-if="promoOrder.length" :class="{'carousel': carouselEnabled}">
        <transition-group name="fade" tag="div">
            <div v-if="promoSlug=='get-the-app'" key="get-the-app" class="promo-tile-inner promo-tile-get-the-app">
                <div class="promo-tile-messaging">
                    <div class="promo-tile-icon">
                        <img src="@/assets/img/app-icon-rounded.svg">
                    </div>
                    <h1 class="promo-tile-headline">
                        Get the <span class="text-nowrap">mobile app</span>
                    </h1>
                    <p class="promo-tile-subhead">
                        Fast &amp; Convenient Access
                    </p>
                </div>

                <div class="promo-tile-cta">
                    <div class="promo-tile-cta-primary">
                        <p>
                            <a href="https://www.getmypaystub.com/download-app/android">
                                <img src="@/assets/img/google-play-badge.svg">
                            </a>
                        </p>
                        <p>
                            <a href="https://www.getmypaystub.com/download-app/ios">
                                <img src="@/assets/img/apple-appstore-badge.svg">
                            </a>
                        </p>
                    </div>
                    <div class="promo-tile-cta-secondary">
                        <p>
                            <a class="d-inline-block py-hf" href @click.prevent="showSendLinkModal()">Send a link to my phone</a>
                        </p>
                    </div>
                </div>
                <send-link-modal ref="sendLinkModal" />
            </div>

            <div v-if="promoSlug=='enhanced-ess'" key="enhanced-ess" class="promo-tile-inner promo-tile-enhanced-ess pt-2">
                <div class="promo-tile-messaging">
                    <div class="promo-tile-icon">
                        <i class="far fa-pencil fa-flip-horizontal"></i>
                    </div>
                    <h1 class="promo-tile-headline">
                        Enhanced ESS
                    </h1>
                    <p class="promo-tile-subhead">
                        Put your employees&rsquo; data in their hands.
                    </p>
                </div>

                <div class="promo-tile-cta d-flex align-items-end">
                    <div class="promo-tile-cta-primary text-center flex-grow-1 mt-8 mb-2">
                        <a class="btn btn-outline-primary text-nowrap w-100" href="features/enhanced-ess" @click="trackLearnMoreEnhancedESS" target="_blank">
                            <i class="far mr-1 fa-book-open"></i>Learn More
                        </a>
                    </div>
                </div>
            </div>

            <div v-if="promoSlug=='hr-suite'" key="hr-suite" class="promo-tile-inner promo-tile-hr-suite pt-2">
                <div class="promo-tile-messaging">
                    <div class="promo-tile-icon">
                        <i class="far fa-file-signature"></i>
                    </div>
                    <h1 class="promo-tile-headline">
                        HR Compliance Suite
                    </h1>
                    <p class="promo-tile-subhead">
                        Get documents completed & signed—<em>fast</em>.
                    </p>
                </div>

                <div class="promo-tile-cta d-flex align-items-end">
                    <div class="promo-tile-cta-primary text-center flex-grow-1 mt-8 mb-2">
                        <a class="btn btn-outline-primary text-nowrap w-100" href="features/hr-compliance" @click="trackLearnMoreHRSuite" target="_blank">
                            <i class="far mr-1 fa-book-open"></i>Learn More
                        </a>
                    </div>
                </div>
            </div>
        </transition-group>

        <div v-if="carouselEnabled" class="carousel-controls">
            <button class="carousel-btn" :class="{'active': promoIdx==idx}" v-for="(slide, idx) in promoOrder" :key="idx" @click="promoIdx=idx"></button>
        </div>

    </div>
</template>

<script>
import SendLinkModal from '@/components/SendLinkModal'

export default {
    props: ['promo', ],
    components: {SendLinkModal,},
    data() {
        return {
            promoIdx: 0,
            carouselInterval: null
            // (disabled for now in favor of specified promoOrder)
            // startingPromoWeights: {
            //     'enhanced-ess': 0.6,
            //     'get-the-app': 0.4,
            // },
        }
    },
    created() {
        // (Disabled for now in favor of specified promoOrder)
        // If props don't specify a specific promo to show, then randomize the promo
        // if (!this.promo) {
        //     this.promoIdx = this.promoOrder.indexOf(this.pickRandomWeightedItem(this.startingPromoWeights))
        // }
    },
    mounted() {
        if (this.carouselEnabled) {
            // Start carousel
            this.carouselInterval = setInterval(() => {
                this.nextSlide()
            }, 45000);
        }
    },
    beforeUnmount() {
        clearInterval(this.carouselInterval)
    },
    computed: {
        carouselEnabled() {
            return !this.promo && this.promoOrder.length > 1 ? true : false
        },
        promoOrder() {
            let order = []
            if (this.$store.state.system_flags?.hr_docs_suite_released) {
                order.push('hr-suite')
            }
            if (this.$store.state.system_flags?.enhanced_ess_released) {
                order.push('enhanced-ess')
            }
            if (!this.$store.stateisInApp) {
                order.push('get-the-app')
            }
            return order
        },
        promoSlug() {
            return this.promo ? this.promo : this.promoOrder[this.promoIdx]
        }
    },
    methods: {
        pickRandomWeightedItem(weightedItems) {
            let items = Object.keys(weightedItems)
            let weights = Object.values(weightedItems)
            let i = 0

            for (i = 0; i < weights.length; i++) {
                weights[i] += weights[i - 1] || 0
            }
            let random = Math.random() * weights[weights.length - 1]
            for (i = 0; i < weights.length; i++) {
                if (weights[i] > random) {
                    break
                }
            }
            return items[i]
        },
        nextSlide() {
            if (this.promoIdx >= this.promoOrder.length - 1) {
                this.promoIdx = 0
            } else {
                this.promoIdx += 1
            }
        },
        showSendLinkModal() {
            this.$refs.sendLinkModal.open()
        },
        trackLearnMoreEnhancedESS() {
            this.$bus.trackEvent('Enhanced ESS Billboard Learn More', {})
        },
        trackLearnMoreHRSuite() {
            this.$bus.trackEvent('HR Compliance Suite Billboard Learn More', {})
        },
    },
}
</script>
