<template></template>

<script>
import TourMixin from '@/mixins/TourMixin'

export default {
    mixins: [TourMixin],
    components: {},
    data() {
        return {}
    },
    methods: {
        start() {
            let steps = [
                {
                    element: '#navbarMainMenuLink',
                    popover: {
                        title: 'GetMyPayStub has a new look!',
                        description: 'Learn what new features are available to you now.',
                        disableButtons: [],
                        prevBtnText: 'Skip Tour',
                        nextBtnText: 'Get Started',
                        showButtons: ['next', 'previous'],
                        onNextClick: () => {
                            this.openMainMenu()
                            this.tour.moveNext()
                        },
                        onPrevClick: () => {
                            this.$emit('finished')
                            this.tour.movePrevious()
                        }
                    },
                },
                {
                    element: '#navbarDropdownMenu',
                    popover: {
                        title: 'Menu',
                        description: 'Access your pages, switch accounts, and log out here.',
                        onPrevClick: () => {
                            this.closeMainMenu()
                            this.tour.movePrevious()
                        }
                    },
                },
                {
                    element: '#dashboard-todo',
                    popover: {
                        title: 'Your To-Do List',
                        description: 'Get a snapshot of what needs your attention most.',
                        onPrevClick: () => {
                            this.openMainMenu()
                            this.tour.movePrevious()
                        }
                    },
                    onHighlightStarted: () => {
                        this.closeMainMenu()
                    },
                },
                {
                    element: '#dashboard-warnings',
                    popover: {
                        title: 'Warnings',
                        description: 'Catch up on any warnings you’ve missed.',
                    },
                },
                {
                    element: '#dashboard-tasks',
                    popover: {
                        title: 'Tasks',
                        description: 'See what tasks need your team’s attention at a glance.',
                    },
                },
                {
                    element: '#dashboard-insights',
                    popover: {
                        title: 'Insights',
                        description: 'Quickly track your team’s progress toward goals across all of your services. Expand sections or click categories to dive deeper into your data.',
                    },
                },
                {
                    element: '#dashboard-filter-btn',
                    popover: {
                        title: 'Filter Page',
                        description: 'Filter the dashboard by manager or company to compare warnings, tasks, and insights data across different stakeholders.',
                    },
                },
                {
                    element: '#dashboard-quick-links',
                    popover: {
                        title: 'Help with the Dashboard',
                        description: 'Re-start this tutorial here for a quick refresher.',
                        onNextClick: () => {
                            this.$emit('finished')
                            this.tour.moveNext()
                        }
                    },
                },
            ]
            this.setSteps(steps)
            this._start()
        },
    }
}
</script>