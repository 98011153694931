<template>
    <div class="heads-up-banner-container">
        <div v-if="headsup=='enhanced-ess' && enhancedESSCompanies.length > 0 && !isDismissed" class="heads-up-banner mb-2">
            <div class="heads-up-status">running now</div>
            <div class="heads-up-details pl-1 pr-2">
                <div>
                    <strong>Enhanced ESS is now in use in {{ enhancedESSCompanies[0].name }} {{ enhancedESSCompanies.length > 1 ? `and ${enhancedESSCompanies.length - 1} other${enhancedESSCompanies.length > 2 ? 's' : ''}` : ''}}!</strong>
                </div>
                <div class="heads-up-links">
                    <router-link target="_blank" class="btn btn-link py-0" :to="{name: 'client-features-enhanced-ess', params: {client: client.id} }" @click="trackLearnMoreEnhancedESS">
                        <i class="far mr-hf fa-book-open"></i>Learn More
                    </router-link>
                    <a href class="btn btn-link py-0" @click.prevent="dismiss()">Dismiss</a>
                </div>
            </div>
        </div>
        <div v-if="headsup=='hr-suite' && hrSuiteCompanies.length > 0 && !isDismissed" class="heads-up-banner mb-2">
            <div class="heads-up-status">running now</div>
            <div class="heads-up-details pl-1 pr-2">
                <div>
                    <strong>HR Compliance Suite is now in use in {{ hrSuiteCompanies[0].name }} {{ hrSuiteCompanies.length > 1 ? `and ${hrSuiteCompanies.length - 1} other${hrSuiteCompanies.length > 2 ? 's' : ''}` : ''}}!</strong>
                </div>
                <div class="heads-up-links">
                    <router-link target="_blank" class="btn btn-link py-0" :to="{name: 'client-features-hr-compliance', params: {client: client.id} }" @click="trackLearnMoreHRSuite">
                        <i class="far mr-hf fa-book-open"></i>Learn More
                    </router-link>
                    <a href class="btn btn-link py-0" @click.prevent="dismiss()">Dismiss</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    props: ['client', 'headsup'],
    components: {},
    data() {
        return {
            'dismissed': false
        }
    },
    computed: {
        dismissalSlug() {
            return `headsup-${this.headsup}`
        },
        dismissalsRecord() {
            let rec = {}
            if (this.$store.state.user) {
                if (this.$store.state.user.dismissals_record['clients']) {
                    if (this.client.id in this.$store.state.user.dismissals_record['clients']) {
                        rec = this.$store.state.user.dismissals_record['clients'][this.client.id]
                    }
                }
            }
            return rec
        },
        isDismissed() {
            if (this.dismissed) {
                return true
            } else {
                let timestamp = this.dismissalsRecord[this.dismissalSlug]
                if (timestamp) {
                    let inputformat = 'YYYY-MM-DDTHH:mm:ss.SSSZ'
                    let dismissedMoment = moment(timestamp, inputformat)
                    if (dismissedMoment.isSameOrBefore()) {
                        return true
                    }
                }
            }
            return false
        },
        enhancedESSCompanies() {
            if (this.client.has_enhanced_ess) {
                return this.client.companies.filter(c => c.has_enhanced_ess)
            } else {
                return []
            }
        },
        hrSuiteCompanies() {
            if (this.client.has_company_docs_module) {
                return this.client.companies.filter(c => c.has_company_docs_module)
            } else {
                return []
            }
        },
        trackEventData() {
            return {
                'client_id': this.client.id,
                'client_business_type_rollup_primary': this.client.business_type_rollup_primary,
                'client_business_type_rollup_secondary': this.client.business_type_rollup_secondary,
                'client_business_type_rollup_brand': this.client.business_type_rollup_brand,
            }
        }
    },
    methods: {
        dismiss() {
            this.dismissed = true
            this.$api.post(`/me/dismiss`, {'client_id': this.client.id, 'slug': this.dismissalSlug}).then(resp => {
                this.$store.dispatch('GET_USER')
            }).catch(errors => {
                this.$bus.showError(errors.__all__)
            })
            this.$bus.trackEvent('Enhanced ESS Heads Up Banner Dismiss', this.trackEventData)
        },
        dismissIn30Days() {
            let dismissTimestamp = moment().add(30, 'days');
            this.$api.post(`/me/dismiss`, {'client_id': this.client.id, 'slug': this.dismissalSlug, 'timestamp': dismissTimestamp})
        },
        trackLearnMoreEnhancedESS() {
            this.$bus.trackEvent('Enhanced ESS Heads Up Banner Learn More', this.trackEventData)
        },
        trackLearnMoreHRSuite() {
            this.$bus.trackEvent('HR Compliance Suite Heads Up Banner Learn More', this.trackEventData)
        },
    },
    mounted() {
        // dismiss 30 days from the first time the user sees this heads up
        // (users can also dismiss manually before then)
        if (this.$store.state.user && !this.dismissalsRecord[this.dismissalSlug]) {
            this.dismissIn30Days()
        }
    },
}

</script>
