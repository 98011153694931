<template>
<div>
    <div v-if="title || !hideControls" class="d-flex" style="justify-content: space-between;">
        <h3 v-if="title">{{ title }}</h3>

        <div v-if="!hideControls" class="mt-1">
            <div class="text-right mb-1">
                <button class="btn btn-outline-primary btn-collapse-xl" :disabled="zoom >= 400" @click.prevent="zoom = zoom * 1.25">
                    <i class="far fa-fw fa-search-plus"></i>
                    <span class="sr-only">Zoom In</span>
                </button>
                <button class="btn btn-outline-primary btn-collapse-xl ml-1" :disabled="zoom <= 100" @click.prevent="zoom = zoom / 1.25">
                    <i class="far fa-fw fa-search-minus"></i>
                    <span class="sr-only">Zoom Out</span>
                </button>
                <button v-if="url" class="btn btn-outline-primary btn-collapse-rs ml-1" @click.prevent="print()">
                    <i class="far fa-fw fa-file-pdf"></i>
                    <span class="btn-text ml-hf">PDF</span>
                </button>
            </div>
        </div>
    </div>

    <div class="pdf-viewer-wrapper">
        <template v-if="page">
            <VuePdfEmbed :page="page" :source="src" v-if="src" @loaded="onLoaded" :style="{ '--zoom': zoomPct }" :scale="scale"/>
        </template>
        <template v-else>
            <VuePdfEmbed :source="src" v-if="src" @loaded="onLoaded" :style="{ '--zoom': zoomPct }" :scale="scale"/>
        </template>
    </div>

    <div class="loader loader-modal" v-show="!isLoaded">
        <div class="spinner">
            <div class="lds-grid">
                <div></div><div></div><div></div>
                <div></div><div></div><div></div>
                <div></div><div></div><div></div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed'

export default {
    components: {
        VuePdfEmbed,
    },
    props: ['url', 'page', 'title', 'hideControls'],
    // url can be a URL string (including a data URL), or an object in the form of pdf.js DocumentInitParameters: https://github.com/mozilla/pdf.js/blob/38287d943532eee939ceffbe6861163f93805ca7/src/display/api.js#L145
    // page may be omitted to render all pages
    data() {
        return {
            isLoaded: false,
            src: this.loadPDF(),
            zoom: 100,
            scale: 2
        }
    },
    computed: {
        zoomPct() {
            return `${this.zoom}%`
        }
    },
    watch: {
        url() {
            this.src = this.loadPDF()
        }
    },
    methods: {
        loadPDF() {
            if (!this.url) {
                return null
            }
            this.isLoaded = false
            return this.url
        },
        onLoaded() {
            this.isLoaded = true
            this.$emit('loaded')
        },
        print() {
            if (!this.url) {
                return null
            }
            let pdf_url = this.url.url ? this.url.url : this.url

            if (! this.$store.state.isInApp) {
                window.open(pdf_url) // NB: Chrome does not all navigating to a data URL
                return
            }

            this.$store.dispatch('START_LOADING')
            this.$api.get(pdf_url, false, false).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                // use this rather than ArrayBufferToString because ArrayBufferToString
                // sometimes results in: 'RangeError: Maximum call stack size exceeded'
                function _arrayBufferToBase64( buffer ) {
                    var binary = '';
                    var bytes = new Uint8Array( buffer );
                    var len = bytes.byteLength;
                    for (var i = 0; i < len; i++) {
                        binary += String.fromCharCode( bytes[ i ] );
                    }
                    return window.btoa( binary );
                }

                let message = JSON.stringify({
                    command: 'print',
                    content: btoa(_arrayBufferToBase64(resp))
                })

                if (typeof global !== 'undefined' && global.webkit && global.webkit.messageHandlers && global.webkit.messageHandlers.cordova_iab) {
                    global.webkit.messageHandlers.cordova_iab.postMessage(message)
                } else {
                    console.error("In app, but global.webkit.messageHandlers not found");
                }
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.pdf-viewer-wrapper {
    margin-left: auto;
    margin-right: auto;
    overflow-x: auto;
    padding-left: 8px;
    padding-right: 8px;
    :deep(canvas) {
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
        margin-left: auto;
        margin-right: auto;
        margin-top: 1em;
        margin-bottom: 1em;
        width: var(--zoom) !important;
        height: auto !important;
    }
}
.no-shadow .pdf-viewer-wrapper {
    :deep(canvas) {
        box-shadow: none;
    }
}
</style>

